import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  googlePlay,
  appStore,
  vectorRight,
  vectorLeft,
  circle,
} from '../../assets/icons';
import {
  downloadSection,
  cornerDownload,
  cornerDownload2,
} from '../../assets/imgs';

const DownloadsAppBanner = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <div
      className="bg-[#EAFFFD] w-11/12 mx-auto rounded-lg mt-7 mb-7 p-6 max-h-45"
      dir={isArabic ? 'rtl' : 'ltr'}
    >
      <div className="relative grid md:grid-cols-2 sm:grid-cols-1 gap-8">
        <div className="flex justify-center md:justify-end">
          <img
            src={downloadSection}
            alt="Download Section"
            className="object-contain"
          />
        </div>

        <div className="flex flex-col justify-center items-center space-y-4">
          <p className="text-xl text-customPurple font-bold text-center">
          {t('home.downloadApp.title')}
          </p>
          <div className="flex space-x-4 space-x-reverse">
            <a href="#" className="flex items-center">
              <img src={appStore} alt="App Store" className="w-40 h-auto" />
            </a>
            <a href="#" className="flex items-center">
              <img src={googlePlay} alt="Google Play" className="w-40 h-auto" />
            </a>
          </div>
        </div>

        <img
          src={isArabic ? vectorLeft : vectorRight}
          alt="Arrow"
          className={`absolute top-0 ${isArabic ? 'left-0' : 'right-0'}`}
        />

        <img
          src={isArabic ? vectorLeft : vectorRight}
          alt="Arrow"
          className={`absolute top-0 ${isArabic ? 'left-0' : 'right-0'}`}
        />
        {/* <img
          src={isArabic ? cornerDownload2 : cornerDownload}
          alt="Corner"
          className="absolute bottom-0 right-0"
        /> */}
      </div>
    </div>
  );
};

export default DownloadsAppBanner;
