import React, { useState, useContext, useEffect } from 'react';
import { userArabic, ImageIcon } from '../../../assets/icons';
import InputRHF from '../../../components/inputs/InputRHF';
import i18n from '../../../languages/i18n';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FaLock, FaTrash } from 'react-icons/fa';
import { UserContext } from '../../../context/UserContext';
import { profileSchema } from '../../../schema/profile';
import { Lock, DeleteIcon } from '../../../assets/icons';
import { updateProfile } from '../../../services/auth';
import ChangePasswordModal from '../../../components/modals/ChangePasswordModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessChangePassword from '../../../components/modals/SuccessChangePassword';
import { getUserData } from '../../../services/apis/user';

const Profile = () => {
  document.body.dir = i18n.dir();
  const { t } = useTranslation();
  const { userData, setUserData } = useContext(UserContext);
  const [isModal, setIsModal] = useState(false);
  const [successChangePass, setSuccessChangePass] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgBase64, setProfileImgBase64] = useState('');
  const [userInfo, setUserInfo] = useState(null);

  const getUser = async () => {
    const response = await getUserData();
    setUserInfo(response);
  };

  const closeSuccessChangePass = () => {
    setSuccessChangePass(false);
  };

  const closeModal = () => {
    setIsModal(false);
  };

  const {
    register,
    handleSubmit,
    reset, // Add reset from useForm
    formState: { errors },
  } = useForm({
    resolver: zodResolver(profileSchema),
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setProfileImg(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setProfileImgBase64(reader.result);
    };
  };

  const onSubmit = async (data) => {
    // Ensure the base64 image is in the correct format
    const base64Image = profileImgBase64.split(',')[1]; // Strip the prefix if needed

    const payload = {
      fullName: data.fullName,
      email: data.email,
      removeProfileImg: false,
      profileImg: base64Image || '', // Ensure it's not undefined or null
      nationalId: userData?.nationalId,
      description: data.description || '',
      phoneNumber: userData.phoneNumber,
    };

    console.log('Payload to be sent:', payload); // Log the payload

    try {
      const response = await updateProfile(payload);

      console.log('API Response:', response); // Log the response

      if (response.status === 200) {
        const successMessage = t('profile.updateSuccess');
        toast.success(successMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1500, // duration in milliseconds
        });
        setUserData(response.data);
        setSuccessChangePass(true);
      }
    } catch (error) {
      console.error('Error updating profile:', error); // More detailed error logging
      const errorMessage =
        error.response?.data?.message || 'An unknown error occurred';
    }
  };

  useEffect(() => {
    // Fetch user data and update the form
    const getUser = async () => {
      const response = await getUserData();
      setUserInfo(response);

      // Call reset to update form values once user info is fetched
      reset({
        fullName: response?.fullName || '',
        email: response?.email || '',
        nationalId: response?.nationalId || '',
        description: response?.description || '',
        phoneNumber: response?.phone || '',
      });
    };

    getUser();
  }, [reset]);

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-10">
      <div className="px-[11%] flex flex-col gap-6 md:flex-row justify-between  ">
        <div className="bg-[#73D3CB] rounded-full p-8 relative">
          {profileImgBase64 ? (
            <img
              src={profileImgBase64}
              alt="User"
              className="w-15 h-20 rounded-full "
            />
          ) : (
            <img src={userArabic} alt="User" />
          )}
          <div className="bg-[#73D3CB] p-3 rounded-full absolute left-2 shadow-2xl">
            <label htmlFor="profileImg">
              <img src={ImageIcon} alt="Icon" />
            </label>
            <input
              id="profileImg"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
          </div>
        </div>

        <div className="flex flex-col items-center gap-4">
          <div className="bg-[#3E0292] rounded-lg w-[173px] h-[53px] flex items-center justify-between px-4">
            <img src={Lock} alt="lock" />
            <button
              className="text-[#FFFFFF] font-medium"
              style={{
                fontFamily: 'Neo Sans Arabic',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '19.2px',
                textAlign: 'left',
              }}
              onClick={() => setIsModal(true)}
            >
              {t('profile.changePass')}
            </button>
          </div>

          <div className="bg-[#FF4342] rounded-lg w-[173px] h-[53px] flex items-center justify-between px-4">
            <img src={DeleteIcon} alt="delete" />
            <button
              className="text-[#FFFFFF] font-medium"
              style={{
                fontFamily: 'Neo Sans Arabic',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '19.2px',
                textAlign: 'left',
              }}
            >
              {t('profile.deleteAcc')}
            </button>
          </div>
        </div>
      </div>

      <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid px-[13%] md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 gap-y-4">
          <div className="max-w-[497px]">
            <InputRHF
              label={t('profile.name')}
              type="text"
              {...register('fullName')}
              placeholder={t('profile.name')}
              error={errors.fullName?.message}
            />
          </div>
          <div className="max-w-[497px]">
            <InputRHF
              label={t('profile.email')}
              type="text"
              {...register('email')}
              placeholder={t('profile.email')}
              error={errors.email?.message}
            />
          </div>
          <div className="max-w-[497px]">
            <InputRHF
              label={t('profile.phone')}
              type="text"
              defaultValue={userData?.phoneNumber} // Set default value
              placeholder={t('profile.phone')}
              readOnly // Make this field read-only
            />
          </div>
          <div className="max-w-[497px]">
            <InputRHF
              label={t('profile.idNumber')}
              type="text"
              {...register('nationalId')}
              placeholder={t('profile.idNumber')}
              error={errors.nationalId?.message}
            />
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <button
            type="submit"
            className="bg-[#00CEBC] border border-[#00CEBC] rounded-[14px] text-[15px] text-white px-10 py-4 w-[30%] shadow-lg text-center w-64"
          >
            {t('profile.submit')}
          </button>
        </div>
      </form>

      <ChangePasswordModal isOpen={isModal} isClose={closeModal} />
      <SuccessChangePassword
        isOpen={successChangePass}
        Close={closeSuccessChangePass}
      />
      <ToastContainer />
    </div>
  );
};

export default Profile;
