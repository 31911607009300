import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { notFoundIcon } from "../assets/icons"
import { Button } from "../components"

const NotFoundPage=()=>{
    const {t} = useTranslation()
    const navigate = useNavigate()
    return(
        <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF]">
         <div className="w-[90%] m-auto flex flex-col items-center justify-center">
            <div className="text-[20px] font-[700px] text-[#444444]">{t("notFoundPage.title")}</div>
            <div className="text-[16px] font-[400px] text-[#444444] mt-5">{t("notFoundPage.subTitle")}</div>
            <img src={notFoundIcon} className="w-[422px] my-10"/>
            <Button dark={false} className="bg-[#00CEBC] border-[#00CEBC] shadow-md mb-5" title={t("notFoundPage.buttonTitle")} onClick={()=>navigate("/")}/>
         </div>
         </div>    
    )
}

export default NotFoundPage