import Chart from 'react-apexcharts'

const CircleCircuit=({statistics})=>{
    let labels = []
    let series = []
    statistics?.medsRating?.forEach(element => {
        labels.push(element.rate)
        series.push(element.percentage)
    });
    const chartOptions = {
        chart: {
          type: 'donut',
        },
        // colors: ['#00CEBC', '#00CEDF', '#3E0292', '#04C500','#BBBBBB'],
        // labels: ['Segment 1', 'Segment 2', 'Segment 3', 'Segment 4' , 'Segment 5'],
        lebels:labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                // height:300
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
    
      const chartSeries = series;

   return(
       <div className='flex justify-center'>
     <Chart
        options={chartOptions}
        series={chartSeries}
        type="donut"
        width="100%"
        height="500px"
      />
       </div>
   )
}

export default CircleCircuit