import { z } from 'zod';

export const profileSchema = z.object({
  fullName: z.string().min(1, 'Full name is required'),
  email: z.string().email('Invalid email address'),
  phoneNumber: z.string(),
  nationalId: z
    .string()
    .length(10, 'National ID must be exactly 10 characters'),
  description: z.string().optional(),
});
