import { star ,successRate} from "../../assets/icons"
import StarRatings from 'react-star-ratings';
import { useState } from "react";
import { defaultLettersCircle } from "../../helpers/helper";
import { useTranslation } from "react-i18next";
import Textarea from "../inputs/Textarea";
import CheckBox from "../inputs/CheckBox";

const MediatorRate=({onValueChangeCommentRate,onValueChangeRate,setShowMessageSuccess,showMessageSuccess,onValueChangeStarRate})=>{
    const {t} = useTranslation()
      const [rating, setRating] = useState(0);
      // const [showMessageSuccess,setShowMessageSuccess] = useState(false)

  const changeRating = (newRating) => {
    onValueChangeStarRate(Math.floor(newRating))
    setRating(Math.floor(newRating),setShowMessageSuccess(true));

  };


  const [selectedValue, setSelectedValue] = useState('');
  const handleChange = (e) => {
      console.log(e.target.value == 1)
      setSelectedValue(e.target.value);
      onValueChangeRate(e.target.value);
    };
    const [comment, setComment] = useState('');
    const handleChangeComment = (e) => {
        console.log(e.target.value)
        setComment(e.target.value);
        onValueChangeCommentRate(e.target.value);
      };
    return(
        <div>
            <div className="flex flex-row items-center justify-between p-2 m-[10px] rounded-[28px] bg-[#C6FFFA]">
                <div className="flex flex-row">
                  <div className="rounded-full p-1 bg-[#73D3CB]">{defaultLettersCircle("محمود المرشدي",'w-[50px]','h-[50px]')}</div>
                    <div className="flex flex-row items-center text-[#3E0292] text-[15px] font-bold mx-3">
                    <div>{t("listOffers.mediator")}</div>
                   <div className="px-2">:</div>
                   <div>محمود المرشدي</div>
                    </div>
            
                </div>
                <div className="flex flex-row items-center">
                    <div><img src={star}/></div>
                    <div className="text-[#3E0292] text-[15px] font-700">4.5</div>
                    </div>
            </div>

            {showMessageSuccess ? (
                        <div className="flex flex-col justify-center items-center">
                        <div className="my-5">{t("mediatorRate.sentRatedSuccess")}</div>
                        <div><img src={successRate}/></div>
                    </div>
            ):(
                <div className="flex flex-col justify-center items-center">
            <StarRatings
        rating={rating}
        starRatedColor="#3E0292"
        changeRating={changeRating}
        numberOfStars={5}
        name='rating'
        starDimension="40px"
        starSpacing="5px"
        starHoverColor="#3E0292"
      />
      <div className="flex flex-row items-center ">
        <div className="mt-2 text-lg font-medium">{t("mediatorRate.ratedValue")}</div>
        <div className="mt-2 text-lg font-medium mx-3">{rating}</div>
     </div>
     <div className="flex flex-col items-center">
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={1} checked={selectedValue == 1} type="radio"/>
            <label className="mx-3 text-[#3E0292] text-[13px] font-bold">{t("paidRequest.acceptable")}</label>
            </div>
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={2} checked={selectedValue == 2} type="radio"/>
            <label className="mx-3 text-[#3E0292] text-[13px] font-bold">{t("paidRequest.good")}</label>
            </div>
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={3} checked={selectedValue == 3} type="radio"/>
            <label className="mx-3 text-[#3E0292] text-[13px] font-bold">{t("paidRequest.veryGood")}</label>
            </div>
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={4} checked={selectedValue == 4} type="radio"/>
            <label className="mx-3 text-[#3E0292] text-[13px] font-bold">{t("paidRequest.excellent")}</label>
            </div>
            </div>
     <div className="bg-[#E0FFFC] p-3 rounded-[20px]">
            <Textarea rows={4} label={t("paidRequest.addComment")} placeholder={t("paidRequest.enterComment")} onChange={handleChangeComment} value={comment}/>
      </div>
                </div>
            )}


        </div>
    )
}

export default MediatorRate