import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  googlePlay,
  appStore,
  vectorRight,
  vectorLeft,
  circle,
} from '../../assets/icons';
import {
  downloadSection,
  cornerDownload,
  cornerDownload2,
} from '../../assets/imgs';
import { Lang } from '../../helpers/contents';
import i18n from '../../languages/i18n';

import '../component.css';
const DownloadApp = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#BBF7F1] w-[90%] mr-auto ml-auto rounded-[10px] mt-20 p-3 p-7">
      <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 mt-2">
        <div
          className={`flex flex-col items-center justify-center relative z-10 max600:mt-10 ${
            i18n.language == Lang.ar ? 'order-2' : 'order-1'
          }`}
        >
          <img src={downloadSection} className="downloadSection" />
          <img
            src={vectorLeft}
            className="vectorLeft absolute right-0 bottom-[-20px] z-10"
          />
          <img
            src={vectorRight}
            className="vectorRight absolute left-0 top-[-30px] z-10"
          />
          <img
            src={cornerDownload}
            className="cornerDownload absolute left-[-28px] bottom-[-27px] z-0"
          />
          <img
            src={cornerDownload2}
            className="cornerDownload2 absolute left-[-28px] bottom-[-27px] z-0"
          />
          <img
            src={circle}
            className="circle absolute right-[20px] top-[-10px] z-0"
          />
        </div>
        <div
          className={`flex items-center justify-center flex-col ${
            i18n.language == Lang.ar ? 'order-1' : 'order-2'
          } sm:mt-10`}
        >
          <div className="downloadApp font-700 font-['Kufam'] text-[#3E0292] text-[30px]">
            {t('home.downloadApp.title')}
          </div>
          <div className="flex flex-row justify-between items-center mt-5">
            <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1">
              <div className="flex flex-col justify-center items-center cursor-pointer">
                <img
                  src={googlePlay}
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.miso.mshraiB2C',
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                />
              </div>
              <div className="flex flex-col justify-center items-center cursor-pointer">
                <img
                  src={appStore}
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/eg/app/%D9%85%D8%B4%D8%B1%D8%A7%D9%8A/id6553995179',
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
