import {
  Button,
  FDropdown,
  MAddOrderConfirmation,
  Textarea,
  WayConnection,
  YearDropdown,
} from '../../../../components';
import { userCar, userMail, LetterIcon } from '../../../../assets/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../languages/i18n';
import Counter from '../../../../components/inputs/Counter';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  addOffer,
  getAllCarBrands,
  getColors,
  getCities,
  getModelsOfBrand,
  editOrder,
} from '../../../../services/apis/offer';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import useAddOrderSchema from '../../../../schema/order';

const Add = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const location = useLocation();
  const isEditMode = Boolean(orderId);
  const isArabic = i18n.language === 'ar';
  const [openModal, setOpenModal] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [groupedModels, setGroupedModels] = useState([]);
  const [cities, setCities] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCommunicationOptions, setSelectedCommunicationOptions] =
    useState([1]);
  const [isPriceNotDetermined, setIsPriceNotDetermined] = useState(false);
  const yearOptions = Array.from({ length: 25 }, (_, index) => 2000 + index);
  const addOrderSchema = useAddOrderSchema();
  const { state } = location;
  const existingOrder = state?.order;

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleCommunicationOptionsSelect = (updatedOptions) => {
    setSelectedCommunicationOptions(updatedOptions);
  };

  const handleCheckBoxChange = () => {
    setIsPriceNotDetermined(!isPriceNotDetermined);
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(addOrderSchema),
    defaultValues: {
      requestTitle: '',
      description: '',
      yearFrom: 0,
      yearTo: 0,
      priceFrom: 0,
      priceTo: 0,
      odoFrom: 0,
      odoTo: 0,
      cars: [
        {
          brandId: 0,
          models: [],
        },
      ],
      cityIds: [],
      colorIds: [],
      communicationOptionIds: [],
      phoneNumber: '',
      whatsappNumber: '',
    },
  });

  const watchBrand = watch('carBrands');

  const handleAddOfferSubmit = async (data) => {
    try {
      // Validate that carBrands and carType have corresponding entries
      if (data?.carBrands?.length === 0) {
        console.error('At least one car brand must be selected');
        return;
      }
      for (let i = 0; i < data?.carBrands?.length; i++) {
        const brandId = data.carBrands[i];
        const modelId = data.carType[i];
        if (!brandId || !modelId) {
          console.error(
            'Each selected car must have a brand and at least one model'
          );
          return;
        }
      }

      // Validate price range
      if (Number(data.priceTo) < Number(data.priceFrom)) {
        console.error('Price To should be greater than or equal to Price From');
        return;
      }

      const payload = {
        requestTitle: data.requestTitle || '',
        description: data.description || '',
        yearFrom: Number(data.yearFrom) || 0,
        yearTo: Number(data.yearTo) || 0,
        priceFrom: Number(data.priceFrom) || 0,
        priceTo: Number(data.priceTo) || 0,
        odoFrom: Number(data.kilometersFrom) || 0,
        odoTo: Number(data.kilometersTo) || 0,
        cars: data.carBrands.map((brandId, index) => ({
          brandId: brandId,
          models: data.carType[index] ? [data.carType[index]] : [],
        })),
        cityIds: data.city || [],
        colorIds: data.color || [],
        communicationOptionIds: selectedCommunicationOptions,
        phoneNumber: data.phoneNumber || '',
        whatsappNumber: data.whatsappNumber || '',
      };
      setLoading(true);
      if (isEditMode) {
        const response = await editOrder(orderId, payload);
        console.log("🚀 ~ handleAddOfferSubmit ~ response:", response)
        if (response && response.id) {
          setOpenModal(true);
          setTimeout(() => navigate(`/orders/${orderId}`), 1500);
        } else {
          throw new Error('Unexpected response format');
        }
      } else {
        const response = await addOffer(payload);
        if (response && response.data && response.data.id) {
          setOpenModal(true);
          setTimeout(() => {
            navigate('/');
          }, 1500);
        }
      }
    } catch (error) {
      console.error('Error submitting offer:', error);
    }
  };

  useEffect(() => {
    // Fetch all brands, colors, and cities on component load
    const fetchInitialData = async () => {
      const brandData = await getAllCarBrands();
      const colorData = await getColors();
      const cityData = await getCities();

      if (brandData) {
        const formattedBrands = brandData.map((brand) => ({
          value: brand.id,
          label: isArabic ? brand.nameAr : brand.nameEn,
          icon: brand.image,
        }));
        setBrands(formattedBrands);
      }

      if (colorData) {
        const formattedColors = colorData?.data?.items?.map((color) => ({
          value: color.id,
          label: isArabic ? color.nameAr : color.nameEn,
        }));
        setColors(formattedColors);
      }

      if (cityData) {
        const formattedCities = cityData.map((city) => ({
          value: city.id,
          label: isArabic ? city.nameAr : city.nameEn,
        }));
        setCities(formattedCities);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (watchBrand?.length > 0) {
      // Fetch models for selected brands and group them
      const fetchModelsForBrands = async () => {
        try {
          const modelDataList = await Promise.all(
            watchBrand.map((brandId) => getModelsOfBrand(brandId))
          );

          const grouped = watchBrand.map((brandId, index) => {
            const brand = brands.find((b) => b.value === brandId);
            const models = modelDataList[index].map((model) => ({
              value: model.id,
              label: isArabic ? model.nameAr : model.nameEn,
            }));
            return {
              brandId,
              brandLabel: brand?.label, // Adjust if necessary
              models,
            };
          });

          setGroupedModels(grouped);
        } catch (error) {
          console.error('Error fetching models:', error);
        }
      };

      fetchModelsForBrands();
    } else {
      setGroupedModels([]); // Reset if no brand selected
    }
  }, [watchBrand, brands]);

  useEffect(() => {
    if (isEditMode && existingOrder) {
      // Format the data as per form structure
      const formattedData = {
        requestTitle: existingOrder.requestTitle || '',
        description: existingOrder.description || '',
        yearFrom: existingOrder.yearFrom || 0,
        yearTo: existingOrder.yearTo || 0,
        priceFrom: existingOrder.priceFrom || 0,
        priceTo: existingOrder.priceTo || 0,
        odoFrom: existingOrder.odoFrom || 0,
        odoTo: existingOrder.odoTo || 0,
        carBrands: existingOrder.cars.map((car) => car.id), // Assuming car.id is the brandId
        carType: existingOrder.cars.map(
          (car) => (car.models.length > 0 ? car.models[0].id : null) // Assuming models[0].id is the modelId
        ),
        city: existingOrder.cities.map((city) => city.id) || [], // Extract city IDs
        color: existingOrder.colors.map((color) => color.id) || [], // Extract color IDs
        communicationOptionIds:
          existingOrder.communicationOptions.map((option) => option.id) || [], // Extract communication option IDs
        phoneNumber: existingOrder.phoneNumber || '',
        whatsappNumber: existingOrder.whatsappNumber || '',
      };
      reset(formattedData); // Reset the form with the formatted data
    }
  }, [isEditMode, existingOrder, reset]);

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-10">
      <div className="w-[90%] mr-auto ml-auto">
        <div className="text-[17px] text-[#000000] font-500 text-center mb-10">
          {isEditMode ? t('myOrders.editOrder') : t('myOrders.orderCar')}
        </div>

        <form onSubmit={handleSubmit(handleAddOfferSubmit)}>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mb-4 gap-4">
            <FDropdown
              control={control}
              name="carBrands"
              label={t('myOrders.brandCar')}
              placeholder={t('myOrders.selectBrandCar')}
              icon={userMail}
              options={brands}
              error={errors.carBrands?.message}
            />

            <FDropdown
              control={control}
              name="carType"
              label={t('myOrders.carType')}
              placeholder={t('myOrders.selectCarType')}
              icon={LetterIcon}
              options={models}
              groupedOptions={groupedModels}
              error={errors.carType?.message}
              disabled={watchBrand?.length === 0}
            />
            <FDropdown
              control={control}
              name="city"
              label={t('myOrders.city')}
              placeholder={t('myOrders.selectCity')}
              icon={userMail}
              options={cities}
            />

            <FDropdown
              control={control}
              name="color"
              icon={LetterIcon}
              options={colors}
              label={t('myOrders.color')}
              placeholder={t('myOrders.selectColor')}
            />
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-4items-center">
            <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4">
              <YearDropdown
                control={control}
                name="yearFrom"
                icon={userMail}
                label={t('myOrders.year')}
                placeholder={t('myOrders.from')}
                options={yearOptions.map((year) => ({
                  value: year,
                  label: year,
                }))}
              />

              <div className="mt-5">
                <YearDropdown
                  control={control}
                  name="yearTo"
                  icon={''}
                  label={''}
                  placeholder={t('myOrders.to')}
                  options={yearOptions.map((year) => ({
                    value: year,
                    label: year,
                  }))}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4">
              <Controller
                name="kilometersFrom"
                defaultValue={existingOrder?.odoFrom || 0}
                control={control}
                render={({ field }) => (
                  <Counter
                    {...field}
                    initialValue={existingOrder?.odoFrom}
                    icon={LetterIcon}
                    label={t('myOrders.kilometers')}
                    placeholder={t('myOrders.from')}
                    step={10000}
                  />
                )}
              />
              <Controller
                name="kilometersTo"
                defaultValue={existingOrder?.odoTo || 0}
                control={control}
                render={({ field }) => (
                  <Counter
                    {...field}
                    initialValue={existingOrder?.odoTo}
                    icon={''}
                    label={t('myOrders.to')}
                    placeholder={t('myOrders.to')}
                    className="mt-2"
                    step={10000}
                  />
                )}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-5">
            <div className="flex flex-col">
              <div className="flex flex-row items-center">
                <input
                  type="checkbox"
                  checked={isPriceNotDetermined}
                  onChange={handleCheckBoxChange}
                  className="appearance-none w-5 h-5 rounded-[5px] border border-[#3E0292] checked:bg-[#3E0292] checked:border-transparent focus:outline-none"
                />
                <div className="text-[#303030] text-[13px] font-500 mx-5">
                  {t('myOrders.priceNotDetermined')}
                </div>
              </div>

              {/* Conditionally render the counter fields */}
              {!isPriceNotDetermined && (
                <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4">
                  <Controller
                    name="priceFrom"
                    control={control}
                    defaultValue={existingOrder?.priceFrom || 0}
                    render={({ field }) => (
                      <Counter
                        {...field}
                        initialValue={existingOrder?.priceFrom}
                        icon={userMail}
                        label={t('myOrders.price')}
                        placeholder={t('myOrders.from')}
                        className="mt-2"
                        step={10000}
                      />
                    )}
                  />
                  <Controller
                    name="priceTo"
                    control={control}
                    defaultValue={existingOrder?.priceTo || 0}
                    render={({ field }) => (
                      <Counter
                        {...field}
                        initialValue={existingOrder?.priceTo}
                        icon={''}
                        label={t('myOrders.to')}
                        placeholder={t('myOrders.to')}
                        className="mt-2"
                        step={10000}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col max600:mt-5 sm:mt-5">
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Textarea
                    rows={5}
                    label={t('myOrders.description')}
                    placeholder={t('myOrders.description')}
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          {/* <WayConnection
            selectedOptions={selectedCommunicationOptions}
            onSelect={handleCommunicationOptionsSelect}
          /> */}
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2">
            <Button
              type="submit"
              title={t('myOrders.addOrderNow')}
              dark={false}
              className="bg-[#00CEBC] border border-[#00CEBC]"
              loading={loading}
            />
            <Button
              onClick={() => reset()}
              title={t('myOrders.closeOrder')}
              dark={false}
              className="bg-[#FF4342] border border-[#FF4342] max600:mt-3 sm:mt-3 xl:mt-0 lg:mt-0 2xl:mt-0 md:mt-0"
            />
          </div>
        </form>

        <MAddOrderConfirmation isOpen={openModal} Close={closeModal} />
      </div>
    </div>
  );
};

export default Add;
