import Car from "./parts/Car"
import Mediator from "./parts/Mediator"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { CompleteOfferContext } from "../../context/CompleteOfferContext"

const CompleteOrder=({offer})=>{
    const completeOfferContext = useContext(CompleteOfferContext)
    const {t} = useTranslation()

  const handleCarSelect = (carId) => {
    if (completeOfferContext.selectedCars.includes(carId)) {
        completeOfferContext.setSelectedCars(completeOfferContext.selectedCars.filter(id => id !== carId));
    } else {
        completeOfferContext.setSelectedCars([...completeOfferContext.selectedCars, carId]);
    }
  };
    return(
        <div>
            <div className="text-[#303030] font-700 text-[15px]">{t("completeRequest.chooseCar")}</div>
            <Mediator offer={offer} cars={
                <div>
                    {offer?.cars.map((car,index)=>(
                      <Car 
                      key={index} 
                      car={car}
                      selected={completeOfferContext.selectedCars.includes(car.offerCarId)}
                      onClick={() => handleCarSelect(car.offerCarId)}
                      />
                    ))}
                </div>
            }/>
        </div>
    )
}

export default CompleteOrder