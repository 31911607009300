import mainService from './mainService';
import VerifyOTP from '../components/auth/VerifyOTP';

export function requestOtp(phoneNumber) {
  return mainService
    .post(`otp/requestOtp/${phoneNumber}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function verifyOTP(requestBody) {
  return mainService
    .post(`/otp/verifyOtp`, requestBody)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function verifyOtp(data) {
  return mainService
    .post('/otp/verifyOtp', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function register(data) {
  return mainService
    .post('/user/register', data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function login(data) {
  return mainService
    .post('/user/login', data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function resetPassword({ phoneNumber, ...data }) {
  return mainService
    .put(`user/resetPassword/${phoneNumber}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function updateProfile(data) {
  return mainService
    .put('/user/updateUser', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(
        'API Error:',
        error.response ? error.response.data : error.message
      );
      return Promise.reject(error);
    });
}

export function changePassword(data) {
  return mainService
    .put('/user/changePassword', data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(
        'API Error:',
        error.response ? error.response.data : error.message
      );
      return Promise.reject(error);
    });
}
