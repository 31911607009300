import { formatMessageTime } from '../../utils/messageUtils';

const Message = ({ sender = true, message, showAvatar = true, userInfo }) => {
  const isSender = message.senderId === userInfo?.userId;

  const renderContent = () => {
    if (message.image) {
      return (
        <img
          src={message.image}
          alt="Shared image"
          className="max-w-[200px] rounded-md hover:opacity-90 cursor-pointer transition-opacity"
          onClick={() => window.open(message.image, '_blank')}
        />
      );
    }

    if (message.file) {
      const fileUrl = Array.isArray(message.file)
        ? message.file[0]
        : message.file;
      return (
        <div className="flex items-center space-x-2 bg-white/30 rounded-md p-2">
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" />
          </svg>
          <a
            href={fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 underline transition-colors"
          >
            {message.fileName || 'Download file'}
          </a>
        </div>
      );
    }

    const messageText =
      typeof message.text === 'object' ? message.text.message : message.text;
    return <span className="break-words">{messageText || 'No message'}</span>;
  };

  return (
    <div
      className={`flex items-end gap-2 my-1 ${
        isSender ? 'justify-start' : 'justify-end'
      }`}
    >
      {/* {!isSender && (
        <img
          src={message.senderImage || '/default-avatar.png'}
          alt="avatar"
          className="w-8 h-8 rounded-full object-cover"
        />
      )} */}

      <div className="flex flex-col max-w-[70%]">
        {!isSender && (
          <span className="text-xs text-gray-500 ml-2 mb-1">
            {message.senderName}
          </span>
        )}

        <div
          className={`
            flex flex-col p-3 rounded-2xl
            ${
              isSender
                ? 'bg-[#00CEBC4F] rounded-tr-none'
                : 'bg-[#EFEFEF] rounded-tl-none'
            }
          `}
        >
          {renderContent()}
          <span className="text-[10px] text-gray-500 self-end mt-1">
            {formatMessageTime(message.createdAt)}
          </span>
        </div>
      </div>

      {/* {isSender && showAvatar && (
        <img
          src={message.senderImage || '/default-avatar.png'}
          alt="avatar"
          className="w-8 h-8 rounded-full object-cover"
        />
      )} */}
    </div>
  );
};

export default Message;
