import React from 'react';
import InfoSection from '../../components/InfoSection';
import SignUpForm from '../../components/SignUpForm';
import i18n from '../../languages/i18n';

function SignUp() {
  document.body.dir = i18n.dir();

  return (
    <div className="grid min-h-screen grid-cols-1 md:grid-cols-2">
      <div className="flex items-center justify-center ">
        <InfoSection />
      </div>
      <div className=" bg-gradient-to-r from-[#FFFFFF] to-[#D7E2FF] flex items-center justify-center ">
        <div className="w-full max-w-md p-4 sm:p-1 md:p-2 lg:p-2">
          <SignUpForm />
        </div>
      </div>
    </div>
  );
}

export default SignUp;
