import React from 'react';
import i18n from '../../languages/i18n';
import { useTranslation } from 'react-i18next';
import { arrowBack } from '../../assets/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { verifyOTP } from '../../services/auth';

const VerifyOTPForm = () => {
  document.body.dir = i18n.dir();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { phoneNumber } = location.state || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log('a7a7a7a7a7a');
    const requestBody = {
      phoneNumber,
      otpCode: `${data.otp1}${data.otp2}${data.otp3}${data.otp4}${data.otp5}${data.otp6}`,
      roleId: 2,
      imgUrl: '',
    };

    try {
      const response = await verifyOTP(requestBody);
      console.log('OTP verified successfully:', response);
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <div className=" rounded-lg max600:order-2 w-full max-w-md space-y">
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-2xl font-bold text-center mb-10 text-customPurple sm:mt-10">
          {t('login.label.welcome')}
        </h2>
        <div className="flex justify-between">
          <span className="text-[#666666]"> {t('forgetPass.forgetPass')}</span>
          <img
            src={arrowBack}
            alt="arrow"
            className="bg-[#00CEBC] rounded-lg p-2"
            onClick={() => navigate('/forgetPass')}
          />
        </div>
        <h2 className="text-md  text-center text-[#3E0292] sm:mt-10">
          {t('forgetPass.verifyOTP.otp')}{' '}
        </h2>
        <h2 className="text-[#303030] text-center sm:mt-5 ">
          {t('forgetPass.verifyOTP.phoneOtp')}
          <span className="text-[#3E0292] ml-2 mr-2">{phoneNumber}</span>
        </h2>

        <div className="mt-9">
          <div className="flex justify-center gap-2 mb-6">
            <input
              className="w-12 h-12 text-center border rounded-md shadow-sm focus:border-[#3E0292] focus:ring-[#3E0292]"
              type="text"
              maxlength="1"
              pattern="[0-9]"
              inputmode="numeric"
              autoComplete="one-time-code"
              required
              name="otp1"
              {...register('otp1', { required: true })}
            />
            <input
              className="w-12 h-12 text-center border rounded-md shadow-sm focus:border-teal-500 focus:ring-teal-500"
              type="text"
              maxlength="1"
              pattern="[0-9]"
              inputmode="numeric"
              autoComplete="one-time-code"
              required
              name="otp2"
              {...register('otp2', { required: true })}
            />
            <input
              className="w-12 h-12 text-center border rounded-md shadow-sm focus:border-teal-500 focus:ring-teal-500"
              type="text"
              maxlength="1"
              pattern="[0-9]"
              inputmode="numeric"
              autoComplete="one-time-code"
              required
              name="otp3"
              {...register('otp3', { required: true })}
            />
            <input
              className="w-12 h-12 text-center border rounded-md shadow-sm focus:border-teal-500 focus:ring-teal-500"
              type="text"
              maxlength="1"
              pattern="[0-9]"
              inputmode="numeric"
              autoComplete="one-time-code"
              required
              name="otp4"
              {...register('otp4', { required: true })}
            />
            <input
              className="w-12 h-12 text-center border rounded-md shadow-sm focus:border-teal-500 focus:ring-teal-500"
              type="text"
              maxlength="1"
              pattern="[0-9]"
              inputmode="numeric"
              autoComplete="one-time-code"
              required
              name="otp5"
              {...register('otp5', { required: true })}
            />
            <input
              className="w-12 h-12 text-center border rounded-md shadow-sm focus:border-teal-500 focus:ring-teal-500"
              type="text"
              maxlength="1"
              pattern="[0-9]"
              inputmode="numeric"
              autoComplete="one-time-code"
              required
              name="otp6"
              {...register('otp6', { required: true })}
            />
          </div>
        </div>

        {Object.keys(errors).length > 0 && (
          <div className="text-red-500 text-center mt-2">
            {errors.otp1 && <p>OTP 1 is required.</p>}
            {errors.otp2 && <p>OTP 2 is required.</p>}
            {errors.otp3 && <p>OTP 3 is required.</p>}
            {errors.otp4 && <p>OTP 4 is required.</p>}
            {errors.otp5 && <p>OTP 5 is required.</p>}
            {errors.otp6 && <p>OTP 6 is required.</p>}
          </div>
        )}

        <div className="flex justify-center mt-5">
          <button
            type="submit"
            className="bg-[#00CEBC] border border-[#00CEBC] rounded-[14px] text-[15px] text-white px-10 py-4 w-[70%] shadow-lg text-center"
          >
            {t('forgetPass.submit')}
          </button>
        </div>
      </form>{' '}
    </div>
  );
};

export default VerifyOTPForm;
