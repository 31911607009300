import { Button } from './../index';
import { carOrder, cars } from '../../assets/icons';
import { useTranslation } from 'react-i18next';
import i18n from '../../languages/i18n';
import { Lang } from '../../helpers/contents';
import { useNavigate } from 'react-router-dom';
import ButtonIcon from '../buttons/ButtonIcon';
import { userRole, userToken } from '../../helpers/helper';
const AddOrder = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mt-20 pr-10 bg-[#00CEBC12]">
      <div
        className={`rounded-lg flex justify-center items-center max600:mt-10 max600:order-2 ${
          i18n.language == Lang.ar ? 'order-2' : 'order-1'
        }`}
      >
        <img src={carOrder} className="w-[100%]" />
      </div>
      <div
        className={`flex justify-center flex-col max600:order-1 ${
          i18n.language == Lang.ar ? 'order-1' : 'order-2'
        } px-5`}
      >
        <div className="text-[24px] text-[#3E0292] font-700">
          {t('home.addOrder.title')}
        </div>
        <div className="mt-5">
          {userRole == 3 ? (
               <Button
               dark={true}
               onClick={() => {
                 !userToken ? navigate('/login') : navigate('/offers/add');
               }}
               title={t('قدم عرضك الان')}
             />
          ):(
          <Button
            dark={true}
            onClick={() => {
              !userToken ? navigate('/login') : navigate('/orders/add');
            }}
            title={t('home.addOrder.button')}
          />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddOrder;
