import { z } from 'zod';
import i18n from 'i18next'; // Import i18next

export const signUpSchema = z.object({
  phoneNumber: z
    .string()
    .min(10, i18n.t('phoneNumberInvalid'))
    .max(10, i18n.t('phoneNumberExact'))
    .regex(/^0[5-9][0-9]{8}$/, i18n.t('phoneNumberPattern'))
    .transform((value) => `966${value.slice(1)}`),
  password: z.string().min(8, i18n.t('passwordMinLength')),
  // confirmPassword: z
  //   .string()
  //   .min(8, i18n.t('passwordMinLength')),
});
// .refine((data) => data.password === data.confirmPassword, {
//   path: ['confirmPassword'],
//   message: i18n.t('passwordsDontMatch'),
// });

export const loginSchema = z.object({
  phoneNumber: z
    .string()
    .min(10, i18n.t('phoneNumberInvalid'))
    .max(10, i18n.t('phoneNumberExact'))
    .regex(/^0[5-9][0-9]{8}$/, i18n.t('phoneNumberPattern'))
    .transform((value) => `966${value.slice(1)}`),
  password: z.string().min(8, i18n.t('passwordMinLength')),
});

export const forgetPassSchema = z.object({
  phoneNumber: z
    .string()
    .min(10, i18n.t('phoneNumberInvalid'))
    .max(10, i18n.t('phoneNumberExact'))
    .regex(/^0[5-9][0-9]{8}$/, i18n.t('phoneNumberPattern'))
    .transform((value) => `966${value.slice(1)}`),
});
