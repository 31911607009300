import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import i18n from '../languages/i18n';
import { useTranslation } from 'react-i18next';
import { arrowBack, OtpConfirmation } from '../assets/icons';
import InputRHF from '../components/inputs/InputRHF';
import { phone } from '../assets/icons';
import { useNavigate } from 'react-router-dom';
import { forgetPassSchema } from '../schema/auth';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { requestOtp } from '../services/auth';
import OTPConfirmation from '../components/modals/OTPConfirmation';

const ForgetPasswordForm = () => {
  const [openModal, setOpenModal] = useState(false);

  document.body.dir = i18n.dir();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const CloseModal = () => {
    setOpenModal(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ resolver: zodResolver(forgetPassSchema) });

  const handleForgetPassword = async () => {
    const { phoneNumber } = getValues();

    const result = forgetPassSchema.safeParse({ phoneNumber });

    const { phoneNumber: transformedPhoneNumber } = result.data;
    try {
      const otpResponse = await requestOtp(transformedPhoneNumber);
      const otpCode = otpResponse;
      if (otpResponse) {
        setOpenModal(true);
        setTimeout(() => {
          setOpenModal(false);
          navigate('/verifyOtp', { state: { phoneNumber } });
        }, 1000);
      }
    } catch {
      console.log('Error during registration:', error);
      toast.error('Sign-up failed, please try again.', {
        position: 'top-right',
      });
    }
  };

  return (
    <div className=" rounded-lg max600:order-2 w-full max-w-md space-y">
      <form onSubmit={handleSubmit(handleForgetPassword)}>
        <h2 className="text-2xl font-bold text-center mb-10 text-customPurple sm:mt-10">
          {t('login.label.welcome')}
        </h2>
        <div className="flex justify-between">
          <span className="text-[#666666]"> {t('forgetPass.forgetPass')}</span>
          <img
            src={arrowBack}
            alt="arrow"
            className="bg-[#00CEBC] rounded-lg p-2"
            onClick={() => navigate('/login')}
          />
        </div>
        <h2 className="text-l  text-center text-[#303030] sm:mt-10">
          {t('forgetPass.pleaseEnterPhoneNumber')}{' '}
        </h2>

        <div className="mt-9">
          <InputRHF
            label={t('contactUs.label.phone')}
            type={'text'}
            icon={phone}
            placeholder={t('contactUs.placeholder.phone')}
            {...register('phoneNumber')}
            error={errors.phoneNumber?.message}
          />
        </div>

        <div className="flex justify-center mt-5">
          <button
            type="submit"
            className="bg-[#00CEBC] border border-[#00CEBC] rounded-[14px] text-[15px] text-white px-10 py-4 w-[70%] shadow-lg text-center"
          >
            {t('forgetPass.submit')}
          </button>
        </div>
      </form>

      <OTPConfirmation isOpen={openModal} isClose={CloseModal} />
    </div>
  );
};

export default ForgetPasswordForm;
