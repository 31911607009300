import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CardOffer,
  InputSearch,
  NotFoundOrders,
  CardSkeleton,
} from '../../../../components';
import { getOffers, getOffersForOrder } from '../../../../services/apis/offer';

const Offers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [offers, setOffers] = useState([]);
  console.log("🚀 ~ Offers ~ offers:", offers)
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const { id } = useParams();

  useEffect(() => {
    getAllOffersForRequest();
  }, []);

  const getAllOffersForRequest = async () => {
    setLoading(true);
    let data = {
      params: {
        searchText: searchText,
      },
      body: { requestId: id },
    };
    const response = await getOffers(data.body, data.params);
    setOffers(response.data.items, setLoading(false));
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const searchAction = () => {
    getAllOffersForRequest();
  };

  if (loading) {
    return (
      <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-4 my-5 w-[90%] mr-auto ml-auto">
        {/* {Array(3)
            .fill(0)
            .map((_, index) => (
              <div key={index} style={{ marginBottom: "10px" }}> */}
        <CardSkeleton number={3} height={'300px'} />
        {/* </div>
             ))}  */}
      </div>
    );
  }

  if (!offers || offers.length === 0) {
    return <NotFoundOrders from="offers" onClick={() => navigate(`/orders`)} />;
  }

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <InputSearch
          onChange={handleSearch}
          value={searchText}
          action={searchAction}
        />
        <div className="flex flex-row items-center justify-between my-5">
          <div className="bg-[#3E0292] p-3 rounded-[10px] text-[#ffffff] text-[14px] font-500 flex flex-row items-center">
            <div>{t('listOffers.numberOrder')}</div>
            <div className="px-2">:</div>
            <div>#{offers[0]?.requestId}</div>
          </div>
          <div className="flex flex-row items-center justify-around p-2">
            <div className="text-[#3E0292] text-[14px] font-500 mx-5">
              {t('listOffers.offers')}
            </div>
            <div className="bg-[#D7E2FF] rounded-[5px] text-[#3E0292] text-[16px] font-500 p-3">
              {offers.length}
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-16  my-5">
          {offers.map((offer, index) => (
            <CardOffer
              key={offer.offerId}
              offer={offer}
              onClick={() =>
                navigate(`/orders/${offer?.requestId}/offers/${offer?.offerId}`)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Offers;
