'use client';

import React, { useState, useEffect } from 'react';
import { MinusIcon, PlusIcon } from 'lucide-react';

const Input = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <input
      className={`flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 ${className}`}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = 'Input';

function Button({ className, ...props }) {
  return (
    <button
      type="button" // Add this line to prevent form submission
      className={`inline-flex items-center justify-center rounded-md text-sm font-500 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ${className}`}
      {...props}
    />
  );
}

export default function Counter({
  label,
  initialValue = 0,
  step = 1,
  min = 0,
  max = Infinity,
  onChange,
  icon,
}) {
  const [value, setValue] = useState(initialValue);

  const handleIncrement = (e) => {
    e.preventDefault(); // Add this line to prevent default button behavior
    setValue((prev) => {
      const newValue = Math.min(prev + step, max);
      onChange?.(newValue);
      return newValue;
    });
  };

  const handleDecrement = (e) => {
    e.preventDefault(); // Add this line to prevent default button behavior
    setValue((prev) => {
      const newValue = Math.max(prev - step, min);
      onChange?.(newValue);
      return newValue;
    });
  };

  const handleInputChange = (e) => {
    const newValue = Number(e.target.value);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  // Effect to update value when props change
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="w-[100%] mt-2">
      <div className="flex flex-row items-center text-[#3E0292] text-[12px]">
        <img src={icon} alt="" />
        <div className="mx-3 font-400">{label}</div>
      </div>{' '}
      <div className="relative ">
        <Input
          type="number"
          value={value}
          onChange={handleInputChange}
          className="w-44 pr-10 pl-10 py-2 h-[100%] text-center border-2 border-[#D9D9D9] rounded-xlg focus:ring-purple-600 focus:border-purple-600"
          min={min}
          max={max}
        />
        <Button
          onClick={handleDecrement}
          className="absolute left-1.5 top-1/2 -translate-y-1/2 rounded-full w-6 h-6 p-0 bg-[#3E0292] text-white hover:bg-purple-700 shadow-md transition-all duration-200 ease-in-out hover:shadow-lg"
          aria-label={`Decrease ${label}`}
        >
          <MinusIcon className="h-3 w-3" />
        </Button>
        <Button
          onClick={handleIncrement}
          className="absolute right-1.5 top-1/2 -translate-y-1/2 rounded-full w-6 h-6 p-0 bg-[#3E0292] text-white hover:bg-purple-700 shadow-md transition-all duration-200 ease-in-out hover:shadow-lg"
          aria-label={`Increase ${label}`}
        >
          <PlusIcon className="h-3 w-3" />
        </Button>
      </div>
    </div>
  );
}
