import { useContext, useState ,useEffect} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { arrowLeftOrder } from "../../../assets/icons"
import { FilterOrderContext } from "../../../context/FilterOrderContext";
import { statueses, statuesesOwner } from "../../../helpers/helper";
import Button from "../../buttons/Button";
import GInput from "../../inputs/GInput"
  
const Status=({back,from})=>{
  const {t} = useTranslation()
    const filterOrderContext = useContext(FilterOrderContext)
    console.log(filterOrderContext)
    const { register, handleSubmit, setValue ,getValues} = useForm({
        defaultValues: { status: [] } 
      });
    const [selectedStatus, setSelectedStatus] = useState([]);

    useEffect(()=>{
      setSelectedStatus(filterOrderContext.statuses)
    },[filterOrderContext.statuses])

    const handleStatusChange = (status) => {
      const currentStatuses = getValues('statuses') || [];

      const updatedStatuses = currentStatuses.some(selected => selected.value === status.value)
        ? currentStatuses.filter(i => i.value !== status.value)
        : [...currentStatuses, status];
      setSelectedStatus(updatedStatuses); 
      
      
      setValue('statuses', updatedStatuses); 
      filterOrderContext.setStatuses(updatedStatuses)
    
    };

      const onSubmit = (data) => {
          filterOrderContext.setStatuses(data.statuses,back())
      };
    return(
        <div>
            <img src={arrowLeftOrder} className="my-2 cursor-pointer" onClick={back}/>
            <form onSubmit={handleSubmit(onSubmit)} className="p-4">
            <GInput title={t("filterModal.selectStatus")} items={from == "owner" ? statuesesOwner : statueses} type={"status"} selectedItems={selectedStatus} handleCheckboxChange={handleStatusChange} register={register}/>
            <div className="grid grid-cols-2 gap-x-2 mt-10">
                <Button type="submit" dark={false} className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]" title={t("filterModal.confirm")}/>
                <Button type="submit" dark={false} className="bg-[#FF4342] border-[#FF4342] rounded-[10px]" title={t("filterModal.cancel")}/>
      </div>
            </form>
        </div>
    )
}

export default Status