import Environment from '../Environment';
import mainService from '../mainService';

export function getOffersForOrder(data, orderId) {
  return mainService
    .get(Environment.getOffersForRequest + '/' + orderId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getOffer(data, offerId) {
  return mainService
    .get(`${Environment.getOffer + '/' + offerId}`, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}


export function getSavedOffer(data, offerId) {
  return mainService
    .get(`${Environment.savedOffers + '/' + offerId}`, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function updateOfferStatus(data) {
  return mainService
    .post(Environment.offerUpdateStatus, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function addOffer(data) {
  return mainService
    .post(Environment.addOffer, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const errorMessage = error.response
        ? error.response.data.message
        : 'Network response was not ok';
      return Promise.reject(new Error(errorMessage));
    });
}

export function editOrder(data,orderId) {
  return mainService
    .put(Environment.editOrder+"/"+orderId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getOffers(data, queryParams) {
  return mainService
    .post(Environment.getOffers, data, {
      params: queryParams,
    })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getOffersForAllOrders(data) {
  return mainService
    .post(Environment.getOffers, data)
    .then((res) => {
      // Check if the expected structure exists and access the items correctly
      if (res.data && res.data.data && res.data.data.items) {
        return res.data.data.items; // Access items correctly
      } else {
        console.warn('No items found in the response:', res);
        return []; // Return an empty array if no items are found
      }
    })
    .catch((error) => {
      console.error('Error in getOffers:', error);
      return Promise.reject(error);
    });
}

export function getOfferWithRequestForCompleteRequest(data, requestId) {
  return mainService
    .get(
      `${Environment.getOfferWithRequestForCompleteRequest + '/' + requestId}`,
      data
    )
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function confirmationOffer(data) {
  return mainService
    .post(Environment.confirmOffer, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getAllCarBrands() {
  return mainService
    .get(Environment.allBrands)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getModelsOfBrand(brandId) {
  return mainService
    .get(`${Environment.brandModels}/${brandId}`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getColors() {
  return mainService
    .get(Environment.colors)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getCities() {
  return mainService
    .get(Environment.cities)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getCommunicationOptions() {
  return mainService
    .get(Environment.communicationOptions)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}


export function getAllOffersForMediator(data) {
  return mainService
    .get(Environment.listAllOffersMediator, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}


export function getAllSavedOffersForMediator(data) {
  return mainService
    .get(Environment.savedOffers, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}


export function addSavedOffer(data) {
  return mainService
    .post(Environment.savedOffers, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // const errorMessage = error.response
      //   ? error.response.data.message
      //   : 'Network response was not ok';
      // return Promise.reject(new Error(errorMessage));
      return Promise.reject(error);
    });
}


export function editSavedOffer(data,offerId) {
  return mainService
    .put(Environment.savedOffers+"/"+offerId, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // const errorMessage = error.response
      //   ? error.response.data.message
      //   : 'Network response was not ok';
      // return Promise.reject(new Error(errorMessage));
      return Promise.reject(error);
    });
}

export function deleteSavedOffer(data,offerId) {
  return mainService
    .delete(Environment.savedOffers+"/"+offerId, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // const errorMessage = error.response
      //   ? error.response.data.message
      //   : 'Network response was not ok';
      // return Promise.reject(new Error(errorMessage));
      return Promise.reject(error);
    });
}


export function createNewOffer(data) {
  return mainService
    .post(Environment.createOffer, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // const errorMessage = error.response
      //   ? error.response.data.message
      //   : 'Network response was not ok';
      //   Promise.reject(error)
      return Promise.reject(error);
    });
}

export function deleteOffer(data,offerId) {
  return mainService
    .delete(Environment.getOffer+"/"+offerId, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // const errorMessage = error.response
      //   ? error.response.data.message
      //   : 'Network response was not ok';
      // return Promise.reject(new Error(errorMessage));
      return Promise.reject(error);
    });
}


export function updateOffer(data,offerId) {
  return mainService
    .put(Environment.getOffer+"/"+offerId, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // const errorMessage = error.response
      //   ? error.response.data.message
      //   : 'Network response was not ok';
      // return Promise.reject(new Error(errorMessage));
      return Promise.reject(error);
    });
}