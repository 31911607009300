import { getChatListOfCarOwner } from "./apis/chat";

const Environment = {
  requestsStatusStatistics: '/buyer/requestsStatusStatistics',
  orders: '/requests',
  allmodels: 'car/allModels',
  modelsOfBrand: 'car/brandModels',
  communicationOptions: 'communication-options',
  notifications: '/notifications',
  getOrder: '/requests',
  getOffersForRequest: '/offer/listOffersForRequest',
  getOffer: '/offer',
  getFilterOrders: '/requests/filterRequests',
  offerUpdateStatus: '/offer/updateOfferStatus',
  getOrderStatistics: '/buyer',
  notificationSetting: '/notifications/settings',
  getOffers: '/requests/filterOffers',
  contactUs: '/landing-page/contact',
  topRatedMediators: '/buyer/topRatedMediators',
  getOfferWithRequestForCompleteRequest: '/offer/listOffersForRequest',
  confirmOffer: '/confirmations/',
  odoRange: 'car/odoRanges',
  modelYearRange: 'car/modelYearRanges',
  priceRange: 'car/priceRanges',
  addOffer: 'requests/',
  editOrder: 'requests',
  getConfirmationRequest: '/confirmations',
  getConfirmationRequest: '/confirmations',
  rateMediator: '/buyer/addMedCommentRating',
  allBrands: 'car/allBrands',
  brandModels: 'car/brandModels',
  colors: 'colors',
  cities: 'car/saudiCities',
  communicationOptions: 'communication-options',
  ownerOrders: 'car-owners/sellMediation/',
  offerOwnerOrders: 'car-owners/mediationOffer',
  orderOwner: 'car-owners/sellMediation',
  uploadFiles: 'util/uploadFiles',
  statusOrder: 'confirmations/request',
  statusOrderOwner: 'car-owners/confirmations/sm',
  userInfo: 'user/info',
  closeOrder: 'requests',
  blockUser: 'block/mediator',
  listMediators: 'block/mediators',
  getFilterOrdersOwner: 'car-owners/sellMediation/filter',
  initiateChat: 'chat/initiate',
  getChatListOfCarOwner: 'car-owners/chat/conversations',
  getChatList: 'chat/conversations',
  listAllOffersMediator:"offer/listOffers",
  statisticsMediators:"mediator/homeStatistics",
  mediatorFavouritesRequests:"mediator/favoriteBuyerRequests",
  addOrderToFavourite:"mediator/favoriteBuyerRequest",
  savedOffers:"saved-offers",
  createOffer:"offer/",
  addOfferMediator:"car-owners/mediationOffer/",
  buyRequests: 'requests/open',
  cancelAccount:"user/closeAccount",
  activateAccount:"user/activateAccount"
};

export default Environment;
