import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Bounce, toast } from "react-toastify"
import { BMW, Calendar, car, grayCar, kilometers, location, moneyGreen, moneyWhite, statisticsOrder, userMail ,saudiUser, star, carIcon, chatRound, rejected, reject, timesOffer, checkOffer} from "../../assets/icons"
import { Lang } from "../../helpers/contents"
import { convertDate, defaultLettersCircle, languageAr, toastConfig } from "../../helpers/helper"
import i18n from "../../languages/i18n"
import { updateStatusOffer } from "../../services/apis/owner"
import ButtonBadge from "../buttons/ButtonBadge"
import ButtonIcon from "../buttons/ButtonIcon"
import CarColor from "../orders/CarColor"
import {Button, Textarea} from "./../index"
const CardOffer=({onClick,offer,goOffer,refreshOffer})=>{
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [loadingUpdateAccept,setLoadingUpdateAccept] = useState(false)
    const [loadingUpdateReject,setLoadingUpdateReject] = useState(false)

    const loader=(type)=>{
        let loaderFalse = type == "accept" ? setLoadingUpdateAccept(false) : setLoadingUpdateReject(false)
        return loaderFalse
    }

    const offerUpdateStatus=async(type)=>{
        console.log(type)
        type == "accept" ? setLoadingUpdateAccept(true) : setLoadingUpdateReject(true)
  
          let data = {
            offerStatusId:type == "accept" ? 3 : 2
          }
          try {
              const response = await updateStatusOffer(data,offer.id);
              console.log('Success:', response);
              toast.success(t("تم التعديل الحالة بنجاح"), toastConfig,loader(type));
              refreshOffer()
            } catch (error) {
              if (error.response) {
                if(error.response.status == 400){
                  toast.error(error.response.data.message, toastConfig,loader(type));
              }else if(error.response.status == 500){
                  toast.error(error.response.data.message, toastConfig,loader(type));
              }
              }
            }
      
      }


    return(
        <div className="rounded-[20px] bg-[#ACEBEB] cursor-pointer flex flex-col justify-between shadow-lg">
            <div>
            <div className="flex flex-row justify-between items-center">
               <div className={`bg-[#00CEBC] flex flex-row items-center ${languageAr ? "rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[0px]" : "rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[0px] rounded-br-[20px]"} text-[14px] text-[#ffffff] p-4 h-[50px]`}>
                   <div>{t("listOrdersCarOwner.offerNumber")}</div>
                   <div className="px-2">:</div>
                   <div>#{offer.id}</div>
               </div>
               <div className="flex flex-row items-center p-2 bg-[#E6EDFF] rounded-[7px] mx-5">
                 <div><img src={Calendar} className="w-[20px] h-[20px]"/></div>
                 <div className="text-[13px] font-[600px] text-[#000000] px-2">12-01-2024</div>
                 <div className="text-[11px] font-[600px] text-[#6A6A6A]">12:13:20</div>
               </div>
            </div>
            <div className="flex flex-row items-center w-[90%] mr-auto ml-auto mt-5" onClick={()=>navigate(`/profile-info/${offer?.medInfo?.id}`)}>
                <div className="rounded-full p-1 bg-[#73D3CB]">{offer.medInfo.profileImg == null ? defaultLettersCircle(offer.medInfo.fullName) : <img className="w-[36px] h-[36px] rounded-full" src={offer.medInfo.profileImg}/>}</div>
                <div className="flex flex-col mx-3">
                    <div className="flex flex-row items-center text-[#3E0292] text-[15px] font-700">
                    <div>{t("listOffers.mediator")}</div>
                   <div className="px-2">:</div>
                   <div>{offer.medInfo.fullName}</div>
                    </div>
                    <div className="flex flex-row items-center">
                    <div><img src={star}/></div>
                    <div className="text-[#3E0292] text-[15px] font-700 mx-3">{offer.medInfo.medAvgRating}</div>
                    {offer.statusId == 3 ? (
                    <div className="bg-[#00CEBC] rounded-[12px] shadow-lg flex flex-row justify-center items-center p-1">
                    <img src={chatRound}/>
                    <div className="text-[13px] font-400 text-white mx-3">{t("statusActions.conversation")}</div>
                     </div>
                    ):null}
                    </div>
                </div>

            </div>

            <div onClick={onClick}>
            <div className="flex flex-row items-center mx-3 my-3">
                <div className="text-[#3E0292] text-[14px] font-500">{t("offerDetails.commission")}:</div>
                <div className="flex flex-row bg-[#04C500CC] rounded-[12px] p-2 mx-2">
                  <div><img src={moneyWhite} className="w-[20px] h-[20px]"/></div>
                  <div className="flex flex-row items-center px-2">
        <div className="text-[12px] font-500 text-[#ffffff] px-2">{offer.commission}</div>
        {/* <div className="text-[12px] font-500 text-[#ffffff]">{t("listOrders.Rial")}</div> */}

    </div>
               </div>
                
            </div>
            </div>
            <div className="mx-3 my-3">
                <Textarea disabled={true} label={t("statusActions.comment")} value={offer.comment} placeholder={t("statusActions.placeholderComment")} rows={4}/>
            </div>
            </div>

            {offer.statusId == 2 ? (
            <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
            <ButtonIcon className="bg-[#DDC0D9] border border-[#DDC0D9] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg" titleClass={"text-[#FD2828] text-[14px] font-500"} icon={timesOffer} dark={false} title={t("statusActions.rejectRequest")}/> 
            </div>
            ):offer.statusId == 3 ?(
                <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
                <ButtonIcon className="bg-[#DEF3FF] border border-[#DEF3FF] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg text-[#00CEBC]" titleClass={"text-[#00CEBC] text-[14px] font-500"} icon={checkOffer} dark={false} title={t("statusActions.acceptRequest")}/> 
                </div>
            ):offer.statusId == 4 ?(
                <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
                <ButtonIcon className="bg-[#DEF3FF] border border-[#DEF3FF] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg text-[#00CEBC]" titleClass={"text-[#00CEBC] text-[14px] font-500"} icon={checkOffer} dark={false} title={t("statusActions.completeRequest")}/> 
                </div>
            ):(
                <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[90%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
                <Button loading={loadingUpdateAccept} className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg" dark={false} title={t("listOffers.accept")} onClick={()=>offerUpdateStatus("accept")} />
                <Button loading={loadingUpdateReject} className="bg-[#FF0000] border border-[#FF0000] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg" dark={false} title={t("listOffers.reject")} onClick={()=>offerUpdateStatus("reject")}/>
            </div>
            )}

        </div>
    )
}

export default CardOffer