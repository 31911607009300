import React, { useState } from 'react';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import i18n from '../../languages/i18n';
const InputRHF = React.forwardRef(
  ({ icon, label, type, placeholder, error, ...rest }, ref) => {
    const isRtl = i18n.dir() === 'rtl';
    document.body.dir = i18n.dir();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };
    const inputType = type === 'password' && isPasswordVisible ? 'text' : type;

    return (
      <div className="w-[100%] relative">
        <div className="flex flex-row items-center text-[#3E0292] text-[12px]">
          {icon && <img src={icon} alt="" />}
          <div className="mx-3 font-400">{label}</div>
        </div>
        <div className="relative">
          <input
            className={`rounded-lg border-[#D9D9D9] text-[#9E9E9E] text-[13px] w-[100%] mt-3 p-3 font-400 ${
              error ? 'border-red-500' : ''
            }`}
            type={inputType}
            placeholder={placeholder}
            ref={ref}
            style={{
              textAlign: isRtl ? 'right' : 'left',
              direction: isRtl ? 'rtl' : 'ltr',
            }}
            {...rest}
          />
          {type === 'password' && (
            <span
              onClick={togglePasswordVisibility}
              className={`absolute ${
                isRtl ? 'left-4' : 'right-4'
              } top-1/2 transform -translate-y-1/2 cursor-pointer pt-3`}
            >
              {isPasswordVisible ? <FaRegEye /> : <FaRegEyeSlash />}
            </span>
          )}
        </div>
        {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
      </div>
    );
  }
);

export default InputRHF;
