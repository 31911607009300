import { useTranslation } from "react-i18next"
import { privacyImage } from "../../../assets/icons"

const Privacy=()=>{
    const {t} = useTranslation()
    return(
      <div className="w-[90%] mr-auto ml-auto">
          <div className="text-[30px] text-[#3E0292] text-center font-500">{t("home.services.privacy")}</div>

          <div><img src={privacyImage} className="w-[100%] mt-5"/></div>
          {/* <div className="font-[400px] text-[#303030] text-[14px] mt-10">{t("privacy.sectionOne")}</div>
          <div className="font-[400px] text-[#303030] text-[14px] mt-10">{t("privacy.sectionTwo")}</div> */}

<div className="grid md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 mt-16">
          <div className="text-[14px] text-[#303030] sm:order-2 lg:order-1 xl:order-1 md:order-1 2xl:order-1 max600:mt-5 ">
              <div className="text-[20px] text-[#3E0292]">{t("terms.welcome.title")}</div>
              <div className="my-3">{t("terms.welcome.descriptionOne")}</div>
              <div>{t("terms.welcome.descriptionTwo")}</div>
              <div className="my-3">{t("terms.welcome.descriptionThree")}</div>
          </div>
              {/* <div className="w-[100%] sm:order-1 lg:order-1 xl:order-1 md:order-1 2xl:order-1"><img src={locationCar} className="w-[100%]"/></div> */}
          </div>
          
          <div className="grid md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 mt-16">
          {/* <div className="w-[100%] max600:order-1"><img src={listCar} className="w-[100%]"/></div> */}
              <div className="text-[14px] text-[#303030] max600:order-2 max600:mt-5">
                  <div className="text-[20px] text-[#3E0292]">{t("terms.cookies.title")}</div>
                  <div className="my-3">{t("terms.cookies.descriptionOne")}</div>
                  <div>{t("terms.cookies.descriptionTwo")}</div>
              </div>

          </div>
          <div className="grid md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 mt-16">
          <div className="text-[14px] text-[#303030] sm:order-2 lg:order-1 xl:order-1 md:order-1 2xl:order-1 max600:mt-5 ">
              <div className="text-[20px] text-[#3E0292]">{t("terms.license.title")}</div>
              <div className="my-3">{t("terms.license.descriptionOne")}</div>
              <div>{t("terms.license.titleListOne")}</div>
              <div className="font-400 px-3 my-3">
                  <ul className="list-disc px-2">
                      <li>{t("terms.license.listOne.one")}</li>
                      <li>{t("terms.license.listOne.two")}</li>
                      <li>{t("terms.license.listOne.three")}</li>
                      <li>{t("terms.license.listOne.four")}</li>
                  </ul>
              </div>
              <div>{t("terms.license.descriptionTwo")}</div>
              <div className="my-3">{t("terms.license.descriptionThree")}</div>
              <div>{t("terms.license.descriptionFour")}</div>
              <div className="my-3">{t("terms.license.descriptionFive")}</div>
              <div>{t("terms.license.titleListTwo")}</div>
              <div className="font-400 px-3 my-3">
                  <ul className="list-disc px-2">
                      <li>{t("terms.license.listTwo.one")}</li>
                      <li>{t("terms.license.listTwo.two")}</li>
                      <li>{t("terms.license.listTwo.three")}</li>
                      <li>{t("terms.license.listTwo.four")}</li>
                  </ul>
              </div>
              <div>{t("terms.license.descriptionSix")}</div>
          </div>
              {/* <div className="w-[100%] sm:order-1 lg:order-1 xl:order-1 md:order-1 2xl:order-1"><img src={locationCar} className="w-[100%]"/></div> */}
          </div>
          <div className="grid md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 mt-16">
          {/* <div className="w-[100%] max600:order-1"><img src={listCar} className="w-[100%]"/></div> */}
              <div className="text-[14px] text-[#303030] max600:order-2 max600:mt-5">
                  <div className="text-[20px] text-[#3E0292]">{t("terms.connection.title")}</div>
                  <div className="my-3">{t("terms.connection.descriptionOne")}</div>
                  <div>{t("terms.connection.descriptionTwo")}</div>
                  <div className="my-3 font-400 px-3">
                      <ul className="list-disc px-2">
                          <li>{t("terms.connection.listOne.one")}</li>
                          <li>{t("terms.connection.listOne.two")}</li>
                          <li>{t("terms.connection.listOne.three")}</li>
                          <li>{t("terms.connection.listOne.four")}</li>
                          <li>{t("terms.connection.listOne.five")}</li>
                      </ul>
                  </div>
                  <div>{t("terms.connection.descriptionThree")}</div>
              </div>

          </div>
          <div className="grid md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 mt-16">
              <div className="text-[20px] text-[#3E0292]">{t("terms.contact.title")}</div>
              <div className="text-[14px] text-[#303030] my-3">{t("terms.contact.descriptionOne")}</div>

          </div>
      </div>
    )
}
export default Privacy