import { Spinner } from "flowbite-react"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import InfiniteScroll from "react-infinite-scroll-component"
import { useNavigate } from "react-router-dom"
import { carOwnerNotFound, plusWhite } from "../../../assets/icons"
import { ButtonIcon, CardOrderOwner, CardSkeleton, FilterOrder, InputSearch, MConfirmation, NotFoundCars } from "../../../components"
import { FilterOrderContext } from "../../../context/FilterOrderContext"
import { userRole } from "../../../helpers/helper"
import { getFilterOrdersOwner, getOrders } from "../../../services/apis/owner"


const List = () => {
  const filterOrderContext = useContext(FilterOrderContext);

  let filterOrderContextCondition = 
                filterOrderContext.brands.length == 0 &&
                filterOrderContext.models.length == 0 &&
                filterOrderContext.statuses.length == 0 &&
                filterOrderContext.cities.length == 0 &&
                !filterOrderContext.priceFrom &&
                !filterOrderContext.priceTo
                console.log(filterOrderContextCondition)
  console.log(filterOrderContext)
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  console.log("🚀 ~ List ~ orders:", orders)
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const observerRef = useRef(null);
  const prevFilter = useRef(filterOrderContext);

  const hasFilterChanged = useCallback(() => {
    return JSON.stringify(prevFilter.current) !== JSON.stringify(filterOrderContext);
  }, [filterOrderContext]);

  const navigate = useNavigate();


  const handleSearch = (e) => {
    setSearchText(decodeURIComponent(e.target.value));
    console.log(searchText)
  };


  const searchAction=()=>{
      fetchOrders(true);
  }

        // if (loading) {
        //     return <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-4 my-5 w-[90%] mr-auto ml-auto">
        //         <CardSkeleton number={3} height={"300px"} />
        //     </div>; 
        // }

        // if (!orders || orders.length === 0) {
        //     return <NotFoundCars/>
        // }

  const fetchOrders = async (reset = false) => {
    setLoading(true)
    console.log(decodeURIComponent(searchText))
    try {
      let body = {}
       if(!filterOrderContextCondition){
        body = {
          brandIds:filterOrderContext.brands.map(brand => brand.value),
          modelIds:filterOrderContext.models.map(model => model.value),
          status:filterOrderContext.statuses.map(status => status.value),
          cityIds:filterOrderContext.cities.map(city => city.value),
          priceFrom:filterOrderContext.price[0],
          priceTo:filterOrderContext.price[1],
          filterOwnRequests:true
        }
      }else{
        body = {
          brandIds:[],
          modelIds:[],
          status:[],
          cityIds:[],
          priceFrom:null,
          priceTo:null,
          filterOwnRequests:true
        }
      }

      

    let data = {
      params: {
        page: currentPage,
        size: 3,
      },
      body: body,
    };

    if(searchText){
      data.params.searchText = searchText
    }

      const response = await getFilterOrdersOwner(data.body,data.params);

      const newOrders = response.data.page.content;
    setOrders((prevPosts) => (reset ? newOrders : [...prevPosts, ...newOrders]));
    setHasMore(newOrders.length > 0);
    setOpenFilter(false)
    prevFilter.current = filterOrderContext;  
    } catch (error) {
      console.error('Error fetching posts:', error);
    }finally {
      setLoading(false);
    }
  };


  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (hasFilterChanged()) {
      fetchOrders(true);
    }else if (hasMore && !loading) {
      fetchOrders();
    }
  }, [currentPage]);

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <InputSearch
          onChange={handleSearch}
          value={searchText}
          action={()=>setOpenFilter(true)}
          showAdd={true}
          showSearch={true}
          add={() => navigate('/car-owner/add')}
          search={()=>searchAction()}
        />
             <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-16 my-5">
              {orders.map((order, index) => (
                <div ref={index === orders.length - 1 ? lastItemRef : null}>
                   <CardOrderOwner order={order} key={order.id} refreshOrders={fetchOrders} goEditPage={()=>navigate(`/car-owner/${order?.sellMediationId}/edit`)} onClick={()=>navigate(`/car-owner/${order?.sellMediationId}/details`)} goOffers={()=>navigate(`/car-owner/${order?.sellMediationId}/offers`)}/>
               </div>
              ))}   
            </div>
            {loading && <div className="flex justify-center bg-[#D7E2FF69] h-[100px] items-center">
                 <Spinner
                  color="success"
                  aria-label="Extra large spinner example"
                  size="xl"
                />
              </div>}
            {!hasMore && <p className="mt-20 text-center text-[#3E0292]">
                 {t('myOrders.loadingAllOrders')}
               </p>}
    <MConfirmation
        loading={loading}
        openModal={openFilter}
        showFooter={false}
        setOpenModal={setOpenFilter}
        title={t("filterModal.filterWith")}
        content={
          <FilterOrder from="owner" close={()=>setOpenFilter(false)} confirm={fetchOrders} />
        }
        singleButton={false}
        confirm={fetchOrders}
      />
    </div>
    </div>
  );
};

export default List
