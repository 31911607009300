import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notFoundOrders } from '../../assets/icons';
import { Button } from '../index';
const NotFoundOrders = ({ from = 'orders', onClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center flex-col my-5">
      <div>
        <img src={notFoundOrders} />
      </div>
      {from == 'orders' ? (
        <div className="text-[17px] text-[#000000] font-500 my-10">
          {t('myOrders.notFoundOrders')}
        </div>
      ) : null}
      {from == 'orders' ? (
        <Button
          onClick={() => navigate('/orders/add')}
          title={t('myOrders.orderNow')}
          dark={false}
          className="bg-[#00CEBC] border-[#00CEBC] w-[250px]"
        />
      ) : null}
      <Button
        onClick={
          from == 'orders' ? () => window.location.reload(false) : onClick
        }
        title={t('myOrders.orders')}
        dark={false}
        className="bg-[#00CEBC] border-[#00CEBC] w-[250px] mt-5"
      />
    </div>
  );
};

export default NotFoundOrders;
