import { z } from 'zod';
import { useTranslation } from 'react-i18next';

const useAddOrderSchema = () => {
  const { t } = useTranslation();

  return z.object({
    carBrands: z.array(z.number()).min(1, t('addOffer.brandSelect')),
    carType: z.array(z.number()).refine((data) => data.every((id) => id > 0), {
      message: t('addOffer.modelSelect'),
    }),
    city: z.array(z.number()).optional(),
    color: z.array(z.number()).optional(),
    yearFrom: z.number().optional(),
    yearTo: z.number().optional(),
    kilometersFrom: z.number().optional(),
    kilometersTo: z.number().optional(),
    priceFrom: z.number().optional(),
    priceTo: z.number().optional(),
    description: z.string().optional(),
    requestTitle: z.string().optional(),
    phoneNumber: z.string().optional(),
    whatsappNumber: z.string().optional(),
  });
};

export default useAddOrderSchema;
