import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { carOwnerNotFound } from "../../assets/icons"
import {Button} from "../index"
const NotFoundCars=({from = "orders",onClick})=>{
    const navigate = useNavigate()
    const {t} = useTranslation()
    return(
        <div className="flex justify-center items-center flex-col py-10">
            <div><img src={carOwnerNotFound}/></div>
            {from == "orders" ? <div className="text-[17px] text-[#303030] font-500 my-10">{t("carOwner.notFoundOffers")}</div>:null}
            {from == "orders" ? <Button onClick={()=>navigate("/")} title={t("carOwner.addCarOffer")} dark={false} className="bg-[#00CEBC] border-[#00CEBC] w-[250px] shadow-lg"/>:null}
            {/* <Button onClick={from == "orders" ? ()=>window.location.reload(false) : onClick} title={t("myOrders.orders")} dark={false} className="bg-[#00CEBC] border-[#00CEBC] w-[250px] mt-5"/> */}

        </div>
    )
}

export default NotFoundCars