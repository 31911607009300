import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CardOffer,
  InputSearch,
  NotFoundOrders,
  CardSkeleton,
  MCardOffer,
  MCardSavedOffer,
} from '../../../../components';
import { getAllOffersForMediator, getAllSavedOffersForMediator, getOffers, getOffersForOrder } from '../../../../services/apis/offer';
import { Spinner ,Tabs} from 'flowbite-react';
import { themeTabs } from '../../../../helpers/contents';
import { userRole } from '../../../../helpers/helper';

const MOffers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation()
  console.log(location?.state?.status)
  const [offers, setOffers] = useState([]);
  const [dates, setDates] = useState([
    {value:"today",label:t("offerCycle.today")},
    {value:"yesterday",label:t("offerCycle.yestarday")},
    {value:"this_week",label:t("offerCycle.thisWeek")},
    {value:"this_month",label:t("offerCycle.thisMonth")},
    {value:"old_months",label:t("offerCycle.lastMonths")},
  ]);
  const [savedOffers, setSavedOffers] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [dateOption,setDateOption] = useState(null)
  const [searchText, setSearchText] = useState('');
  const observerRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { id } = useParams();


  const getAllOffersForRequest = async (reset = false) => {
    setLoading(true);
    // try{
    let data = {
      params: {
        page: reset ? 0 : currentPage,
        size:6,
        searchText:searchText,
        dateOption:dateOption
      },
      
    };
    const response = await getAllOffersForMediator(data);

    const newOffers = response.data.offers.content;
    setOffers((prevPosts) => (reset ? newOffers : [...prevPosts, ...newOffers]));
    setHasMore(newOffers.length > 0);
    setLoading(false);
// } catch (error) {
//     console.error('Error fetching posts:', error);
//   }finally {
//     setLoading(false);
//   }
  };




  const getAllSavedOffers = async () => {
    setLoading(true);
    // try{
    let data = {
      params: {
        searchText:searchText
      },
      
    };
    const response = await getAllSavedOffersForMediator(data);
    // const newOffers = response.data.items;
    setSavedOffers(response.data.items)
    // setSavedOffers((prevPosts) => (reset ? newOffers : [...prevPosts, ...newOffers]));
    // setHasMore(newOffers.length > 0);
    // setLoading(false);
// } catch (error) {
//     console.error('Error fetching posts:', error);
//   }finally {
//     setLoading(false);
//   }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const searchAction = () => {
    getAllOffersForRequest(true);
  };

  const searchActionSavedOffers = () => {
    getAllSavedOffers();
  };

//   if (loading) {
//     return (
//       <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-4 my-5 w-[90%] mr-auto ml-auto">
//         <CardSkeleton number={3} height={'300px'} />
//       </div>
//     );
//   }

//   if (!offers || offers.length === 0) {
//     return <NotFoundOrders from="offers" onClick={() => navigate(`/offers`)} />;
//   }


  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore]
  );


  useEffect(() => {
    getAllOffersForRequest(currentPage === 0);
}, [currentPage,dateOption]);


 useEffect(() => {
        getAllSavedOffers()
}, []);

const handleDateOptionChange = (value) => {
  setDateOption(value);
  setCurrentPage(0);
  setOffers([]);
};


  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
      <Tabs theme={themeTabs} aria-label="Tabs Chat">
      <Tabs.Item active title={t('offerCycle.sendOffers')}>
        <InputSearch
          onChange={handleSearch}
          value={searchText}
          search={searchAction}
          showFilter={false}
          showSearch={true}
        />
        <div className='grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 max600:grid-cols-1  gap-2'>
          {dates.map((date,index)=>(
         <div key={index} className={`${dateOption == date.value ? "bg-white text-[#00CEBC] border border-[#00CEBC]" : "bg-[#00CEBC] text-white"} rounded-[12px] p-2 text-center mt-3 font-500 text-[15px] cursor-pointer shadow-md`} onClick={()=>handleDateOptionChange(date.value)}>
          {date.label}
         </div>
          ))}
        </div>

        <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-16 my-5">
          {offers.map((offer, index) => (
            <div ref={index === offers.length - 1 ? lastItemRef : null}>
            <MCardOffer
              key={offer.offerId}
              offer={offer}
              onClick={() =>
                navigate(`/orders/${offer?.requestId}/offers/${offer?.offerId}`)
              }
            />
            </div>
          ))}
        </div>
        {loading && <div className="flex justify-center bg-[#D7E2FF69] h-[100px] items-center">
                 <Spinner
                  color="success"
                  aria-label="Extra large spinner example"
                  size="xl"
                />
              </div>}
            {!hasMore && <p className="mt-20 text-center text-[#3E0292]">
                 {t('myOrders.loadingAllOrders')}
             </p>}
             </Tabs.Item>
             <Tabs.Item title={t('offerCycle.savedOffers')}>
             <InputSearch
          onChange={handleSearch}
          value={searchText}
          search={searchActionSavedOffers}
          showFilter={false}
          showSearch={true}
          showAdd={true}
          add={()=>navigate("/offers/add")}
        />
             <div className={`grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 ${userRole == 3 ? "gap-y-4" : "gap-y-16"}  my-5`}>
          {savedOffers.map((offer, index) => (
            <div>
            <MCardSavedOffer
              key={offer.id}
              offer={offer}
              onClick={() =>
                navigate(`/offers/${offer?.id}/edit`)
              }
            />
            </div>
          ))}
        </div>
             </Tabs.Item>

             </Tabs>
      </div>
      
    </div>
  );
};

export default MOffers;
