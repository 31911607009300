import { useTranslation } from 'react-i18next'
import i18n from '../../../languages/i18n'
import CarColor from '../CarColor'
import {carIcon, grayCar, kilometers, location, moneyWhite} from './../../../assets/icons/index'
const Car=({selected = false,car,onClick})=>{
    const {t} = useTranslation()
    return(
        <div className={`bg-[#D7E2FF] p-3 rounded-[20px] mt-3 ${selected ? "border-4 border-[#3E0292]" : "border-0"}`} onClick={onClick}>
                <div className="grid lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%] mr-auto ml-auto">
                <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
            <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                          <div><img className="w-[50px]" src={car?.brand?.image}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">{i18n.language == "ar" ? car?.brand?.nameAr : car?.brand?.nameEn}</div>
                      </div>
                      </div>
                      <div className="p-1 rounded-[12px] bg-[#E6EDFF]">

                      <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                          <div><img src={location}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">{i18n.language == "ar" ? car?.city?.name : car?.brand?.nameEn}</div>
                      </div>
                      </div>
                      <div className="p-1 rounded-[12px] bg-[#E6EDFF]">

                      <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                          <div><CarColor color={"#ff0000"}/> </div>
                          <div style={{color:"#ff0000"}} className="text-[12px] font-400 px-2">{i18n.language == "ar" ? car?.color?.name : car?.brand?.nameEn}</div>
                      </div>
                      </div>
                      <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">

                       <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                          <div><img src={grayCar}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
                      </div>
                      </div>
                      <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">

                      <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 h-[100%]">
            <div><img src={kilometers}/></div>
            <div className="flex flex-row items-center px-2">
                <div className="text-[#000000] text-[13px] font-400">{car?.odo}</div>
                <div className="text-[#BBBBBB] text-[10px] font-400 px-2">{t("listOrders.km")}</div>

            </div>

            </div>
            </div>

            </div>
     <div className="flex flex-row items-center mt-3">
                <div className="text-[#3E0292] text-[14px] font-bold">{t("offerDetails.commission")}:</div>
                <div className="flex flex-row bg-[#04C500CC] rounded-[12px] p-3 mx-2">
                  <div><img src={moneyWhite}/></div>
                  <div className="flex flex-row items-center px-2">
        <div className="text-[12px] font-500 text-[#ffffff] px-2">{car?.commission}</div>
        <div className="text-[12px] font-500 text-[#ffffff]">{t("listOrders.Rial")}</div>

    </div>
               </div>
                
            </div>
        </div>
    )
}

export default Car