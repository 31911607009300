import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  aboutUsImage,
  filterImage,
  keyHome,
  orderBuyer,
  orderOwner,
} from '../../../assets/icons';
import {
  AddOrder,
  CarsMostBuyer,
  CarsType,
  ChooseCar,
  DashboardCard,
  DownloadApp,
  Filter,
  GeneralSwiper,
  RegisterMobile,
  ServicesCard,
  UserCardSwiper,
  ButtonIcon,
  MDashboardCard,
  HomeOffers,
  WhyMshrai,
  Button,
} from '../../../components';
import { userRole, userToken } from '../../../helpers/helper';
import { getMediators } from '../../../services/apis/home';
const users = [
  { id: 1, name: 'عائشة الأنور', img: null, text: '', stars: 5, car: false },
  { id: 2, name: 'أكرم السعد', img: null, text: '', stars: 5, car: false },
  {
    id: 3,
    name: 'محمد الشمال',
    img: null,
    text: 'كانت التجربة استثنائية بكل المقاييس...',
    stars: 5,
    highlighted: true,
    car: false,
  },
  { id: 4, name: 'نوى الحسن', img: null, text: '', stars: 5, car: false },
  { id: 5, name: 'علاء سعد', img: null, text: '', stars: 5, car: false },
];
const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mediators, setMediators] = useState([]);

  useEffect(() => {
    if (userToken && userRole == 2) {
      getAllMediators();
    }
  }, []);

  const getAllMediators = async () => {
    // setLoading(true)
    const response = await getMediators('');
    let arr = [];
    response.data.items.forEach((element) => {
      let obj = {
        name: element.mediatorInfo.fullName,
        car: false,
        stars: element.mediatorInfo.medAvgRating,
        img: element.mediatorInfo.profileImg,
        id: element.mediatorInfo.id,
      };
      arr.push(obj);
    });
    setMediators(arr);
  };


  return (
    <div>
      <div className={`${userRole == 3 && userToken ? "bg-[#E3FAF8]" : "bg-[#D7E2FF]"} rounded-[15px] p-5 w-[90%] mr-auto ml-auto`}>
        {userRole == 3 && userToken ? (
        <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
        <div className="text-[#00CEBC] font-900 text-[43px] flex items-center justify-center text-right font-[Kufam] drop-shadow-lg">
          {t('home.filter.addOfferToBuyers')}
        </div>
        <div className='m-auto'>
        <div className="flex justify-center items-center rounded-full bg-[#7FE7DD] h-[400px] w-[400px]">
          <img src={keyHome} className="w-[400px] h-[400px]" />
        </div>
        </div>
      </div>
        ):(
          <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
          <div className="flex justify-center items-center">
            <img src={filterImage} className="w-[500px]" />
          </div>
          <div className="text-[#3E0292] font-900 text-[43px] flex items-center justify-center text-right font-[Kufam] drop-shadow-lg">
            {t('home.filter.getCar')}
          </div>
        </div>
        )}
        {!userToken || userRole == 3 ? null : (
          <div className="flex items-center justify-center">
            <Filter />
          </div>
        )}
      {userRole == 2 && (
        <div className="grid grid-cols-2 max600:grid-cols-1 sm:grid-cols-2 my-10 w-[80%] mr-auto ml-auto gap-x-3">
          <ButtonIcon
            titleClass={'text-[15px] font-700 text-[#ffffff]'}
            onClick={() => {
              !userToken ? navigate('/login') : navigate('/car-owner/add');
            }}
            className={'shadow-lg bg-[#3E0292] border-[#3E0292]'}
            dark={false}
            title={t('home.filter.addOrderCarOwner')}
            icon={orderOwner}
          />
          <ButtonIcon
            titleClass={'text-[15px] font-700 text-[#ffffff]'}
            onClick={() => {
              !userToken ? navigate('/login') : navigate('/orders/add');
            }}
            dark={false}
            className="bg-[#00CEBC] shadow-lg border-[#00CEBC]"
            title={t('home.filter.addOrderBuyer')}
            icon={orderBuyer}
          />
        </div>
        )}
      </div>

      {!userToken || userRole == 3 ? (
           <WhyMshrai/>
      ):null}

      {!userToken ? (
        <>
          <CarsType />
          <CarsMostBuyer />
          <RegisterMobile />
          <ChooseCar />
        </>
      ) : (
        <>
        {userRole == 3 ? <MDashboardCard/> : <DashboardCard />}
        </>
      )}
      {userRole == 3 && (
      <div className='my-10 bg-[#C7F1EF] rounded-[15px] w-[90%] mr-auto ml-auto p-5'> 
      <div className='flex flex-row items-center justify-between p-5'>  
      <div className="text-[30px] text-[#444444] underline mb-5 font-700">
            {t('home.clients.lastOffers')}
      </div>
      <Button title={t('home.clients.allOffers')} dark={true} onClick={()=>navigate("/offers")}/>
      </div>
      <HomeOffers/>
      </div>)}
      {userRole == 2 && (<AddOrder />)}

      {!userToken ? (
        <div className="mt-16">
          <div className="text-[40px] text-[#444444] underline text-center mb-5 font-700">
            {t('home.clients.title')}
          </div>
          <GeneralSwiper users={users} />
        </div>
      ) : (
        <>
        {userRole == 3 ? null : (
        <div className="mt-16">
          <div className="text-[40px] text-[#444444] underline text-center mb-5 font-700">
            {t('home.clients.titleMediators')}
          </div>
          <GeneralSwiper users={mediators} />
        </div>
          )}
        </>
      )}

      {!userToken ? <DownloadApp /> : null}
      <ServicesCard />
    </div>
  );
};

export default Home;
