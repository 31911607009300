import { Pagination } from 'flowbite-react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CardOrder,
  InputSearch,
  NotFoundOrders,
  CardSkeleton,
  Button,
  ButtonIcon,
  MConfirmation,
  FilterOrder,
} from '../../../../components';
import { FilterContext } from '../../../../context/FilterContext';
import { getBuyOrders, getFilterOrders, getOrders } from '../../../../services/apis/order';
import Environment from '../../../../services/Environment';
import { get } from '../../../../services/Request';
import { themePagination } from '../../../../helpers/contents';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'flowbite-react';
import { plusWhite } from './../../../../assets/icons/index';
import { FilterOrderContext, FilterOrderProvider } from '../../../../context/FilterOrderContext';
import { userRole } from '../../../../helpers/helper';

const List = () => {
  const filterContext = useContext(FilterContext);
  const filterOrderContext = useContext(FilterOrderContext);
  let filterContextCondition = 
                filterContext.data.brandIds.length == 0 &&
                filterContext.data.modelIds.length == 0 &&
                filterContext.data.status.length == 0 &&
                filterContext.data.cityIds.length == 0
                console.log(filterOrderContext)
  let filterOrderContextCondition = 
                filterOrderContext.brands.length == 0 &&
                filterOrderContext.models.length == 0 &&
                filterOrderContext.statuses.length == 0 &&
                filterOrderContext.cities.length == 0 &&
                !filterOrderContext.price[0] &&
                !filterOrderContext.price[1]
                console.log(filterContextCondition)
                console.log(filterOrderContextCondition)
  console.log(filterOrderContext)
  const { t } = useTranslation();
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  console.log("🚀 ~ List ~ orders:", orders)
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([
    {value:"",label:t("offerCycle.all")},
    {value:"today",label:t("offerCycle.today")},
    {value:"yesterday",label:t("offerCycle.yestarday")},
    {value:"this_week",label:t("offerCycle.thisWeek")},
    {value:"this_month",label:t("offerCycle.thisMonth")},
    {value:"old_months",label:t("offerCycle.lastMonths")},
  ]);
  const [posts, setPosts] = useState([]);
  const [dateOption,setDateOption] = useState(null)

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const observerRef = useRef(null);
  const prevFilter = useRef(filterOrderContext);
  const prevFilterHome = useRef(filterContext); 

  const hasFilterChanged = useCallback(() => {
    return JSON.stringify(prevFilter.current) !== JSON.stringify(filterOrderContext);
  }, [filterOrderContext]);

  const hasFilterHomeChanged = useCallback(() => {
    return JSON.stringify(prevFilterHome.current) !== JSON.stringify(filterContext);
  }, [filterContext]);

  const navigate = useNavigate();


  // const handleSearch = (e) => {
  //   setSearchText(e.target.value);
  // };


  // const searchAction=()=>{
  //   fetchOrders()
  // }


        // if (loading) {
        //     return <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-4 my-5 w-[90%] mr-auto ml-auto">
        //         <CardSkeleton number={3} height={"300px"} />
        //     </div>; 
        // }

        // if (!orders || orders.length === 0) {
        //     return <NotFoundOrders/>
        // }

  const fetchOrders = async (reset = false) => {
    setLoading(true)
    if (location?.state) {
      console.log(location?.state?.status);
      filterContext.data.status[0] = location?.state?.status;
    }
    try {
      let body = {}
      if(location?.state || !filterContextCondition){
        body = filterContext.data
      }else if(!filterOrderContextCondition){
        body = {
          brandsId:filterOrderContext.brands.map(brand => brand.value),
          modelIds:filterOrderContext.models.map(model => model.value),
          status:filterOrderContext.statuses.map(status => status.value),
          cityIds:filterOrderContext.cities.map(city => city.value),
          priceFrom:filterOrderContext.price[0],
          priceTo:filterOrderContext.price[1]
        }
      }else{
        body = {
          brandsId:[],
          modelIds:[],
          status:[],
          cityIds:[],
          priceFrom:null,
          priceTo:null
        }
      }

    let data = {
      params: {
        page: currentPage,
        size: 3,
      },
      body: body,
    };

      const response = await getFilterOrders(data.body,data.params);

      const newOrders = response.data.page.content;
    setOrders((prevPosts) => (reset ? newOrders : [...prevPosts, ...newOrders]));
    setHasMore(newOrders.length > 0);
    setOpenFilter(false)
    prevFilter.current = filterOrderContext;  
    prevFilterHome.current = filterContext;  
    } catch (error) {
      console.error('Error fetching posts:', error);
    }finally {
      setLoading(false);
    }
  };




  const getBuyRequests = async (reset = false) => {
    setLoading(true)
    // if (location?.state) {
    //   console.log(location?.state?.status);
    //   filterContext.data.status[0] = location?.state?.status;
    // }
    try {
    //   let body = {}
    //   if(location?.state || !filterContextCondition){
    //     body = filterContext.data
    //   }else if(!filterOrderContextCondition){
    //     body = {
    //       brandsId:filterOrderContext.brands.map(brand => brand.value),
    //       modelIds:filterOrderContext.models.map(model => model.value),
    //       status:filterOrderContext.statuses.map(status => status.value),
    //       cityIds:filterOrderContext.cities.map(city => city.value),
    //       priceFrom:filterOrderContext.price[0],
    //       priceTo:filterOrderContext.price[1]
    //     }
    //   }else{
    //     body = {
    //       brandsId:[],
    //       modelIds:[],
    //       status:[],
    //       cityIds:[],
    //       priceFrom:null,
    //       priceTo:null
    //     }
    //   }

    let data = {
      params: {
        page: reset ? 0 : currentPage,
        size: 3,
        dateOption:location?.state?.dateOption ? location?.state?.dateOption : dateOption,
      },
    };

    if(location?.state?.filter){
      data.params.filter = location?.state?.filter
    }

      const response = await getBuyOrders(data);

      const newOrders = response.data.page.content;
    setOrders((prevPosts) => (reset ? newOrders : [...prevPosts, ...newOrders]));
    setHasMore(newOrders.length > 0);
    // setOpenFilter(false)
    // prevFilter.current = filterOrderContext;  
    // prevFilterHome.current = filterContext;  
    } catch (error) {
      console.error('Error fetching posts:', error);
    }finally {
      setLoading(false);
    }
  };


  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if(userRole == 3){
      getBuyRequests(currentPage === 0)
    }else{
    if (hasFilterChanged()) {
      fetchOrders(true);
    }else if (hasFilterHomeChanged()) {
      fetchOrders(true); 
    }else if (hasMore && !loading) {
      fetchOrders();
    }
  }
  }, [currentPage,dateOption]);


  const handleDateOptionChange = (value) => {
    setDateOption(value);
    setCurrentPage(0);
    setOrders([]);
  };

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <InputSearch
          // onChange={handleSearch}
          value={searchText}
          action={()=>setOpenFilter(true)}
          showAdd={userRole == 3 ? false : true}
          showSearch={true}
          showFilter={userRole == 3 ? false : true}
          add={() => navigate('/orders/add')}
          // search={()=>searchAction()}
        />
         {userRole == 3 && (
          <div className='grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 max600:grid-cols-1  gap-2'>
          {dates.map((date,index)=>(
         <div key={index} className={`${dateOption == date.value ? "bg-white text-[#00CEBC] border border-[#00CEBC]" : "bg-[#00CEBC] text-white"} rounded-[12px] p-2 text-center mt-3 font-500 text-[15px] cursor-pointer shadow-md`} onClick={()=>handleDateOptionChange(date.value)}>
          {date.label}
         </div>
          ))}
        </div>
          )}

             <div className={`grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 ${userRole == 3 ? "gap-y-4" : "gap-y-16"} my-5`}>
              {orders.map((order, index) => (
                <div ref={index === orders.length - 1 ? lastItemRef : null}>
                <CardOrder
                  order={order}
                  key={order.id}
                  goOffers={() => navigate(`/orders/${order.id}/offers`)}
                  goStatistics={() =>
                    navigate(`/orders/${order.id}/statistics`)
                  }
                  onClick={() => navigate(`/orders/${order.id}`)}
                />
               </div>
              ))}   
            </div>
            {loading && <div className="flex justify-center bg-[#D7E2FF69] h-[100px] items-center">
                 <Spinner
                  color="success"
                  aria-label="Extra large spinner example"
                  size="xl"
                />
              </div>}
            {!hasMore && <p className="mt-20 text-center text-[#3E0292]">
                 {t('myOrders.loadingAllOrders')}
               </p>}
    <MConfirmation
        loading={loading}
        openModal={openFilter}
        showFooter={false}
        setOpenModal={setOpenFilter}
        title={t("filterModal.filterWith")}
        content={
          <FilterOrder from="normal" close={()=>setOpenFilter(false)} confirm={fetchOrders} />
        }
        singleButton={false}
        confirm={fetchOrders}
      />
    </div>
    </div>
  );
};

export default List;
