import { Bounce } from "react-toastify";
import i18n from "../languages/i18n";
import { Lang } from "./contents";

export const languageAr = i18n.language == Lang.ar

export const userRole = localStorage.getItem("userRole")
export const userToken = localStorage.getItem("token")


export const convertDate=(date)=>{
    const newDate = new Date(date).toISOString().split("T")[0];
    return newDate
}

  export const statueses = [
    {label:languageAr ? "مفتوح" : "Open",value:"OPEN"},
    {label:languageAr ? "مغلق" : "Close",value:"CLOSED"},
    {label:languageAr ? "مكتمل" : "Completed",value:"COMPLETED"}
  ];

  export const statuesesOwner = [
    {label:languageAr ? "مفتوح" : "Open",value:1},
    {label:languageAr ? "مغلق" : "Close",value:2},
    {label:languageAr ? "مكتمل" : "Completed",value:3}
  ];



  export const toastConfig = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
}


  export function validateSaudiPhone(phone) {
    // const saudiPhoneRegex = /^(?:\966|0)(5\d{8})$/;
    // return saudiPhoneRegex.test(phone);
    const saudiPhoneRegex = /^05\d{8}$/;
    return saudiPhoneRegex.test(phone);
  }
  
  export function validatePassword(password) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  }


  export function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }


  // export function defaultLettersCircle(fullName,width = "w-[30px]",height = "h-[30px]"){
  //     const nameParts = fullName.trim().split(" ");
    
  //     const firstNameInitial = nameParts[0] ? nameParts[0][0].toUpperCase() : "";
    
  //     const secondNameInitial = nameParts[1] ? nameParts[1][0].toUpperCase() : "";
    
  //   return <div className={`bg-[#73D3CB] ${width} ${height} rounded-full flex flex-row items-center justify-center text-[18px] text-[#3E0292]`}>{firstNameInitial + "     " + secondNameInitial}</div>
  // }


  export function defaultLettersCircle(fullName, width = "w-[30px]", height = "h-[30px]") {
    const validFullName = fullName ? fullName.trim() : "";
  
    const nameParts = validFullName.split(" ");
  
    const firstNameInitial = nameParts[0] ? nameParts[0][0].toUpperCase() : "";
    const secondNameInitial = nameParts[1] ? nameParts[1][0].toUpperCase() : "";
  
    return (
      <div
        className={`bg-[#73D3CB] ${width} ${height} rounded-full flex flex-row items-center justify-center text-[18px] text-[#3E0292]`}
      >
        {firstNameInitial + " " + secondNameInitial}
      </div>
    );
  }


  export const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.setItem("i18nextLng", "ar");
};
  