import { Carousel } from "flowbite-react";
import { Button, ButtonIcon, CardOfferDetails , ChooseMediator, CompleteOrder, InputSearch, MConfirmation, MediatorRate, CardSkeleton } from './../../../components'
import {useTranslation} from 'react-i18next'
import { clock, info, saudiUser, star ,chatLight, chatWhite,reject, rejected,menuDots,loadingGreen,loadingRed, confirmIcon, checkOffer, timesOffer} from "./../../../assets/icons";
// import i18n from "../../../../languages/i18n";
import { Lang, themeCarousel } from "./../../../helpers/contents";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { confirmationOffer, getOffer, getOfferWithRequestForCompleteRequest, updateOfferStatus } from "./../../../services/apis/offer";
import { Bounce, toast } from "react-toastify";
import { convertDate, languageAr, toastConfig } from "./../../../helpers/helper";
// import { Dropdown } from "flowbite-react";
// import Slider from "react-slick";
import './offer.css'
import { getOfferDetails ,getOrderDetails, updateStatusOffer} from "../../../services/apis/owner";
import { CompleteOfferContext } from "../../../context/CompleteOfferContext";
const OfferDetails=()=>{
    const {t} = useTranslation()
    const completeOfferContext = useContext(CompleteOfferContext)
    console.log(completeOfferContext)
    const navigate = useNavigate()
    const {offerId,id} = useParams()
    const [offer,setOffer] = useState({})
    const [order,setOrder] = useState({})
    const [loading,setLoading] = useState(false)
    const [openChooseCarModal,setOpenChooseCarModal] = useState(false)
    const [openConfirmModal,setOpenConfirmModal] = useState(false)
    const [openCompleteOfferModal,setOpenCompleteOfferModal] = useState(false)
    const [openRateMediatorModal,setOpenRateMediatorModal] = useState(false)
    const [loadingUpdateAccept,setLoadingUpdateAccept] = useState(false)
    const [loadingUpdateReject,setLoadingUpdateReject] = useState(false)
    const [loadingOffers,setLoadingOffers] = useState(false)
    const [offers,setOffers] = useState([])
 
    const loader=(type)=>{
        let loaderFalse = type == "accept" ? setLoadingUpdateAccept(false) : setLoadingUpdateReject(false)
        return loaderFalse
    }
    useEffect(()=>{
    getOrder()
    },[])

    const getOrder = async()=>{
        setLoading(true)
       const response = await getOrderDetails("",id)
       console.log(response)

       setOrder(response.data,getOffer())
    }

       const getOffer = async()=>{
        setLoading(true)
       const response = await getOfferDetails("",offerId)
       console.log(response)
       setOffer(response.data,setLoading(false))
    }


    const offerUpdateStatus=async(type)=>{
      type == "accept" ? setLoadingUpdateAccept(true) : setLoadingUpdateReject(true)

        let data = {
          offerStatusId:type == "accept" ? 3 : 2
        }
        try {
            const response = await updateStatusOffer(data,offerId);
            console.log('Success:', response);
            toast.success(t("تم التعديل الحالة بنجاح"), toastConfig,loader(type));
          } catch (error) {
            if (error.response) {
              if(error.response.status == 400){
                toast.error(error.response.data.message, toastConfig,loader(type));
            }else if(error.response.status == 500){
                toast.error(error.response.data.message, toastConfig,loader(type));
            }
            }
          }
    
    }

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };


  const getOffersWithRequest = async()=>{
   setLoadingOffers(true)
   const response = await getOfferWithRequestForCompleteRequest("",id)
   console.log(response)
   setOffers(response.data.offers,setLoadingOffers(false))
  //  setOffers(response)
  }


  const confirm=()=>{
    setOpenCompleteOfferModal(false)
    setOpenChooseCarModal(true)
  }

  const confirmRequest=async()=>{
    let cars = []
    completeOfferContext.selectedCars.forEach(carId => {
       cars.push({offerCarId:carId})
    });
    let data = {
      cars:cars
    }
    try {
      const response = await confirmationOffer(data);
      setOpenChooseCarModal(false)
      setOpenConfirmModal(true)
      console.log('Success:', response);
      toast.success(t("تم تاكيد الطلب بنجاح"), toastConfig);
    } catch (error) {
      if (error.response) {
        if(error.response.status == 400){
          toast.error(error.response.data.message, toastConfig);
      }else if(error.response.status == 500){
          toast.error(error.response.data.message, toastConfig);
      }
      }
    }
  }

  const completeRequestConfirm=()=>{
    confirmRequest()
  }

  const goToHome=()=>{
    navigate("/")
    // setOpenRateMediatorModal(true)
  }


   return(
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
    <div className="w-[90%] mr-auto ml-auto">
        <div className="flex flex-row items-center justify-between my-5">
            <div className="flex flex-row items-center">
            <div className="bg-[#3E0292] p-3 rounded-[10px] text-[#ffffff] text-[14px] font-500 flex flex-row items-center">
            <div>{t("offerDetails.details")}</div>
               <div className="px-2">:</div>
               <div>#{offer.id}</div>
            </div>
            <div className="bg-[#00CEBC] rounded-[7px] flex flex-row items-center p-1 mx-5">
              <div><img src={info}/></div>
              <div className="text-[#ffffff] flex flex-row items-center text-[12px] font-[600px] mx-3">
              <div>{t("offerDetails.containOffer")}</div>
               <div className="px-2">{order?.cars?.length}</div>
               <div>{t("offerDetails.carsTitle")}</div>
              </div>
            </div>
            </div>
            <div className="flex flex-row items-center">
            {offer.statusId == 2 ? (
            <div className="flex flex-row items-center justify-around p-2 bg-[#FF00004D] rounded-[7px] mx-5">
                    <div><img src={timesOffer}/></div>
                    <div className="text-[#FD2828] text-[11px] font-[600px] mx-3">{t("offerDetails.reject")}</div>
            </div>
            ):offer.statusId == 4 ? (
              <div className="flex flex-row items-center justify-around p-2 bg-[#00CEBC4D] rounded-[7px] mx-5">
              <div><img src={checkOffer}/></div>
              <div className="text-[#00CEBC] text-[11px] font-[600px] mx-3">{t("statusActions.complete")}</div>
             </div>
            ):offer.statusId == 3 ?(
              <div className="flex flex-row items-center justify-around p-2 bg-[#00CEBC4D] rounded-[7px] mx-5">
              <div><img src={checkOffer}/></div>
              <div className="text-[#00CEBC] text-[11px] font-[600px] mx-3">{t("statusActions.accept")}</div>
             </div>
            ):null}
            <div className="flex flex-row items-center justify-around p-2 bg-[#E6EDFF] rounded-[7px] mx-3">
                    <div><img src={clock}/></div>
                    {order?.createdDate ? <div className="text-[#3E0292] mx-3"><span className="font-bold text-[13px]">{convertDate(order?.createdDate)}</span> <span className="text-[11px] font-[600px]">{(order?.createdDate?.split('T')[1])?.slice(0, 8)}</span></div> : null}
                </div>
                <div>

    <div className="relative">
   {offer.statusId == 3 ?( <img src={menuDots} onClick={toggleMenu} className="cursor-pointer"/>) : null}
      {isOpen && (
        <div
          className={`absolute ${languageAr ? "left-0" : "right-0"} mt-2 w-[100px] z-10 bg-white border border-gray-200 rounded-[12px] shadow-lg`}
          onMouseLeave={closeMenu} 
        >
          <ul className="py-2">
            <li>
              <div className="flex flex-row items-center justify-around p-3 cursor-pointer" onClick={()=>setOpenCompleteOfferModal(true,getOffersWithRequest())}>
               <div><img src={loadingGreen}/></div>
               <div className="text-[#00CEDF] text-[15px] font-400">{t("completeRequest.completed")}</div>
              </div>
            </li>
            <li>
               <div className="flex flex-row items-center justify-around border-t border-t-[#E9E9E9] p-3 cursor-pointer">
               <div><img src={loadingRed}/></div>
               <div className="text-[#CE4223] text-[15px] font-400">{t("completeRequest.close")}</div>
              </div>
            </li>
          </ul>
        </div>
      )}
      </div>
                  
                </div>
                </div>
        </div>
    <div className="carousel-container" style={{ direction: "ltr" }}>
      {/* <Carousel 
      theme={themeCarousel}
       > */}
      {loading ?

<CardSkeleton number={order?.cars?.length} height={"500px"} />

:order?.cars?.map((car,index)=>(
  <CardOfferDetails key={car.id} mediator={order.carOwnerInfo} goProfile={()=>navigate(`/profile-info/${order.carOwnerInfo.id}`)} carOffer={car} from="owner" offer={offer} commision={offer.commission}/>
//    )}
 ))}
      {/* </Carousel>  */}
    </div>
    

    {/* {offer.statusId == 3 && from != "owner" ? (
    <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%]  mr-auto ml-auto gap-x-2 mt-5">
    <ButtonIcon loading={loadingUpdateAccept} icon={chatWhite} className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0" dark={false} title={t("بدء محادثه مع الوسيط عبر شات مشراى")}/>
    </div>
    ): */}
    {offer.statusId == 2 ? (
      <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%]  mr-auto ml-auto gap-x-2 mt-5">
      <ButtonIcon loading={loadingUpdateReject} icon={reject} titleClass="text-[#FD2828]" className="bg-[#FF00004D] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0" dark={false} title={t("offerDetails.rejected")}/>
      </div>
    ):offer.statusId == 1 ?(
      <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[50%]  mr-auto ml-auto gap-x-2 mt-5">
      <Button loading={loadingUpdateAccept} className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0" dark={false} title={t("offerDetails.accept")} onClick={()=>offerUpdateStatus("accept")}/>
      <Button loading={loadingUpdateReject} className="bg-[#FF0000] border border-[#FF0000] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0" dark={false} title={t("offerDetails.reject")} onClick={()=>offerUpdateStatus("reject")}/>
      </div>
    ):null}
    </div>
    <MConfirmation loading={loading} openModal={openCompleteOfferModal} showFooter={true} setOpenModal={setOpenCompleteOfferModal} title={t("completeRequest.chooseMediator")} content={<ChooseMediator offers={offers} loadingOffers={loadingOffers}/>} confirm={confirm}/>
    <MConfirmation loading={loading} openModal={openChooseCarModal} showFooter={true} setOpenModal={setOpenChooseCarModal} title={t("completeRequest.completeRequest")} content={<CompleteOrder offer={completeOfferContext.offer}/>} confirm={completeRequestConfirm}/>
    <MConfirmation loading={loading} openModal={openConfirmModal} showFooter={true} setOpenModal={setOpenConfirmModal} title={t("completeRequest.successSentToMediator")} content={<div className="flex justify-center items-center"><img src={confirmIcon}/></div>} titleButton={t("completeRequest.backToHome")} singleButton={true} confirm={goToHome}/>
    {/* <MConfirmation loading={loading} openModal={openRateMediatorModal} showFooter={true} setOpenModal={setOpenRateMediatorModal} title={t("تقييم الوسيط")} content={<MediatorRate/>} titleButton={t("completeRequest.backToHome")} singleButton={true} confirm={goToHome}/> */}

    </div>

   )
}
export default OfferDetails