import Environment from "../Environment";
import mainService, { baseURL } from "../mainService";

export function getOrders(data) {
    return mainService
      .get(Environment.orders, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }


  export function getOrder(data,orderId) {
    return mainService
      .get(`${Environment.getOrder+"/"+orderId}`, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  export function getOrdersStatusStatistics(data) {
    return mainService
      .get(`${Environment.requestsStatusStatistics}`, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }


  export function deleteOrder(data,orderId) {
    return mainService
      .delete(`${Environment.getOrder+"/"+orderId}`, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }


  export function getFilterOrders(data,queryParams) {
    return mainService
      .post(Environment.getFilterOrders, data,{
        params: queryParams,
      })
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }



  export function getOrderStatistics(data,orderId) {
    return mainService
      .get(Environment.getOrderStatistics+"/"+orderId+"/"+"statistics", data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }


  export function getConfirmationRequest(data,confirmationId) {
    return mainService
      .get(Environment.getConfirmationRequest+"/"+confirmationId, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }


  export function updateConfirmationRequest(data,confirmationId) {
    return mainService
      .put(Environment.getConfirmationRequest+"/"+confirmationId, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }


    export function paidConfirmationRequest(data,confirmationId) {
    return mainService
      .post(Environment.getConfirmationRequest+"/"+confirmationId+"/pay", data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  export function rateMediator(data) {
    return mainService
      .post(Environment.rateMediator, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

    export function getStatusConfirmationOrder(data,orderId) {
    return mainService
      .get(Environment.statusOrder+"/"+orderId, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

    export function closeOrderCar(data,orderId) {
    return mainService
      .patch(Environment.closeOrder+"/"+orderId+"/"+"CLOSED" ,data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }



  export function getOrdersStatusStatisticsMediators(data) {
    return mainService
      .get(`${Environment.statisticsMediators}`, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }


    export function getFavouriteOrders(data) {
      const queryString = new URLSearchParams(data).toString();
      const urlWithParams = `${Environment.mediatorFavouritesRequests}?${queryString}`;
    return mainService
      .get(urlWithParams)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }

  export function addToFavourite(data,requestId) {
    return mainService
      .post(Environment.addOrderToFavourite+"/"+requestId, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }


  export function getBuyOrders(data) {
    return mainService
      .get(Environment.buyRequests, data)
      .then((res) => res.data)
      .catch((error) => Promise.reject(error));
  }