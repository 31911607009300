import { useTranslation } from "react-i18next";
import { goTo, lamborgini } from "../../assets/icons"
import GeneralSwiper from "./GeneralSwiper";
import UserCardSwiper from "./UserCardSwiper"
const testimonials = [
    {id:1, name: "لامبورجينى", img: null, text: '', stars: 5 ,car:true},
    {id:2, name: "لامبورجينى", img: null, text: '', stars: 5 ,car:true},
    {id:3, name: "لامبورجينى", img: null, text: 'كانت التجربة استثنائية بكل المقاييس...', stars: 5, highlighted: true ,car:true},
    {id:4, name: "لامبورجينى", img: null, text: '', stars: 5 ,car:true},
    {id:5, name: "لامبورجينى", img: null, text: '', stars: 5 ,car:true},
  ];
const CarsMostBuyer=()=>{
    const {t} = useTranslation()
    return(
        <div className="bg-[#00CEBC12] py-10 mt-20">
            <div className="flex flex-row items-center justify-between px-20">
                <div className="font-700 max600:text-[25px] text-[45px] text-[#444444] underline">{t("home.carMostBuyer.title")}</div>
                <div><img src={goTo}/></div>

            </div>
            <div className="mt-5">
           <GeneralSwiper users={testimonials} car={true}/>
           </div>
        </div>
    )
}

export default CarsMostBuyer