
const Input = ({ icon, label, type, placeholder,onChange,value ,name,disabled = false,multiple = false,max,min}) => {
  return (
    <div className="w-[100%]">
      <div className="flex flex-row items-center text-[#3E0292] text-[12px]">
        <img src={icon} />
        <div className="mx-3 font-400">{label}</div>
      </div>
      <input
        multiple={multiple}
        disabled={disabled}
        className="rounded-lg border-[#D9D9D9] text-[#9E9E9E] text-[13px] w-[100%] mt-3 p-3 font-400"
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        max={max}
        min={min}
      />
    </div>
  );
};

export default Input;
