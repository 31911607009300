import React, { createContext, useState } from 'react';

const FilterContext = createContext();

function FilterProvider(props) {

    const[data,setData] = useState({
        brandIds:[],
        cityIds:[],
        modelIds:[],
        status:[]
    })
 

  return (
    <FilterContext.Provider
    value={{
     data,
     setData

    }}
    >
      {props.children}
    </FilterContext.Provider>
  );
}

export { FilterContext , FilterProvider};
