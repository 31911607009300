import {Button} from './../index' 
import { cars } from '../../assets/icons'
import { useTranslation } from 'react-i18next'
const ChooseCar=()=>{
    const {t} = useTranslation()
    return(
        <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mt-20 max600:px-0 px-20">
            <div className="rounded-lg bg-[#9EE8E2] p-32 flex justify-center items-center max600:mt-10 max600:order-2">
                <img src={cars} className="absolute"/>
            </div>
            <div className='flex justify-center flex-col items-end max600:order-1'>
                <div className="max600:text-[25px] text-[43px] text-[#3E0292] font-700 text-right">{t("home.chooseCar.title")}</div>
                <div className='mt-5'><Button dark={true} title={t("home.chooseCar.action")}/></div>
            </div>

        </div>
    )
}

export default ChooseCar