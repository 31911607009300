import axios from 'axios';
import { logoutUser } from '../helpers/helper';

export const baseURL = 'https://staging-backend.mshrai.com/api/';

const getToken = () => {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem('token') || '';
  } else {
    return '';
  }
};

const token = localStorage.getItem('token');

const mainService = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng'),
    Authorization: `Bearer ${localStorage.getItem('authToken')}`,
  },
});

mainService.interceptors.request.use(
  function (config) {
    const token = getToken().replace(/^"|"$/g, '');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

mainService.interceptors.response.use(
  function (response) {
    return response; 
  },
  function (error) {
    if (error.response && (error.response?.status === 401 || error.response?.status === 403)) {  
      logoutUser();
      window.location.href = '/';
    }

    return Promise.reject(error); 
  }
);

export default mainService;
