import { DownloadApp } from "../../../components"

const Download=()=>{
   return(
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
       <div className="max600:w-[100%] w-[90%] mr-auto ml-auto max600:p-0 p-12">
        <DownloadApp/>
       </div>
       </div>
   )
}

export default Download