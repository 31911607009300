import React, { createContext, useState } from 'react';

const FilterOrderContext = createContext();

function FilterOrderProvider(props) {

  const [brands,setBrands] = useState([])
  const [statuses,setStatuses] = useState([])
  const [models,setModels] = useState([])
  const [cities,setCities] = useState([])
  const [price,setPrice] = useState([])

  return (
    <FilterOrderContext.Provider
    value={{
     brands,
     setBrands,
     statuses,
     setStatuses,
     cities,
     setCities,
     models,
     setModels,
     price,
     setPrice

    }}
    >
      {props.children}
    </FilterOrderContext.Provider>
  );
}

export { FilterOrderContext , FilterOrderProvider};
