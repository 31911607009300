import { user } from '../../assets/imgs';
import { star } from '../../assets/icons';
import { defaultLettersCircle } from '../../helpers/helper';
const CarOwnerCardUser = ({
  selected = false,
  receivedMessage = false,
  onClick,
  user,
}) => {
  return (
    <div
      className={`${
        receivedMessage
          ? 'bg-[#B8CBFF]'
          : selected
          ? 'bg-[#00CEBC]'
          : 'bg-[#D6E1FF]'
      } rounded-[12px] m-2 shadow-md cursor-pointer`}
      onClick={onClick}
    >
      <div className="flex flex-row justify-between">
        <div className="p-3 flex flex-row items-center">
          <div>
            {!user?.receiverInfo?.profileImg ? (
              defaultLettersCircle(user.name, 'w-[60px]', 'h-[60px]')
            ) : (
              <img
                src={user.receiverInfo?.profileImg}
                alt={user.receiverInfo?.fullName}
                className="w-[60px] h-[60px] rounded-full"
              />
            )}
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <div
                className={`${
                  receivedMessage
                    ? 'text-[#303030] font-[900px]'
                    : selected
                    ? 'text-white font-500'
                    : 'text-[#303030] font-500'
                } text-[16px]`}
              >
                {user.receiverInfo?.fullName}
              </div>
              <div className="flex flex-row items-center mx-2">
                <img src={star} alt="Rating" />
                <div
                  className={
                    selected
                      ? 'text-white font-500 text-[15px] mx-2'
                      : 'text-[#303030] font-500 text-[15px] mx-2'
                  }
                >
                  {user.receiverInfo?.medAvgRating}
                </div>
              </div>
            </div>
            <div
              className={`${
                receivedMessage
                  ? 'text-[#7B7B7B] font-700'
                  : selected
                  ? 'text-white font-400'
                  : 'text-[#7B7B7B] font-400'
              } text-[16px] mt-3`}
            >
              {user.lastMessage || user.message}{' '}
              {/* Adjust based on your data */}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between p-2">
          <div className="bg-[#FFFFFF4D] rounded-[10px] flex flex-row items-center font-400 text-[15px] p-1">
            <div
              className={`${
                receivedMessage
                  ? 'text-[#00CEBC]'
                  : selected
                  ? 'text-black'
                  : 'text-[#00CEBC]'
              }`}
            >
              #{user.medOfferId}
            </div>
            <div
              className={`${
                receivedMessage
                  ? 'text-[#00CEBC]'
                  : selected
                  ? 'text-black'
                  : 'text-[#00CEBC]'
              }`}
            >
              :
            </div>
            <div className="text-black">id</div>
          </div>
          {receivedMessage && (
            <div className="rounded-full bg-[#00CEBC] text-white font-700 text-[13px] w-[40%] flex items-center justify-center my-3">
              {/* You can show the count of unread messages here */}2
            </div>
          )}
          <div
            className={`${
              receivedMessage
                ? 'text-[#00CEBC]'
                : selected
                ? 'text-white '
                : 'text-[#BBBBBB]'
            } font-400 text-[11px]`}
          >
            {user.cratedAt}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarOwnerCardUser;
