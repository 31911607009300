import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { searchGreen } from "../../assets/icons"


const GInput=({value,onChange,title,back,type,handleCheckboxChange,selectedItems,register,items})=>{
    const {t} = useTranslation()
    const [data,setData] = useState([])

    useEffect(()=>{
      setData(items)
    },[items])

    

    const handleSearch=(e)=>{
      const searchTerm = e.target.value.toLowerCase();
      if (searchTerm === '') {
      setData(items);
      } else {
      const newArr = items.filter((item) => item.label.toLowerCase().includes(searchTerm));
      setData(newArr); 
      }
    }
 
    return(
        <div>
            <div className="text-[#3E0292] text-[15px] font-[500px]">{title}</div>
            <div className="relative w-full mt-5">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <img src={searchGreen}/>
            </div>
            <input type="text" id="simple-search" className="bg-[#00CEBC4D] border border-[#00CEBC4D] rounded-[39px] p-4 text-[#9E9E9E] text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("listOrders.search")} onChange={handleSearch} />
            </div>
            <div className="mt-5 h-[200px] overflow-y-scroll">
            {data.map((item, index) => (
          <label key={index} className="flex items-center mb-2">
            <input
              type="checkbox"
              {...register(`${type}[${item.value}]`)} 
              value={item}
              onChange={() => handleCheckboxChange(item)}
              checked={selectedItems.some(selected => selected.value === item.value)}
              className="form-checkbox h-5 w-5 rounded-[5px] text-[#3E0292] font-400"
            />
            <span className="mx-2 font-400">{item.label}</span>
          </label>
        ))}
            </div>
        </div>
    )
}

export default GInput