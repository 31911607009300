import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import i18n from '../languages/i18n';
import DownloadsAppBanner from '../components/shared/DownloadsAppBanner';
import DownloadApp from '../components/home/DownloadApp';
import { useTranslation } from 'react-i18next';
const MediatorPopup = ({ isOpen, isClose }) => {
  if (!isOpen) return null;
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl relative rounded-2xl ">
        <button
          className="absolute top-4 right-4 bg-[#00CEBC] rounded-lg text-gray-500 hover:text-gray-800"
          onClick={isClose}
        >
          <AiOutlineClose size={24} className="text-white" />
        </button>

        <div className="text-center">
          <h2 className="text-sm font-medium mb-4">
            {t('register.mediatorPopup.title')}
          </h2>

          {/* <DownloadsAppBanner /> */}
          <DownloadApp />
          <p className="text-sm font-medium mb-2  mt-2 text-[#3E0292]">
            {t('register.mediatorPopup.subTitle')}
          </p>

          <div className="flex justify-center">
            <input
              type="text"
              placeholder={t('register.mediatorPopup.phone')}
              className="border border-gray-300 bg-[#E7E7E7] rounded-lg py-2 px-4  focus:outline-none focus:ring-2 focus:ring-customPurple"
            />
            <button className="bg-[#6E36AA] text-white rounded-lg py-2 px-8 ">
              {t('register.mediatorPopup.button')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediatorPopup;
