import {
  Button,
  Dropdown,
  FDropdown,
  MConfirmation,
  Textarea,
  WayConnection,
  YearDropdown,
  GeneralDropdown,
  Input,
} from './../../../components';
import {
  userCar,
  userMail,
  LetterIcon,
  uploadImage,
  uploadImages,
  SuccessAddOffer,
} from './../../../assets/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from './../../../languages/i18n';
import Counter from './../../../components/inputs/Counter';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  getAllCarBrands,
  getColors,
  getCities,
  getModelsOfBrand,
} from './../../../services/apis/offer';
import { useNavigate, useParams } from 'react-router-dom';
import { FaBullseye, FaTimes, FaUpload } from 'react-icons/fa';
import { Field, Formik } from 'formik';
import {
  addOrderCar,
  deleteOrderCar,
  getOrderDetails,
  updateOrderCar,
} from '../../../services/apis/owner';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../helpers/helper';
import { uploadFiles } from '../../../services/apis/general';

const Edit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isArabic = i18n.language === 'ar';
  const [openModal, setOpenModal] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [cities, setCities] = useState([]);
  const [colors, setColors] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({});

  const yearOptions = Array.from({ length: 25 }, (_, index) => 2000 + index);

  const closeModal = () => {
    setOpenModal(false);
  };

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      description: '',
      brandId: 0,
      modelId: 0,
      price: 0,
      modelYear: 0,
      cityId: 0,
      colorId: 0,
      odo: 0,
      filesUrl: [],
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrderDetails('', id);
        const orderData = response.data;
        // Reset the form with the fetched data
        console.log(orderData);
        setOrder(orderData);
        console.log(order);
        reset({
          description: orderData?.cars?.[0]?.description || '',
          brandId: orderData?.cars?.[0]?.brand?.id || 0,
          modelId: orderData?.cars?.[0]?.model?.id || 0,
          price: orderData?.cars?.[0]?.price || 0,
          modelYear: orderData?.cars?.[0]?.modelYear || 0,
          cityId: orderData?.cars?.[0]?.city?.id || 0,
          colorId: orderData?.cars?.[0]?.color?.id || 0,
          odo: orderData?.cars?.[0]?.odo || 0,
          filesUrl: orderData?.cars?.[0]?.filesUrl || [],
        });
        otherAction(orderData?.cars?.[0]?.brand?.id);
      } catch (error) {
        console.error('Error fetching car data:', error);
      }
    };

    fetchData(); // Call the fetch function
  }, [reset]);

  const removeOrderCar = async () => {
    try{
    const response = await deleteOrderCar('', id);
    console.log(response);
    navigate('/car-owner');
    toast.success(t('orderDetails.deleteMessage'), toastConfig);
  }catch(error){
    toast.error(
      error.response.data,
      toastConfig
    );
  }
  };

  const handleEditOfferSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    try {
      // Validate that carBrands and carType have corresponding entries
      if (!data.brandId) {
        toast.error(t('myOrders.atleastBrand'), toastConfig, setLoading(false));
        return;
      }

      const payload = {
        car: {
          description: data.description || '',
          brandId: Number(data.brandId) || 0,
          modelId: Number(data.modelId) || 0,
          price: Number(data.price) || 0,
          modelYear: Number(data.modelYear) || 0,
          cityId: Number(data.cityId) || 0,
          colorId: Number(data.colorId) || 0,
          odo: Number(data.odo) || 0,
          filesUrl: files,
        },
      };

      const response = await updateOrderCar(payload, id);
      console.log(response, id);

      // Check the response structure and status
      // if (response && response.data && response.data.id) {
        setOpenModal(true);
        toast.success(
          t('myOrders.editOrderSuccess'),
          toastConfig,
          setLoading(false)
        );
        setTimeout(() => {
          navigate('/');
        }, 1500);
      // } else {
      //   throw new Error('Unexpected response format');
      // }
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        } else if (error.response.status == 500) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        }
      }
      console.error('Error submitting offer:', error);
    }
  };

  useEffect(() => {
    // Fetch all brands, colors, and cities on component load
    const fetchInitialData = async () => {
      const brandData = await getAllCarBrands();
      const colorData = await getColors();
      const cityData = await getCities();

      if (brandData) {
        const formattedBrands = brandData.map((brand) => ({
          value: brand.id,
          label: isArabic ? brand.nameAr : brand.nameEn,
          icon: brand.image,
        }));
        setBrands(formattedBrands);
        console.log(order);
      }

      if (colorData) {
        const formattedColors = colorData?.data?.items?.map((color) => ({
          value: color.id,
          label: isArabic ? color.nameAr : color.nameEn,
        }));
        setColors(formattedColors);
      }

      if (cityData) {
        const formattedCities = cityData.map((city) => ({
          value: city.id,
          label: isArabic ? city.nameAr : city.nameEn,
        }));
        setCities(formattedCities);
      }
    };

    fetchInitialData();
  }, []);

  const otherAction = async (brandId) => {
    console.log(brandId);
    const response = await getModelsOfBrand(brandId);
    console.log(response);
    const allModels = response.flat().map((model) => ({
      value: model.id,
      label: isArabic ? model.nameAr : model.nameEn,
    }));
    setModels(allModels);
  };

  const handleChangeFiles = (e) => {
    let files = Array.from(e.target.files);
    uploadFile(files);
  };

  const uploadFile = async (data) => {
    setLoadingFiles(true);
    const formData = new FormData();
    data.forEach((image, index) => {
      formData.append(`files`, data[index]);
    });
    try {
      const response = await uploadFiles(formData);
      console.log('Success:', response);
      setFiles(response);
      toast.success(
        t('myOrders.fileUploaded'),
        toastConfig,
        setLoadingFiles(false)
      );
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoadingFiles(false)
          );
        } else if (error.response.status == 500) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoadingFiles(false)
          );
        }
      }
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-10">
      <div className="w-[90%] mr-auto ml-auto">
        <div className="text-[17px] text-[#000000] font-500 text-center mb-10">
          {t('myOrders.editOrderCar')}
        </div>
        <form onSubmit={handleSubmit(handleEditOfferSubmit)}>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mb-4 gap-4">
            <GeneralDropdown
              otherAction={otherAction}
              useAction={true}
              maxWidth={false}
              control={control}
              name="brandId"
              icon={userMail}
              label={t('myOrders.brandCar')}
              placeholder={t('myOrders.selectBrandCar')}
              options={brands}
            />
            <GeneralDropdown
              maxWidth={false}
              control={control}
              name="modelId"
              icon={LetterIcon}
              label={t('myOrders.carType')}
              placeholder={t('myOrders.selectCarType')}
              options={models}
            />
            <GeneralDropdown
              maxWidth={false}
              control={control}
              name="cityId"
              icon={userMail}
              label={t('myOrders.city')}
              placeholder={t('myOrders.selectCity')}
              options={cities}
            />
            <GeneralDropdown
              maxWidth={false}
              control={control}
              name="colorId"
              icon={LetterIcon}
              label={t('myOrders.color')}
              placeholder={t('myOrders.selectColor')}
              options={colors}
            />
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-4">
            <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[100%]">
              <YearDropdown
                maxWidth={false}
                control={control}
                name="modelYear"
                icon={userMail}
                label={t('listOrdersCarOwner.year')}
                placeholder={t('myOrders.from')}
                options={yearOptions.map((year) => ({
                  value: year,
                  label: year,
                }))}
              />
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 gap-x-4">
              <Controller
                name="odo"
                defaultValue={order?.cars?.[0]?.odo || 0}
                control={control}
                render={({ field }) => (
                  <Counter
                    {...field}
                    initialValue={order?.cars?.[0]?.odo || 0}
                    icon={LetterIcon}
                    label={t('myOrders.kilometers')}
                    placeholder={t('myOrders.from')}
                    step={10000}
                  />
                )}
              />
            </div>
          </div>
          {console.log(order)}
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-5">
            <div className="flex flex-col">
              <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 gap-x-4">
                <Controller
                  name="price"
                  control={control}
                  defaultValue={order?.cars?.[0]?.price || 0}
                  render={({ field }) => (
                    <Counter
                      {...field}
                      icon={userMail}
                      initialValue={order?.cars?.[0]?.price || 0}
                      label={t('myOrders.price')}
                      placeholder={t('myOrders.from')}
                      className="mt-2 w-[100%]"
                      step={10000}
                    />
                  )}
                />
              </div>

              <div class="w-full mx-auto mt-5">
                <label class="block border bg-[#ffffff] border-gray-300 rounded-lg p-2 flex items-center cursor-pointer w-[100%] p-3">
                  <FaUpload className="text-[#3E0292]" />
                  <input
                    type="file"
                    className="hidden"
                    multiple={true}
                    label={t('إرفاق ملفات')}
                    placeholder={t('اختر ملفات')}
                    onChange={handleChangeFiles}
                  />
                </label>
              </div>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-x-4 mt-3">
                {order?.cars?.[0]?.filesUrl.map((item, index) => (
                  <div className="border border-[#999999] rounded-[5px] flex items-center justify-center relative">
                    <img src={item} className="w-[100%]" />
                    <div className="bg-[#ffffff] flex items-center justify-center rounded-full w-[15px] h-[15px] absolute top-[-10px] right-[-10px]">
                      <FaTimes className="text-[#ff0000] text-[12px]" />{' '}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col max600:mt-5 sm:mt-5">
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Textarea
                    rows={5}
                    label={t('myOrders.description')}
                    placeholder={t('myOrders.description')}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          {/* <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-5">
            <div className="flex flex-col max600:mt-5 sm:mt-5">
                  <Controller
                  name="filesUrl"
                  control={control}
                  render={({ field }) => (
                   <Input
                    type={"file"}
                    name="filesUrl"
                    multiple={true}
                    label={t('myOrders.description')}
                    placeholder={t('myOrders.description')}
                    {...field}
                  />
                )}
                />
              </div>
            </div> */}

          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5">
            <Button
              type="submit"
              loading={loading}
              title={t('myOrders.addOrderNow')}
              dark={false}
              className="bg-[#00CEBC] border border-[#00CEBC]"
            />
            <Button
              onClick={() => {
                removeOrderCar();
                reset();
              }}
              title={t('myOrders.closeOrder')}
              dark={false}
              className="bg-[#FF4342] border border-[#FF4342] max600:mt-3 sm:mt-3 xl:mt-0 lg:mt-0 2xl:mt-0 md:mt-0"
            />
          </div>
        </form>

        <MConfirmation
          openModal={openModal}
          title={t('myOrders.editOrderSuccess')}
          showFooter={false}
          content={
            <div className="flex items-center justify-center">
              <img
                src={SuccessAddOffer}
                className="w-54 h-54 mb-2"
                alt="Registration Success"
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Edit;
