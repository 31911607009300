import React from 'react';
import InfoSection from '../InfoSection';
import VerifyOTPForm from './VerifyOTPForm';
const VerifyOTP = () => {
  return (
    <div className="grid min-h-screen grid-cols-1 md:grid-cols-2">
      <div className="p-8 flex items-center justify-center ">
        <InfoSection />
      </div>
      <div className="bg-gradient-to-r from-[#FFFFFF] to-[#D7E2FF] flex items-center justify-center">
        <div className="w-full max-w-md p-2 sm:p-2 md:p-4 lg:p-6">
          <VerifyOTPForm />
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
