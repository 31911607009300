const CardStatistics=({icon,number,subTitle,withSubTitle = false,title,withNumberAndString=false})=>{
  return(
      <div className="bg-[#BEDBFA] rounded-[10px] flex flex-col justify-center items-center p-3">
          <img src={icon}/>
          {withNumberAndString ? <div className="flex flex-row items-center text-[15px] font-400 text-[#303030] my-3"><div>{number}</div><div className="mx-2">{title}</div></div> : <div className="text-[15px] font-400 text-[#303030] my-3">{number}</div>}
         {withSubTitle ? <div className="text-[11px] font-400 text-[#303030]">{subTitle}</div> : null}
      </div>
  )
}

export default CardStatistics