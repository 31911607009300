import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { OtpConfirmation } from '../../assets/icons';

import { AiOutlineClose } from 'react-icons/ai';

const OTPConfirmation = ({ isOpen, isClose }) => {
  const { t, i18n } = useTranslation();
  if (!isOpen) return null;
  document.body.dir = i18n.dir();

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center h-full bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative rounded-2xl">
        <button
          className="absolute top-4 right-4 bg-[#00CEBC] rounded-lg text-gray-500 hover:text-gray-800"
          onClick={isClose}
        >
          <AiOutlineClose size={24} className="text-white" />
        </button>

        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="text-lg font-medium mb-4">
            {' '}
            {t('forgetPass.otpConfirmation.title')}{' '}
          </h2>
          <img
            src={OtpConfirmation}
            className="w-54 h-54 mb-2"
            alt="Registration Success"
          />
          <h2 className="text-sm font-small mb-4">
            {t('forgetPass.otpConfirmation.subTitle')}{' '}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default OTPConfirmation;
