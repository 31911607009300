import Skeleton from 'react-loading-skeleton';

const CardSkeleton = ({ number, height }) => {
  return Array(number)
    .fill(0)
    .map((_, index) => (
      <div key={index} style={{ marginBottom: '10px' }}>
        <Skeleton
          key={index}
          height={height}
          width={'100%'}
          baseColor={'#D7E2FF'}
          style={{ borderRadius: 20 }}
        />
      </div>
    ));
};

export default CardSkeleton;
