import React, { useState, useRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const YearDropdown = ({
  control,
  name,
  icon,
  label,
  placeholder,
  options,
  maxWidth = true,
}) => {
  const { t } = useTranslation();
  const { field } = useController({ control, name });
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const handleToggle = () => setIsOpen(!isOpen);
  const dropdownRef = useRef(null);

  const handleYearSelect = (year) => {
    field.onChange(year);
    setIsOpen(false);
  };

  const filteredYears = options.filter((option) =>
    option.label.toString().includes(searchTerm)
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      className={
        maxWidth
          ? 'relative w-full max-w-sm mx-auto'
          : 'relative w-full mx-auto'
      }
      ref={dropdownRef}
    >
      <div className="flex items-center gap-2 mb-1">
        {' '}
        {icon && <img src={icon} alt="" />}
        {label && (
          <label className="block text-sm font-400 text-[#3E0292]">
            {label}
          </label>
        )}
      </div>
      <div className="mt-1 relative">
        <div
          className=" w-full bg-white border border-gray-300 rounded-md shadow-sm flex items-center justify-between px-3 py-2 cursor-pointer"
          onClick={handleToggle}
        >
          <span>{field.value || placeholder}</span>
          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>

        {isOpen && (
          <div className="absolute mt-1 p-4 w-full bg-[#BAE8EF] border border-gray-300 rounded-3xl shadow-lg z-10 overflow-hidden">
            <div className="p-2 sticky top-0 bg-[#BAE8EF] z-10">
              <div className="relative">
                <input
                  type="text"
                  placeholder={t('addOffer.search')}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="block w-full bg-white border border-gray-300 rounded-full p-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <Search
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#BAE8EF]"
                  size={20}
                />
              </div>
            </div>

            <div className="grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 p-2 max-h-60 overflow-y-auto">
              {filteredYears.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleYearSelect(option.value)}
                  className={`p-2 rounded-md text-center text-xs ${
                    field.value === option.value
                      ? 'bg-[#34D399] text-white'
                      : 'bg-white hover:bg-gray-100'
                  }`}
                >
                  {option.label}
                </button>
              ))}
            </div>

            <div className="flex justify-center gap-4 p-2 bg-[#BAE8EF] sticky bottom-0 mt-2">
              <button
                onClick={handleToggle}
                className="bg-[#00CEBC] text-white px-10 py-2 rounded-md text-base font-500	"
              >
                {t('addOffer.add')}
              </button>
              <button
                onClick={handleToggle}
                className="bg-[#FF4342] text-white px-10 py-2 rounded-md text-base font-500	"
              >
                {t('addOffer.cancel')}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default YearDropdown;
