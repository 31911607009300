import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyAU2xT0u9XtmbDT6tsWhfGllaAKDF5nuMs',
  authDomain: 'mshrai-b2c.firebaseapp.com',
  databaseURL: 'https://mshrai-b2c-default-rtdb.firebaseio.com',
  projectId: 'mshrai-b2c',
  storageBucket: 'mshrai-b2c.appspot.com',
  messagingSenderId: '462889710365',
  appId: '1:462889710365:web:9896cb627ea37d895367f4',
  measurementId: 'G-7385R8TPDX',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export { database, app };
