import { useTranslation } from 'react-i18next';
import {
  BMW,
  Calendar,
  car,
  grayCar,
  kilometers,
  location,
  moneyGreen,
  statisticsOrder,
  userMail,
} from '../../assets/icons';
import { Lang } from '../../helpers/contents';
import { convertDate, languageAr, userRole } from '../../helpers/helper';
import { ButtonIcon, ButtonBadge } from './../index';
import CarColor from './CarColor';
const CardOrder = ({ onClick, order, goStatistics, goOffers }) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-[20px] bg-[#D7E2FF] cursor-pointer flex flex-col justify-between shadow-lg h-[100%]">
      <div onClick={onClick}>
        <div className="flex flex-row justify-between">
          <div
            className={`bg-[#3E0292] flex flex-row items-center ${
              languageAr
                ? 'rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[0px]'
                : 'rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[0px] rounded-br-[20px]'
            } text-[14px] text-[#ffffff] p-4 h-[50px]`}
          >
            <div>{t('listOrders.numberOrder')}</div>
            <div className="px-2">:</div>
            <div>#{order?.id}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex flex-row items-center p-2 bg-[#E6EDFF] rounded-[7px] mx-5 mt-[5px]">
              <div>
                <img src={Calendar} className="w-[20px] h-[20px]" />
              </div>
              <div className="text-[13px] font-[600px] text-[#000000] px-2">
                {convertDate(order?.createdAt)}
              </div>
              <div className="text-[10px] font-[600px] text-[#6A6A6A]">
                {order?.createdAt.split('T')[1]}
              </div>
            </div>
            <div className="flex flex-row items-center pt-2">
              <div className="text-[#3E0292] text-[13px] font-500">
                {t('home.orders.status')}
              </div>
              <div className="text-[#3E0292] text-[13px] font-500 mx-2">
                :
              </div>
              <div className="text-[#3E0292] text-[13px] font-500">
                {order.status == 'OPEN'
                  ? t('home.orders.open')
                  : order.status == 'CLOSED'
                  ? t('home.orders.close')
                  : order.status == 'COMPLETED'
                  ? t('home.orders.complete')
                  : t('home.orders.open')}
              </div>
              <div
                className={`${
                  order.status == 'OPEN'
                    ? 'bg-[#04C500CC]'
                    : order.status == 'CLOSED'
                    ? 'bg-[#ff0000CC]'
                    : 'bg-[#3E0292CC]'
                }  w-[10px] h-[10px] rounded-full mx-2`}
              ></div>
            </div>
          </div>
        </div>
        {order?.cars?.length == 0 ? null : (
          <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
            <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 w-[90%]">
              {order.cars.slice(0, 1).map((car, index) => (
                <div
                  key={index}
                  className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                >
                  <div>
                    <img src={car.image} className="w-[20px] h-[2s0px]" />
                  </div>
                  <div className="text-[#0000000] text-[12px] font-400 px-2">
                    {languageAr ? car?.brand : car?.brandEn}
                  </div>
                </div>
              ))}
            </div>
            {order?.cars.length > 1 ? (
              <div className="bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500">
                +{order.cars.length - 1}
              </div>
            ) : null}
          </div>
        )}
        {order?.cities?.length == 0 ? null : (
          <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
            <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 w-[90%]">
              {order.cities.slice(0, 1).map((city, index) => (
                <div
                  key={index}
                  className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                >
                  <div>
                    <img src={location} />
                  </div>
                  <div className="text-[#0000000] text-[12px] font-400 px-2">
                    {languageAr ? city?.city : city?.cityEn}
                  </div>
                </div>
              ))}
            </div>
            {order?.cities.length > 1 ? (
              <div className="bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500">
                +{order.cities.length - 1}
              </div>
            ) : null}
          </div>
        )}
        {order?.colors.length == 0 ? null : (
          <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
            <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 w-[90%]">
              {order?.colors.slice(0, 1).map((color, index) => (
                <div
                  key={index}
                  className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                >
                  <div>
                    <CarColor
                      color={
                        color?.hexCode == '#FFFFFF' ? '#808080' : color?.hexCode
                      }
                    />
                  </div>
                  <div
                    style={{
                      color:
                        color?.hexCode == '#FFFFFF'
                          ? '#808080'
                          : color?.hexCode,
                    }}
                    className={`text-[12px] font-400 px-2`}
                  >
                    {languageAr ? color?.nameAr : color?.nameEn}
                  </div>
                </div>
              ))}
            </div>
            {order.colors.length > 1 ? (
              <div className="bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500">
                +{order.colors.length - 1}
              </div>
            ) : null}
          </div>
        )}
        {/* <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-3 mt-3 w-[90%] mr-auto ml-auto">
                  <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%]">
                      <div className="bg-[#ffffff] rounded-[12px] p-3 flex flex-row items-center">
                          <div><img src={grayCar}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
                      </div>
                  </div>
                  <div className="bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500">+0</div>
            </div> */}
        {order?.odoFrom == null && order?.odoTo == null ? null : (
          <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
            <div>
              <img src={kilometers} />
            </div>
            <div className="flex flex-row items-center px-2">
              <div className="text-[#BBBBBB] text-[10px] font-400">
                {t('listOrders.from')}
              </div>
              <div className="text-[#000000] text-[13px] font-400 px-2">
                {order?.odoFrom}
              </div>
              <div className="text-[#BBBBBB] text-[10px] font-400">
                {t('listOrders.km')}
              </div>
              <div className="text-[#000000] text-[10px] font-400 px-2">
                /
              </div>
              <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                {t('listOrders.to')}
              </div>
              <div className="text-[#000000] text-[13px] font-400">
                {order?.odoTo}
              </div>
              <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                {t('listOrders.km')}
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mt-3 mb-3 w-[90%] mr-auto ml-auto">
          <div>
            <img src={moneyGreen} />
          </div>
          <div className="flex flex-row items-center px-2">
            <div className="text-[12px] font-500 text-[#00CEBC]">
              {t('listOrders.price')}
            </div>
            <div className="text-[12px] font-500 text-[#00CEBC] px-2">
              :
            </div>
            <div className="text-[12px] font-500 text-[#00CEBC]">
              {order?.priceFrom}
            </div>
            <div className="text-[12px] font-500 text-[#00CEBC] px-2">
              /
            </div>
            <div className="text-[12px] font-500 text-[#00CEBC] px-2">
              {order?.priceTo}
            </div>
            <div className="text-[12px] font-500 text-[#00CEBC]">
              {t('listOrders.Rial')}
            </div>
          </div>
          {/* <div className="text-[12px] font-500 text-[#00CEBC] px-2">السعر : 500.125 / 1,000.000 ريال سعودى</div> */}
        </div>
      </div>
     {userRole == 2 && (
      <div className={`grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[90%] mr-auto ml-auto gap-x-2 ${userRole == 2 ? "mt-5 mb-[-30px]" : ""}`}>
        <ButtonBadge
          value={order?.countOfOffers}
          disabled={order?.countOfOffers > 0 ? false : true}
          className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg"
          onClick={goOffers}
          dark={false}
          title={t('listOrders.offers')}
        />
        <ButtonIcon
          dark={true}
          className="shadow-lg"
          title={t('listOrders.statistics')}
          onClick={goStatistics}
          icon={statisticsOrder}
        />
      </div>
      )}
    </div>
  );
};

export default CardOrder;
