import { useTranslation } from "react-i18next"
import { moneyWhite } from "../../assets/icons"
import Car from "./parts/Car"
import Mediator from "./parts/Mediator"
import Input from "../inputs/Input"
import Textarea from "../inputs/Textarea"
import CheckBox from "../inputs/CheckBox"
import { useState } from "react"
const ConfirmRequest=({offer,onValueChange,onValueChangeComment,hideActionModal})=>{
    const {t} = useTranslation()
    console.log(offer)
    const [selectedValue, setSelectedValue] = useState('');
    const handleChange = (e) => {
        console.log(e.target.value == 1)
        setSelectedValue(e.target.value);
        onValueChange(e.target.value);
      };
      const [comment, setComment] = useState('');
      const handleChangeComment = (e) => {
          console.log(e.target.value)
          setComment(e.target.value);
          onValueChangeComment(e.target.value);
        };
    return(
        <div>
            <div className="text-[#303030] font-700 text-[15px]">{t("completeRequest.chooseCar")}</div>
            <Mediator offer={offer} cars={
                <div>
                    {offer?.cars.map((car,index)=>(
                      <Car 
                      key={index} 
                      car={car}
                      />
                    ))}
                </div>
            }/>
            <div className="bg-[#E0FFFC] my-1 p-3 rounded-[20px]">
            <div className="flex flex-row items-center mt-3">
                <div className="text-[#3E0292] text-[14px] font-bold">{t("completeRequest.commissionMessage")}:</div>
                <div className="flex flex-row bg-[#04C500CC] rounded-[12px] p-3 mx-2">
                  <div><img src={moneyWhite}/></div>
                  <div className="flex flex-row items-center px-2">
        <div className="text-[12px] font-500 text-[#ffffff] px-2">{offer?.totalCommission}</div>
        <div className="text-[12px] font-500 text-[#ffffff]">{t("listOrders.Rial")}</div>

    </div>
               </div>
                
            </div>
            </div>
            {hideActionModal ? null : (
            <div>
            <div className="bg-[#E0FFFC] p-3 rounded-[20px]">
                <Input disabled={true} label={t("completeRequest.totalCommission")} type={"number"} placeholder={t("completeRequest.placeholderTotalCommission")} value={offer?.totalCommission}/>
            </div>
            <div className="bg-[#E0FFFC] p-3 rounded-[20px] my-1 grid grid-cols-2">
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={1} checked={selectedValue == 1} type="radio"/>
            <label className="mx-3 text-[#3E0292] text-[13px] font-bold">{t("completeRequest.accept")}</label>
            </div>
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={2} checked={selectedValue == 2} type="radio"/>
            <label className="mx-3 text-[#3E0292] text-[13px] font-bold">{t(t("completeRequest.reject"))}</label>
            </div>
            </div>
            <div className="bg-[#E0FFFC] p-3 rounded-[20px]">
            <Textarea rows={4} label={t("completeRequest.writeComment")} placeholder={t("completeRequest.youComment")} onChange={handleChangeComment} value={comment}/>
            </div>
            </div>
        )}

        </div>
    )
}

export default ConfirmRequest