import GInput from "../../inputs/GInput"
import { arrowLeftOrder } from "../../../assets/icons"
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import Button from "../../buttons/Button";
import { FilterOrderContext } from "../../../context/FilterOrderContext";
import { getBrands } from "../../../services/apis/home";
import { languageAr } from "../../../helpers/helper";
import { useTranslation } from "react-i18next";
const items = [
    'تويوتا', 'هيونداي', 'نيسان', 'فورد', 'شيفروليه', 'كيا'
  ];
const Brand=({back})=>{
    const filterOrderContext = useContext(FilterOrderContext)
    const {t} = useTranslation()
    const { register, handleSubmit, setValue ,getValues} = useForm({
        defaultValues: { brandIds: [] } 
      });
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [brands,setBrands] = useState([])

    useEffect(()=>{
    getAllBrands()    
    },[])


    useEffect(()=>{
      setSelectedBrands(filterOrderContext.brands)
    },[filterOrderContext.brands])
  
  
    const getAllBrands=async()=>{
      const response = await getBrands("")
      let newBrands = []
      response.forEach(element => {
          newBrands.push({value:element.id,label:languageAr ? element.nameAr : element.nameEn})
      });
      setBrands(newBrands)
      
  
    }
    const handleBrandChange = (brand) => {

      const currentBrands = getValues('brands') || [];

      const updatedBrands = currentBrands.some(selected => selected.value === brand.value)
        ? currentBrands.filter(i => i.value !== brand.value)
        : [...currentBrands, brand];
      setSelectedBrands(updatedBrands); 
      filterOrderContext.setBrands(updatedBrands)
    
      setValue('brands', updatedBrands); 
    };

      const onSubmit = (data) => {
          filterOrderContext.setBrands(data.brands,back())
      };
    return(
        <div>
            <img src={arrowLeftOrder} className="my-2 cursor-pointer" onClick={back}/>
            <form onSubmit={handleSubmit(onSubmit)} className="p-4">
            <GInput title={t("filterModal.selectBrand")} items={brands} type={"brandIds"} selectedItems={selectedBrands} handleCheckboxChange={handleBrandChange} register={register}/>
            <div className="grid grid-cols-2 gap-x-2 mt-3">
                <Button type="submit" dark={false} className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]" title={t("filterModal.confirm")}/>
                <Button type="submit" dark={false} className="bg-[#FF4342] border-[#FF4342] rounded-[10px]" title={t("filterModal.cancel")}/>
            </div>
            </form>
        </div>
    )
}

export default Brand