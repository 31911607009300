import { CompleteOfferProvider } from "../../../context/CompleteOfferContext"
import OfferDetails from "./OfferDetails"
const Offer=()=>{
    return(
        <CompleteOfferProvider>
            <OfferDetails/>
        </CompleteOfferProvider>
    )
}

export default Offer