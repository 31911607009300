import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  paperSend,
  uploadFile,
  uploadImage,
  uploadImages,
} from '../../assets/icons';

const CInput = ({ onSend }) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  const handleSend = async () => {
    if (message.trim() === '' && !selectedFile && !selectedImage) {
      console.log('No content to send');
      return;
    }

    try {
      setIsSending(true);
      console.log('Sending message:', {
        message: message.trim(),
        file: selectedFile,
        image: selectedImage,
      });

      await onSend({
        message: message.trim(),
        file: selectedFile,
        image: selectedImage,
      });

      console.log('Message sent successfully');
      setMessage('');
      setSelectedFile(null);
      setSelectedImage(null);
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
    setSelectedImage(null);
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
    setSelectedFile(null); 
  };

  const handleRemoveAttachment = () => {
    setSelectedFile(null);
    setSelectedImage(null);
  };

  return (
    <div className="flex flex-row items-center justify-between w-[90%] m-auto">
      <div className="rounded-[39px] bg-[#ffffff] flex flex-row items-center justify-between w-[90%] p-[10px]">
        <input
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          placeholder="Write your message..."
          className="rounded-[39px] bg-[#ffffff] w-[70%] px-4 py-2"
        />
        <div className="flex flex-row items-center">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            className="hidden"
            accept=".pdf,.doc,.docx,.txt"
          />
          <input
            type="file"
            ref={imageInputRef}
            onChange={handleImageSelect}
            className="hidden"
            accept="image/*"
          />
          <img
            src={uploadFile}
            alt="Upload File"
            className="cursor-pointer"
            onClick={() => fileInputRef.current?.click()}
          />
          <img
            src={uploadImage}
            alt="Upload Image"
            className="mx-3 cursor-pointer"
            onClick={() => imageInputRef.current?.click()}
          />
          {(selectedFile || selectedImage) && (
            <div className="flex items-center ml-2">
              {selectedImage ? (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                  className="w-10 h-10 object-cover rounded"
                />
              ) : (
                <span className="text-sm text-gray-600">
                  {selectedFile?.name}
                </span>
              )}
              <button
                onClick={handleRemoveAttachment}
                className="ml-2 text-red-500 text-lg"
              >
                &times;
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`bg-white rounded-full flex justify-center items-center w-[45px] h-[45px] ${
          isSending ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={!isSending ? handleSend : undefined}
      >
        <img src={paperSend} alt={isSending ? 'Sending...' : 'Send'} />
      </div>
    </div>
  );
};

export default CInput;
