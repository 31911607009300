import { useState } from "react";
import { useTranslation } from "react-i18next";
import { moneyWhite } from "../../assets/icons";
import CheckBox from "../inputs/CheckBox";

const PaidRequest=({offer,onValueChange})=>{
    const {t} = useTranslation()
    const [selectedValue, setSelectedValue] = useState('');
    const handleChange = (e) => {
        console.log(e.target.value == 1)
        setSelectedValue(e.target.value);
        onValueChange(e.target.value);
      };
    return(
        <div>
                <div className="bg-[#E0FFFC] my-1 p-3 rounded-[20px]">
            <div className="flex flex-row items-center mt-3">
                <div className="text-[#3E0292] text-[14px] font-bold">{t("completeRequest.commissionMessage")}:</div>
                <div className="flex flex-row bg-[#04C500CC] rounded-[12px] p-3 mx-2">
                  <div><img src={moneyWhite}/></div>
                  <div className="flex flex-row items-center px-2">
        <div className="text-[12px] font-500 text-[#ffffff] px-2">{offer?.totalCommission}</div>
        <div className="text-[12px] font-500 text-[#ffffff]">{t("listOrders.Rial")}</div>

    </div>
               </div>
                
            </div>
            </div>
           <div className="bg-[#E0FFFC] p-3 rounded-[20px] my-1 grid grid-cols-2">
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={1} checked={selectedValue == 1} type="radio"/>
            <label className="mx-3 text-[#3E0292] text-[13px] font-bold">{t("paidRequest.cash")}</label>
            </div>
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} disabled={true} value={2} checked={selectedValue == 2} type="radio"/>
            <label className="mx-3 text-[#3E0292] text-[13px] font-bold">{t("paidRequest.visa")}</label>
            </div>
            </div>
        </div>
    )
}

export default PaidRequest