import { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { slash } from "../../assets/icons"
import { toastConfig } from "../../helpers/helper"
import { unBlockUser } from "../../services/apis/user"
import ButtonIcon from "../buttons/ButtonIcon"
import InputSearch from "../inputs/InputSearch"

const Mediators=({mediators,getAllBlockMediators})=>{
    const [newMediators,setNewMediators] = useState(mediators)
    const {t} = useTranslation()
    const actionUnblockUser=async(userId)=>{
        const response = await unBlockUser("",userId)
        toast.success(t("profileInfo.unBlockMessage"), toastConfig,getAllBlockMediators());
    }

    const handleSearch=(e)=>{
        const searchText = e.target.value.toLowerCase();
       let newMediatorsTest = mediators.filter((item,index)=> item.mediator.fullName.toLowerCase().includes(searchText))
       console.log(newMediatorsTest)
       setNewMediators(newMediatorsTest)
    }
    return(
        <div>
            <InputSearch onChange={handleSearch} showFilter={false}/>
            {newMediators.map((mediator,index)=>(
            <div key={mediator?.mediator?.id} className="flex flex-row items-center justify-between w-[100%] mt-3">
                <div className="bg-[#DCECFD] flex flex-row items-center p-2 rounded-[10px] w-[65%]">
                   <div className="rounded-full border-[#73D3CB] border-[5px]">{mediator?.mediator?.profileImg == null ? defaultLettersCircle(mediator?.mediator?.fullName,"w-[40px]","h-[40px]") : <img src={mediator?.mediator?.profileImg} className="rounded-full w-[40px] h-[40px]" /> }</div>
                   <div className="flex flex-row items-center mx-2">
                       <div className="font-700 text-[#3E0292] text-[15px]">{t("header.mediator")}</div>
                       <div className="font-700 text-[#3E0292] text-[15px] mx-1">:</div>
                       <div className="font-700 text-[#3E0292] text-[15px]">{mediator?.mediator?.fullName}</div>
                   </div>
               </div>
               <ButtonIcon dark={false} icon={slash} className="p-5 bg-[#FF4342] rounded-[10px] shadow-lg w-[30%]" title={t("header.unBlockedMediator")} onClick={()=>actionUnblockUser(mediator?.mediator?.userId)}/>
            </div>
            ))}
        </div>
    )
}

export default Mediators