import React from 'react';
import { useTranslation } from 'react-i18next';
import { SuccessAddOffer } from '../../assets/icons';
import i18n from '../../languages/i18n';
import { AiOutlineClose } from 'react-icons/ai';

const MAddOrderConfirmation = ({ isOpen, isClose }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;
  document.body.dir = i18n.dir();
  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center h-full bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative rounded-2xl">
        <button
          className="absolute top-4 right-4 bg-[#00CEBC] rounded-lg text-gray-500 hover:text-gray-800"
          onClick={isClose}
        >
          <AiOutlineClose size={24} className="text-white p-1 font-bold" />
        </button>

        <div className="flex flex-col items-center justify-center text-center">
          <h2 className="text-md font-medium mb-4">
            {' '}
            {t('addOffer.congrats')}
          </h2>
          <img
            src={SuccessAddOffer}
            className="w-54 h-54 mb-2"
            alt="Registration Success"
          />
        </div>
      </div>
    </div>
  );
};

export default MAddOrderConfirmation;