// import React from 'react';
// import { useTranslation } from 'react-i18next';
// import { SuccessAddOffer } from '../../assets/icons';
// import i18n from '../../languages/i18n';
// import { AiOutlineClose } from 'react-icons/ai';

// const MConfirmation = ({ isOpen, isClose }) => {
//   const { t } = useTranslation();

//   if (!isOpen) return null;
//   document.body.dir = i18n.dir();
//   return (
//     <div
//       id="static-modal"
//       data-modal-backdrop="static"
//       tabIndex="-1"
//       aria-hidden="true"
//       className="fixed inset-0 z-50 flex items-center justify-center h-full bg-black bg-opacity-50"
//     >
//       <div className="bg-white rounded-lg p-6 w-full max-w-md relative rounded-2xl">
//         <button
//           className="absolute top-4 right-4 bg-[#00CEBC] rounded-lg text-gray-500 hover:text-gray-800"
//           onClick={isClose}
//         >
//           <AiOutlineClose size={24} className="text-white p-1 font-bold" />
//         </button>

//         <div className="flex flex-col items-center justify-center text-center">
//           <h2 className="text-md font-medium mb-4">
//             {' '}
//             {t('addOffer.congrats')}
//           </h2>
//           <img
//             src={SuccessAddOffer}
//             className="w-54 h-54 mb-2"
//             alt="Registration Success"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MConfirmation;

import { Modal } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { modalTheme } from '../../helpers/contents';
import { Button } from './../index';
const MConfirmation = ({
  setOpenModal,
  openModal,
  icon,
  title,
  showFooter = false,
  content,
  confirm,
  loading,
  singleButton = false,
  titleButton,
}) => {
  const { t } = useTranslation();
  return (
    <Modal theme={modalTheme} show={openModal}>
      <div className="flex flex-row items-center p-5">
        <FaTimes
          className="bg-[#00CEBC] text-white text-[20px] rounded-[5px] cursor-pointer"
          onClick={() => setOpenModal(false)}
        />
        <div className="mx-auto text-[#3E0292] font-bold text-[16px]">
          {title}
        </div>
      </div>
      <Modal.Body>{content}</Modal.Body>
      {showFooter ? (
        <Modal.Footer>
          {singleButton ? (
            <div className="flex flex-row items-center justify-center w-[100%]">
              <Button
                loading={loading}
                dark={false}
                title={titleButton}
                className="bg-[#00CEBC] border-[#00CEBC] w-[50%]"
                onClick={confirm}
              />
            </div>
          ) : (
            <div className="flex flex-row items-center justify-center w-[100%]">
              <Button
                loading={loading}
                dark={false}
                title={t('mConfirmation.confirm')}
                className="bg-[#00CEBC] border-[#00CEBC] mx-2 w-[30%]"
                onClick={confirm}
              />
              <Button
                dark={false}
                title={t('mConfirmation.cancel')}
                className="bg-[#ffffff] border-[#00CEBC] text-[#00CEBC] w-[30%]"
                textColor="#00CEBC"
                onClick={() => setOpenModal(false)}
              />
            </div>
          )}
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default MConfirmation;
