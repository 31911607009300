export const tokenConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      'Content-Type': "application/json"
    },
  };


  export const themeCarousel= {
    "root": {
      "base": "relative h-full w-full",
      "leftControl": "absolute left-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none",
      "rightControl": "absolute right-0 top-0 flex h-full items-center justify-center px-4 focus:outline-none"
    },
    "indicators": {
      "active": {
        "off": "bg-[#3E0292]/50 hover:bg-[#3E0292] dark:bg-gray-800/50 dark:hover:bg-gray-800",
        "on": "bg-[#3E0292] dark:bg-gray-800"
      },
      "base": "h-3 w-3 rounded-full",
      "wrapper": "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
    },
    "item": {
      "base": "absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
      "wrapper": {
        "off": "w-full flex-shrink-0 transform cursor-default snap-center",
        "on": "w-full flex-shrink-0 transform cursor-grab snap-center"
      }
    },
    "control": {
      "base": "inline-flex h-8 w-8 items-center justify-center rounded-full bg-[#3E0292]/30 group-hover:bg-[#3E0292]/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70 sm:h-10 sm:w-10",
      "icon": "h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
    },
    "scrollContainer": {
      "base": "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
      "snap": "snap-x"
    }
  };
  


  export const SwitchTheme={
      "root": {
        "base": "group flex rounded-lg focus:outline-none",
        "active": {
          "on": "cursor-pointer",
          "off": "cursor-not-allowed opacity-50"
        },
        "label": "ms-3 mt-0.5 text-start text-[15px] font-[400px] text-[#ffffff] dark:text-[#ffffff]"
      },
      "toggle": {
        "base": "relative rounded-full border after:absolute after:rounded-full after:bg-white after:transition-all group-focus:ring-4 group-focus:ring-cyan-500/25",
        "checked": {
          "on": "after:translate-x-full after:border-white rtl:after:-translate-x-full",
          "off": "border-gray-200 bg-gray-200 dark:border-gray-600 dark:bg-gray-700",
          "color": {
            "blue": "border-cyan-700 bg-cyan-700",
            "dark": "bg-dark-700 border-dark-900",
            "failure": "border-red-900 bg-red-700",
            "gray": "border-gray-600 bg-gray-500",
            "green": "border-green-700 bg-green-600",
            "light": "bg-light-700 border-light-900",
            "red": "border-red-900 bg-red-700",
            "purple": "border-purple-900 bg-purple-700",
            "success": "border-green-500 bg-green-500",
            "yellow": "border-yellow-400 bg-yellow-400",
            "warning": "border-yellow-600 bg-yellow-600",
            "cyan": "border-cyan-500 bg-cyan-500",
            "lime": "border-lime-400 bg-lime-400",
            "indigo": "border-indigo-400 bg-indigo-400",
            "teal": "bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4",
            "info": "border-cyan-600 bg-cyan-600",
            "pink": "border-pink-600 bg-pink-600"
          }
        },
        "sizes": {
          "sm": "h-5 w-9 min-w-9 after:left-px after:top-px after:h-4 after:w-4 rtl:after:right-px",
          "md": "h-6 w-11 min-w-11 after:left-px after:top-px after:h-5 after:w-5 rtl:after:right-px",
          "lg": "h-7 w-14 min-w-14 after:left-1 after:top-0.5 after:h-6 after:w-6 rtl:after:right-1"
        }
      }
  };

  export const modalTheme= {
    "root": {
      "base": "fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
      "show": {
        "on": "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80",
        "off": "hidden"
      },
      "sizes": {
        "sm": "max-w-sm",
        "md": "max-w-md",
        "lg": "max-w-lg",
        "xl": "max-w-xl",
        "2xl": "max-w-2xl",
        "3xl": "max-w-3xl",
        "4xl": "max-w-4xl",
        "5xl": "max-w-5xl",
        "6xl": "max-w-6xl",
        "7xl": "max-w-7xl"
      },
      "positions": {
        "top-left": "items-start justify-start",
        "top-center": "items-start justify-center",
        "top-right": "items-start justify-end",
        "center-left": "items-center justify-start",
        "center": "items-center justify-center",
        "center-right": "items-center justify-end",
        "bottom-right": "items-end justify-end",
        "bottom-center": "items-end justify-center",
        "bottom-left": "items-end justify-start"
      }
    },
    "content": {
      "base": "relative h-full w-full p-4 md:h-auto",
      "inner": "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700"
    },
    "body": {
      "base": "flex-1 overflow-auto p-6",
      "popup": "pt-0"
    },
    "header": {
      "base": "flex items-start justify-between rounded-t p-5 dark:border-gray-600",
      "popup": "border-b-0 p-2",
      "title": "text-[16px] font-[500px] text-[#303030] dark:text-white",
      "close": {
        "base": "ml-auto inline-flex items-center rounded-lg bg-[#00CEBC] p-1.5 text-sm text-[#ffffff] hover:bg-[#00CEBC] hover:text-[#ffffff] dark:hover:bg-[#ffffff] dark:hover:text-white",
        "icon": "h-5 w-5"
      }
    },
    "footer": {
      "base": "flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600",
      "popup": "border-t"
    }
  };


  export const navbarTheme = {
    "root": {
      "base": "bg-white px-2 py-2.5 dark:border-gray-700 dark:bg-gray-800 sm:px-4",
      "rounded": {
        "on": "rounded",
        "off": ""
      },
      "bordered": {
        "on": "border",
        "off": ""
      },
      "inner": {
        "base": "mx-auto flex flex-wrap items-center justify-between",
        "fluid": {
          "on": "",
          "off": "container"
        }
      }
    },
    "brand": {
      "base": "flex items-center"
    },
    "collapse": {
      "base": "w-full md:block md:w-auto",
      "list": "mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium",
      "hidden": {
        "on": "hidden",
        "off": ""
      }
    },
    "link": {
      "base": "block py-2 pl-3 pr-4 md:p-0",
      "active": {
        "on": "bg-cyan-700 text-white dark:text-white md:bg-transparent md:text-cyan-700",
        "off": "border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-[#3E0292] md:dark:hover:bg-transparent md:dark:hover:text-white"
      },
      "disabled": {
        "on": "text-gray-400 hover:cursor-not-allowed dark:text-gray-600",
        "off": ""
      }
    },
    "toggle": {
      "base": "inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden",
      "icon": "h-6 w-6 shrink-0"
    }
  };


  export const themePagination = {
    "base": "",
    "layout": {
      "table": {
        "base": "text-sm text-[#3E0292] dark:text-gray-400",
        "span": "font-semibold text-[#3E0292] dark:text-white"
      }
    },
    "pages": {
      "base": "xs:mt-0 mt-2 inline-flex items-center -space-x-px",
      "showIcon": "inline-flex",
      "previous": {
        "base": "ml-0 rounded-lg border border-gray-300 bg-[#3E0292] px-3 py-2 leading-tight text-[#ffffff] enabled:hover:bg-[#3E0292] enabled:hover:text-[#3E0292] dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        "icon": "h-5 w-5"
      },
      "next": {
        "base": "rounded-lg border border-gray-300 bg-[#3E0292] px-3 py-2 leading-tight text-[#ffffff] enabled:hover:bg-[#3E0292] enabled:hover:text-[#ffffff] dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        "icon": "h-5 w-5"
      },
      "selector": {
        "base": "w-12 border border-gray-300 bg-white py-2 leading-tight text-gray-500 enabled:hover:bg-[#3E0292] enabled:hover:text-[#ffffff] dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
        "active": "bg-[#3E029239] text-[#3E0292] hover:bg-[#3E029239] hover:text-[#3E0292] dark:border-gray-700 dark:bg-gray-700 dark:text-white",
        "disabled": "cursor-not-allowed opacity-50"
      }
    }
  }
  

  export const Lang = {
    ar:"ar",
    en:"en"
  };

  export const themeTabs={
    "base": "flex flex-col gap-2",
    "tablist": {
      "base": "flex text-center w-full",
      "variant": {
        "default": "grid w-full grid-flow-col outline-none rounded-none text-sm font-medium shadow dark:divide-gray-700 dark:text-gray-400",
        // "default": "flex-wrap border-b border-gray-200 dark:border-gray-700",
        "underline": "-mb-px flex-wrap border-b border-gray-200 dark:border-gray-700",
        "pills": "flex-wrap space-x-2 text-sm font-medium text-gray-500 dark:text-gray-400",
        "fullWidth": "grid w-full grid-flow-col outline-none rounded-none text-sm font-medium shadow dark:divide-gray-700 dark:text-gray-400"
      },
      "tabitem": {
        "base": "flex items-center justify-center rounded-t-lg p-4 text-sm font-medium first:ml-0 focus:outline-none focus:ring-4 outline-none focus:ring-cyan-300 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
        "variant": {
          // "default": {
          //   "base": "rounded-t-lg",
          //   "active": {
          //     "on": "bg-gray-100 text-cyan-600 dark:bg-gray-800 dark:text-cyan-500",
          //     "off": "text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
          //   }
          // },
          "default": {
            "base": "ml-0 flex w-full first:ml-0 bg-white outline-none",
            "active": {
              "on": "bg-white border-b-[8px] border-b-[#00CEBC] p-4 text-[#00CEBC] font-[500px] text-[20px] dark:bg-gray-700 dark:text-white",
              "off": "bg-white border-b-[8px] border-b-[#B3B3B3] text-[#B3B3B3] hover:bg-gray-50 hover:text-gray-700 font-[500px] text-[20px] dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            }
          },
          "underline": {
            "base": "rounded-t-lg",
            "active": {
              "on": "active rounded-t-lg border-b-2 border-cyan-600 text-cyan-600 dark:border-cyan-500 dark:text-cyan-500",
              "off": "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
            }
          },
          "pills": {
            "base": "",
            "active": {
              "on": "rounded-lg bg-cyan-600 text-white",
              "off": "rounded-lg hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
            }
          },
          "fullWidth": {
            "base": "ml-0 flex w-full first:ml-0 bg-white outline-none",
            "active": {
              "on": "bg-white border-b-[8px] border-b-[#00CEBC] p-4 text-[#00CEBC] font-[500px] text-[20px] dark:bg-gray-700 dark:text-white",
              "off": "bg-white border-b-[8px] border-b-[#B3B3B3] text-[#B3B3B3] hover:bg-gray-50 hover:text-gray-700 font-[500px] text-[20px] dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
            }
          }
        },
        "icon": "mr-2 h-5 w-5"
      }
    },
    "tabitemcontainer": {
      "base": "",
      "variant": {
        "default": "",
        "underline": "",
        "pills": "",
        "fullWidth": "h-[65vh] max600:h-[100%]"
      }
    },
    "tabpanel": "py-3"
  }