import { Carousel } from 'flowbite-react';
import {
  Button,
  ButtonIcon,
  CardOfferDetails,
  ChooseMediator,
  CompleteOrder,
  InputSearch,
  MConfirmation,
  MediatorRate,
  CardSkeleton,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import {
  clock,
  info,
  saudiUser,
  star,
  chatLight,
  chatWhite,
  reject,
  rejected,
  menuDots,
  loadingGreen,
  loadingRed,
  confirmIcon,
  checkOffer,
  timesOffer,
  pen,
  trash,
  confirmDelete,
} from '../../../../assets/icons';
import i18n from '../../../../languages/i18n';
import { Lang, themeCarousel } from '../../../../helpers/contents';
import { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { closeOrderCar, getConfirmationRequest } from '../../../../services/apis/order';
import {
  confirmationOffer,
  deleteOffer,
  getOffer,
  getOfferWithRequestForCompleteRequest,
  updateOfferStatus,
} from '../../../../services/apis/offer';
import { Bounce, toast } from 'react-toastify';
import {
  convertDate,
  languageAr,
  toastConfig,
  userRole,
} from '../../../../helpers/helper';
import { Dropdown } from 'flowbite-react';
import { CompleteOfferContext } from '../../../../context/CompleteOfferContext';
// import Slider from "react-slick";
import './offer.css';
import { ConfirmRequest } from '../../../../components';
import { PaidRequest } from '../../../../components';
import { getUserInfo } from '../../../../services/apis/user';
const OfferDetails = () => {
  const completeOfferContext = useContext(CompleteOfferContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offerId, id } = useParams();
  const [offer, setOffer] = useState({});
  const [loading, setLoading] = useState(false);
  const [openChooseCarModal, setOpenChooseCarModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openCompleteOfferModal, setOpenCompleteOfferModal] = useState(false);
  const [openRateMediatorModal, setOpenRateMediatorModal] = useState(false);
  const [loadingUpdateAccept, setLoadingUpdateAccept] = useState(false);
  const [loadingUpdateReject, setLoadingUpdateReject] = useState(false);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [offers, setOffers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);




  const [confirmationOrder, setConfirmationOrder] = useState([]);
  const [openConfirmRequestModal, setOpenConfirmRequestModal] = useState(false);
  const [openPaidRequestModal, setOpenPaidRequestModal] = useState(false);
  const [confirmationRequest, setConfirmationRequest] = useState(null);
  const [confirmationId, setConfirmationId] = useState(null);
  const [hideActionModal, setHideActionModal] = useState(false);
  const [userInfo,setUserInfo] = useState(null)

  const handleUserInfo = async () => {
    const response = await getUserInfo();
    setUserInfo(response);
  };

  useEffect(() => {
    handleUserInfo();
  }, []);


  const loader = (type) => {
    let loaderFalse =
      type == 'accept'
        ? setLoadingUpdateAccept(false)
        : setLoadingUpdateReject(false);
    return loaderFalse;
  };

  useEffect(() => {
    getOfferDetails();
  }, []);

  const getOfferDetails = async () => {
    setLoading(true);
    const response = await getOffer('', offerId);
    setOffer(response, setLoading(false));
  };

  const offerUpdateStatus = async (type) => {
    type == 'accept'
      ? setLoadingUpdateAccept(true)
      : setLoadingUpdateReject(true);

    let data = {
      offerId: offer.offerId,
      offerStatusId: type == 'accept' ? 1 : 2,
    };
    try {
      const response = await updateOfferStatus(data);
      console.log('Success:', response);
      toast.success(t('تم التعديل الحالة بنجاح'), toastConfig, loader(type));
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig, loader(type));
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig, loader(type));
        }
      }
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const getOffersWithRequest = async () => {
    setLoadingOffers(true);
    const response = await getOfferWithRequestForCompleteRequest('', id);
    console.log(response);
    setOffers(response.data.offers, setLoadingOffers(false));
    //  setOffers(response)
  };

  const confirm = () => {
    setOpenCompleteOfferModal(false);
    setOpenChooseCarModal(true);
  };

  const confirmRequest = async () => {
    let cars = [];
    completeOfferContext.selectedCars.forEach((carId) => {
      cars.push({ offerCarId: carId });
    });
    let data = {
      cars: cars,
    };
    try {
      const response = await confirmationOffer(data);
      setOpenChooseCarModal(false);
      setOpenConfirmModal(true);
      console.log('Success:', response);
      toast.success(t('تم تاكيد الطلب بنجاح'), toastConfig);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };

  const completeRequestConfirm = () => {
    confirmRequest();
  };

  const goToHome = () => {
    navigate('/');
    // setOpenRateMediatorModal(true)
  };

  const closeOrder = async () => {
    let data = {
      params: {
        closureMessage: 'تم الاغلاق بنجاح',
      },
    };
    const response = await closeOrderCar(data.params, id);
    console.log(response);
    getOfferDetails();
    toast.success(t('تم غلق الطلب بنجاح'), toastConfig);
  };



  const removeOffer = async () => {
    try{
    setLoadingConfirm(true);
    const response = await deleteOffer('', offerId);
    navigate('/offers');
    toast.success(
      t("offerCycle.deleteOfferSuccess"),
      toastConfig,
      setLoadingConfirm(false)
    );
    }catch(error){
    toast.error(
      error.response.data,
      toastConfig,
      setLoadingConfirm(false)
    );
  }
  };




  const action = () => {
    setConfirmationId(offer.inProgressOrFinalizedCR?.id);
    console.log(offer)
    if (offer.offerStatus == 3) {
      console.log("test55")
      if (userInfo.userId == offer.userId) {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(true, getConfirmationRequestData(offer.inProgressOrFinalizedCR?.id))
        );
      } else {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(false, getConfirmationRequestData(offer.inProgressOrFinalizedCR?.id))
        );
      }
    } else if (offer.offerStatus == 1 || offer.offerStatus == 4) {
      console.log("test333")
      if (localStorage.getItem('userRole') == 2) {
        setOpenPaidRequestModal(true, getConfirmationRequestData(offer.inProgressOrFinalizedCR?.id));
      } else {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(true, getConfirmationRequestData(offer.inProgressOrFinalizedCR?.id))
        );
      }
    } else if (offer.offerStatus == 5) {
      console.log("test66")
      setOpenConfirmRequestModal(
        true,
        setHideActionModal(true, getConfirmationRequestData(offer.inProgressOrFinalizedCR?.id))
      );
    }
  };

  const getConfirmationRequestData = async (confirmationId) => {
    const response = await getConfirmationRequest('', confirmationId);
    response.data.medInfo = response.data.otherUserInfo;
    response.data.medInfo.avgRating = response.data.otherUserInfo.medAvgRating;
    response.data.cars = [];
    response.data.confirmationCars.forEach((car) => {
      response.data.cars.push(car.offerCar);
    });

    setConfirmationRequest(response.data);
  };
  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <div className="flex flex-row items-center justify-between my-5">
          <div className="flex flex-row items-center">
            <div className="bg-[#3E0292] p-3 rounded-[10px] text-[#ffffff] text-[14px] font-500 flex flex-row items-center">
              <div>{t('offerDetails.details')}</div>
              <div className="px-2">:</div>
              <div>#{offer.offerId}</div>
            </div>
            <div className="bg-[#00CEBC] rounded-[7px] flex flex-row items-center p-1 mx-5">
              <div>
                <img src={info} />
              </div>
              <div className="text-[#ffffff] flex flex-row items-center text-[12px] font-[600px] mx-3">
                <div>{t('offerDetails.containOffer')}</div>
                <div className="px-2">{offer?.cars?.length}</div>
                <div>{t('offerDetails.carsTitle')}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center">
            {offer.offerStatus == 2 ? (
              <div className="flex flex-row items-center justify-around p-2 bg-[#FF00004D] rounded-[7px] mx-5">
                <div>
                  <img src={timesOffer} />
                </div>
                <div className="text-[#FD2828] text-[11px] font-[600px] mx-3">
                  {t('offerDetails.reject')}
                </div>
              </div>
            ) : offer.offerStatus == 1 ? (
              <div className="flex flex-row items-center justify-around p-2 bg-[#00CEBC4D] rounded-[7px] mx-5">
                <div>
                  <img src={checkOffer} />
                </div>
                <div className="text-[#00CEBC] text-[11px] font-[600px] mx-3">
                  {t('statusActions.accept')}
                </div>
              </div>
            ) : offer.offerStatus == 4 ? (
              <div className="flex flex-row items-center justify-around p-2 bg-[#00CEBC4D] rounded-[7px] mx-5">
                <div>
                  <img src={checkOffer} />
                </div>
                <div className="text-[#00CEBC] text-[11px] font-[600px] mx-3">
                  {t('statusActions.complete')}
                </div>
              </div>
            ) : offer.offerStatus == 2 ? (
              <div className="flex flex-row items-center justify-around p-2 bg-[#00CEBC4D] rounded-[7px] mx-5">
                <div>
                  <img src={timesOffer} />
                </div>
                <div className="text-[#00CEBC] text-[11px] font-[600px] mx-3">
                  {t('offerDetails.reject')}
                </div>
              </div>
            ) : null}

            {userRole == 3 && (
               <>
               {offer.offerStatus == 3 ? (
                    <>
                       <ButtonIcon dark={true} title={t('offerCycle.editOffer')} icon={pen} onClick={()=>navigate(`/offers/${offerId}/update`)}/>
                       <ButtonIcon dark={false} title={t('offerCycle.deleteOffer')} className="bg-[#FD2828] border-[#FD2828] mx-2" icon={trash} onClick={()=>setOpenDeleteModal(true)}/>
                    </>
                ):null}
                </>
             )}

                <MConfirmation
                    loading={loadingConfirm}
                    openModal={openDeleteModal}
                    showFooter={true}
                    setOpenModal={setOpenDeleteModal}
                    title={t('هل متاكد من حذف العرض')}
                    content={
                      <div className="flex justify-center items-center">
                        <img src={confirmDelete} />
                      </div>
                    }
                    confirm={removeOffer}
                  />

            <div className="flex flex-row items-center justify-around p-2 bg-[#E6EDFF] rounded-[7px] mx-3">
              <div>
                <img src={clock} />
              </div>
              {offer?.cratedAt ? (
                <div className="text-[#3E0292] mx-3">
                  <span className="font-bold text-[13px]">
                    {convertDate(offer?.cratedAt)}
                  </span>{' '}
                  <span className="text-[11px] font-[600px]">
                    {offer?.cratedAt?.split('T')[1]?.slice(0, 8)}
                  </span>
                </div>
              ) : null}
            </div>
            <div>
              <div className="relative">
                {offer.offerStatus == 1 ? (
                  <img
                    src={menuDots}
                    onClick={toggleMenu}
                    className="cursor-pointer"
                  />
                ) : null}
                {isOpen && (
                  <div
                    className={`absolute ${
                      languageAr ? 'left-0' : 'right-0'
                    } mt-2 w-[100px] z-10 bg-white border border-gray-200 rounded-[12px] shadow-lg`}
                    onMouseLeave={closeMenu}
                  >
                    <ul className="py-2">
                      <li>
                        <div
                          className="flex flex-row items-center justify-around p-3 cursor-pointer"
                          onClick={() =>
                            setOpenCompleteOfferModal(
                              true,
                              getOffersWithRequest()
                            )
                          }
                        >
                          <div>
                            <img src={loadingGreen} />
                          </div>
                          <div className="text-[#00CEDF] text-[15px] font-400">
                            {t('completeRequest.completed')}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div
                          className="flex flex-row items-center justify-around border-t border-t-[#E9E9E9] p-3 cursor-pointer"
                          onClick={closeOrder}
                        >
                          <div>
                            <img src={loadingRed} />
                          </div>
                          <div className="text-[#CE4223] text-[15px] font-400">
                            {t('completeRequest.close')}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-container" style={{ direction: 'ltr' }}>
          <Carousel theme={themeCarousel}>
            {loading ? (
              <CardSkeleton number={offer?.cars?.length} height={'500px'} />
            ) : (
              offer?.cars?.map((car, index) => (
                <CardOfferDetails
                  offer={offer}
                  key={car.id}
                  mediator={offer?.medInfo}
                  carOffer={car}
                  goProfile={() =>
                    navigate(`/profile-info/${offer?.medInfo?.id}`)
                  }
                  finalOffer={offer?.inProgressOrFinalizedCR?.id}
                />
              ))
            )}
          </Carousel>
        </div>

        {offer.offerStatus == 1 ? (
          <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%]  mr-auto ml-auto gap-x-2 mt-5">
            <ButtonIcon
              loading={loadingUpdateAccept}
              icon={chatWhite}
              className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
              dark={false}
              title={t('statusActions.startChat')}
              onClick={() => navigate(`/chats`, { state: { offer: offer } })}
            />
            {/* <Button loading={loadingUpdateReject} className="bg-[#FF0000] border border-[#FF0000] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0" dark={false} title={t("offerDetails.rejected")}/> */}
          </div>
        ) : offer.offerStatus == 2 ? (
          <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%]  mr-auto ml-auto gap-x-2 mt-5">
            {/* <Button loading={loadingUpdateAccept} className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0" dark={false} title={t("offerDetails.accept")} onClick={()=>offerUpdateStatus("accept")}/> */}
            <ButtonIcon
              loading={loadingUpdateReject}
              icon={reject}
              titleClass="text-[#FD2828]"
              className="bg-[#FF00004D] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
              dark={false}
              title={t('offerDetails.rejected')}
            />
          </div>
        ) : offer.offerStatus == 4 ? (
          <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%]  mr-auto ml-auto gap-x-2 mt-5">
            <ButtonIcon
              loading={loadingUpdateAccept}
              icon={checkOffer}
              className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
              dark={false}
              title={t('statusActions.confirmOffer')}
              onClick={()=>action()}
            />
            {/* <Button loading={loadingUpdateReject} className="bg-[#FF0000] border border-[#FF0000] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0" dark={false} title={t("offerDetails.rejected")}/> */}
          </div>
        ) : (
          <>
          {userRole == 3 ? (
              <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%]  mr-auto ml-auto gap-x-2 mt-5">
              <ButtonIcon
              loading={loadingUpdateAccept}
              icon={checkOffer}
              className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
              dark={false}
              title={t("offerCycle.waitingForAccept")}
            />
            </div>
          ):(
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[50%]  mr-auto ml-auto gap-x-2 mt-5">
            <Button
              loading={loadingUpdateAccept}
              className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
              dark={false}
              title={t('offerDetails.accept')}
              onClick={() => offerUpdateStatus('accept')}
            />
            <Button
              loading={loadingUpdateReject}
              className="bg-[#FF0000] border border-[#FF0000] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
              dark={false}
              title={t('offerDetails.reject')}
              onClick={() => offerUpdateStatus('reject')}
            />
          </div>
            )}
          </>
        )}
      </div>
      <MConfirmation
        loading={loading}
        openModal={openCompleteOfferModal}
        showFooter={true}
        setOpenModal={setOpenCompleteOfferModal}
        title={t('completeRequest.chooseMediator')}
        content={
          <ChooseMediator offers={offers} loadingOffers={loadingOffers} />
        }
        confirm={confirm}
      />
      <MConfirmation
        loading={loading}
        openModal={openChooseCarModal}
        showFooter={true}
        setOpenModal={setOpenChooseCarModal}
        title={t('completeRequest.completeRequest')}
        content={<CompleteOrder offer={completeOfferContext.offer} />}
        confirm={completeRequestConfirm}
      />
      <MConfirmation
        loading={loading}
        openModal={openConfirmModal}
        showFooter={true}
        setOpenModal={setOpenConfirmModal}
        title={t('completeRequest.successSentToMediator')}
        content={
          <div className="flex justify-center items-center">
            <img src={confirmIcon} />
          </div>
        }
        titleButton={t('completeRequest.backToHome')}
        singleButton={true}
        confirm={goToHome}
      />




      {/* details offer */}
      <MConfirmation
        loading={loading}
        openModal={openConfirmRequestModal}
        showFooter={hideActionModal ? false : true}
        setOpenModal={setOpenConfirmRequestModal}
        title={t('completeRequest.completeRequest')}
        content={
          <ConfirmRequest
            hideActionModal={hideActionModal}
            // onValueChangeComment={handleValueComment}
            // onValueChange={handleValueConfirm}
            offer={confirmationRequest}
          />
        }
        confirm={confirmRequest}
      />
      {/* <MConfirmation
        loading={loading}
        openModal={openPaidRequestModal}
        setOpenModal={setOpenPaidRequestModal}
        showFooter={true}
        titleButton={
          confirmationRequest?.totalCommission > 0
            ? t('paidRequest.payment')
            : t('paidRequest.rating')
        }
        singleButton={true}
        content={
          <PaidRequest
            onValueChange={handleValueConfirm}
            offer={confirmationRequest}
          />
        }
        confirm={paidRequest}
      /> */}
      {/* <MConfirmation
        loading={loading}
        openModal={openRateMediatorModal}
        showFooter={true}
        setOpenModal={setOpenRateMediatorModal}
        title={t('paidRequest.ratingMediator')}
        content={
          <MediatorRate
            onValueChangeCommentRate={handleValueCommentRate}
            onValueChangeStarRate={handleValueStarRate}
            onValueChangeRate={handleValueRate}
            showMessageSuccess={showMessageSuccess}
            setShowMessageSuccess={showMessageSuccessRateMediator}
          />
        }
        titleButton={t('paidRequest.rating')}
        singleButton={true}
        confirm={ratedMediator}
      /> */}
      {/* <MConfirmation loading={loading} openModal={openRateMediatorModal} showFooter={true} setOpenModal={setOpenRateMediatorModal} title={t("تقييم الوسيط")} content={<MediatorRate/>} titleButton={t("completeRequest.backToHome")} singleButton={true} confirm={goToHome}/> */}
    </div>
  );
};
export default OfferDetails;
