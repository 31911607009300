const CarColor=({color})=>{
    return(
        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5712 2.12207H7.42875C5.25 2.12207 4.76875 3.05207 4.48875 4.19207L3.5 8.24957H17.5L16.5112 4.19207C16.2312 3.05207 15.75 2.12207 13.5712 2.12207Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.2411 14.865C19.3374 15.7425 18.5149 16.5 17.4649 16.5H15.8199C14.8749 16.5 14.7436 16.155 14.5774 15.7275L14.4024 15.2775C14.1574 14.6625 13.9999 14.25 12.7399 14.25H8.25989C6.99989 14.25 6.81614 14.715 6.59739 15.2775L6.42239 15.7275C6.25614 16.155 6.12489 16.5 5.17989 16.5H3.53489C2.48489 16.5 1.66239 15.7425 1.75864 14.865L2.24864 10.2975C2.37114 9.1725 2.62489 8.25 4.91739 8.25H16.0824C18.3749 8.25 18.6286 9.1725 18.7511 10.2975L19.2411 14.865Z" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.5 6H2.625" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.375 6H17.5" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.5 2.25V3.75" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.1875 3.75H11.8125" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.25 11.25H7.875" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.125 11.25H15.75" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    )
}

export default CarColor