import GInput from "../../inputs/GInput"
import { arrowLeftOrder } from "../../../assets/icons"
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import Button from "../../buttons/Button";
import { FilterOrderContext } from "../../../context/FilterOrderContext";
import { getCities } from "../../../services/apis/home";
import { languageAr } from "../../../helpers/helper";
import { useTranslation } from "react-i18next";
const items = [
    'مكة', 'المدينة'
  ];
const City=({back})=>{
    const {t} = useTranslation()
    const filterOrderContext = useContext(FilterOrderContext)
    const { register, handleSubmit, setValue ,getValues} = useForm({
        defaultValues: { cityIds: [] } 
      });

    const [selectedCities, setSelectedCities] = useState([]);
    const [cities, setCities] = useState([]);

    const handleCityChange = (city) => {
      const currentCities = getValues('cities') || [];

      const updatedCities = currentCities.some(selected => selected.value === city.value)
        ? currentCities.filter(i => i.value !== city.value)
        : [...currentCities, city];
      setSelectedCities(updatedCities); 
      filterOrderContext.setCities(updatedCities)
    
      setValue('cities', updatedCities);
    };

    useEffect(()=>{
        getAllCities()
    },[])


    useEffect(()=>{
      setSelectedCities(filterOrderContext.cities)
    },[filterOrderContext.cities])

    const getAllCities=async()=>{
        const response = await getCities("")
        let newCities = []
        response.forEach(element => {
            newCities.push({value:element.id,label:languageAr ? element.nameAr : element.nameEn})
        });
        setCities(newCities)
    
      }

      const onSubmit = (data) => {
          filterOrderContext.setCities(data.cities,back())
      };
    return(
        <div>
            <img src={arrowLeftOrder} className="my-2 cursor-pointer" onClick={back}/>
            <form onSubmit={handleSubmit(onSubmit)} className="p-4">
            <GInput title={t("filterModal.selectCity")} items={cities} type={"cityIds"} selectedItems={selectedCities} handleCheckboxChange={handleCityChange} register={register}/>
            <div className="grid grid-cols-2 gap-x-2 mt-10">
                <Button type="submit" dark={false} className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]" title={t("filterModal.confirm")}/>
                <Button type="submit" dark={false} className="bg-[#FF4342] border-[#FF4342] rounded-[10px]" title={t("filterModal.cancel")}/>
            </div>
            </form>
        </div>
    )
}

export default City