import { useTranslation } from "react-i18next";
import { aboutUsImage } from "../../../assets/icons"
import { DownloadApp, GeneralSwiper, ServicesCard ,UserCardSwiper} from "../../../components"
const testimonials = [
    { name: 'عائشة الأنور', image: 'image1.jpg', text: '', stars: 5 },
    { name: 'أكرم السعد', image: 'image2.jpg', text: '', stars: 5 },
    { name: 'محمد الشمال', image: 'image3.jpg', text: 'كانت التجربة استثنائية بكل المقاييس...', stars: 5, highlighted: true },
    { name: 'نوى الحسن', image: 'image4.jpg', text: '', stars: 5 },
    { name: 'علاء سعد', image: 'image5.jpg', text: '', stars: 5 },
  ];
const AboutUs=()=>{
    const {t} = useTranslation()
    return(
        <div>
            <div className="grid md:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 2xl:grid-cols-2 bg-gradient-to-l from-[#FFFFFF] to-[#D7E2FF] px-16 py-5">
               <div className="flex flex-col">
                  <div>
                      <div className="text-[30px] text-[#3E0292] font-500">{t("aboutUs.title")}</div>
                      <div className="text-[14px] text-[#303030] mt-5 font-400">{t("aboutUs.descriptionOne")}</div>
                      
                      <div className="text-[14px] text-[#303030] mt-3 font-400">{t("aboutUs.descriptionTwo")}</div>
                      <div className="text-[14px] text-[#303030] mt-3 font-400">{t("aboutUs.descriptionThree")}</div>
                  </div>
                  <div className="mt-5">
                  <div className="text-[30px] text-[#3E0292] font-500">{t("aboutUs.whyMshrai")}</div>
                  <div className="text-[14px] text-[#303030] font-400 mt-5">{t("aboutUs.answerOne")}</div>
                  <div className="text-[12px] text-[#303030] font-400 mt-3">{t("aboutUs.subAnswerOne")}</div>
                  <div className="text-[14px] text-[#303030] font-400 mt-5">{t("aboutUs.answerTwo")}</div>
                  <div className="text-[12px] text-[#303030] font-400 mt-3">{t("aboutUs.subAnswerTwo")}</div>
                  <div className="text-[14px] text-[#303030] font-400 mt-5">{t("aboutUs.answerThree")}</div>
                  <div className="text-[12px] text-[#303030] font-400 mt-3">{t("aboutUs.subAnswerThree")}</div>
                  </div>
               </div>
               <div className="flex items-center justify-center"><img src={aboutUsImage}/></div>

            </div>

            <div className="mt-10 w-[100%]">
                <div className="text-[40px] text-[#444444] underline text-center mb-5 font-700">أراء العملاء</div>
        <GeneralSwiper testimonials={testimonials}/>
    </div>


            <DownloadApp/>
            <ServicesCard/>

        </div>
    )
}

export default AboutUs