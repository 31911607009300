import { useState } from "react";
import { useTranslation } from "react-i18next";
import CheckBox from "../inputs/CheckBox"
import Textarea from "../inputs/Textarea";

const CloseChat=()=>{
    const {t} = useTranslation()
    const [selectedValue, setSelectedValue] = useState('');
    const handleChange = (e) => {
        setSelectedValue(e.target.value);
      };
    return(
        <div>
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={t("chats.notAgree")} checked={selectedValue === t("chats.notAgree")} type="radio"/>
            <label className="mx-3">{t("chats.notAgree")}</label>
            </div>
            <div className="flex flex-row items-center my-5">
            <CheckBox handleChange={handleChange} value={t("chats.InappropriateDisplay")} checked={selectedValue === t("chats.InappropriateDisplay")} type="radio"/>
            <label className="mx-3">{t("chats.InappropriateDisplay")}</label>
            </div>
            <div className="flex flex-row items-center">
            <CheckBox handleChange={handleChange} value={t("chats.AnotherReason")} checked={selectedValue === t("chats.AnotherReason")} type="radio"/>
            <label className="mx-3">{t("chats.AnotherReason")}</label>
            </div>
             <div className="mt-8">
            <Textarea placeholder={""} rows={4} label={t("chats.writeWant")}/>
            </div>

        </div>
    )
}

export default CloseChat