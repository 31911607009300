import { arrowLeftOrder } from "../../../assets/icons"
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { FilterOrderContext } from "../../../context/FilterOrderContext";
import ReactSlider from 'react-slider';
import './price.css';  
import Button from "../../buttons/Button";
import { useTranslation } from "react-i18next";
const Price=({back})=>{
    const {t} = useTranslation()
      const filterOrderContext = useContext(FilterOrderContext)
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: { price: [100000, 500000] } 
      });

      const [price,setPrice] = useState([100000, 500000])
      const handleSliderChange = (newValues) => {
        setPrice(newValues,setValue("price",newValues));
      };


      const onSubmit = (data) => {
          filterOrderContext.setPrice(data.price,back())
      };
    return(
        <div>
        <img src={arrowLeftOrder} className="my-2 cursor-pointer" onClick={back}/>
        <div className="text-[#3E0292] text-[15px] font-500">{t("filterModal.selectPrice")}</div>
        <form onSubmit={handleSubmit(onSubmit)} className="p-4">
    <ReactSlider
        min={0}
        max={1000000}
        step={10000}
        value={price}
        onChange={handleSliderChange}
        renderThumb={(props, state) => (
          <div {...props} className="thumb">
            <div className="tooltip">{`${state.valueNow} ${t("filterModal.rial")}`}</div>
          </div>
        )}
        renderTrack={(props, state) => (
          <div {...props} className="track">
            {/* <div className="range" style={{
              left: `${(price[0] / 10000) * 100 / 100}%`,
              right: `${100 - (price[1] / 10000) * 100 / 100}%`
            }} /> */}
          </div>
        )}
      />
      <div
        style={{
          marginTop: '40px',
          padding: '10px 20px',
          backgroundColor: '#a0f0f0',
          color: '#4e008e', 
          borderRadius: '8px',
          textAlign: 'center',
          fontWeight: 'bold',
          display:'flex',
          flexDirection:'row',
        }}
      >
        <div className="mx-2">{t("filterModal.from")}</div>
        <div>{price[0]}</div>
        <div className="mx-2">{t("filterModal.rial")}</div>
        <div>{t("filterModal.to")}</div>
        <div className="mx-2">{price[1]}</div>
        <div>{t("filterModal.rial")}</div>
      </div>


      <div className="grid grid-cols-2 gap-x-2 mt-10">
                <Button type="submit" dark={false} className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]" title={t("filterModal.confirm")}/>
                <Button type="submit" dark={false} className="bg-[#FF4342] border-[#FF4342] rounded-[10px]" title={t("filterModal.cancel")}/>
            </div>
        </form>
        </div>    
    )
}
export default Price