import { Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
// import { getOffers, getOffersForOrder } from "../../../../services/apis/offer"
import {
  CardOfferOwner,
  InputSearch,
  NotFoundOrders,
  CardSkeleton,
  NotFoundCars,
} from '../../../components';
import { getOffers } from '../../../services/apis/owner';

const Offers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    getAllOffersForRequest();
  }, []);

  const getAllOffersForRequest = async () => {
    setLoading(true);
    let data = {
      params: {
        size: 3,
        page: currentPage,
        sellMediationId: id,
      },
    };
    const response = await getOffers(data, id);
    setTotalPages(response.data.page.totalPages);
    setTotalElements(response.data.page.totalElements);
    setOffers(
      (prev) => [...prev, ...response.data.page.content],
      setLoading(false)
    );
    if (response.data.page.content.length === 0) {
      setHasMore(false);
    }
    setCurrentPage(currentPage + 1);
  };

  if (loading) {
    return (
      <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-4 my-5 w-[90%] mr-auto ml-auto">
        {/* {Array(3)
            .fill(0)
            .map((_, index) => (
              <div key={index} style={{ marginBottom: "10px" }}> */}
        <CardSkeleton number={3} height={'300px'} />
        {/* </div>
             ))}  */}
      </div>
    );
  }

  if (!offers || offers.length === 0) {
    return (
      <NotFoundCars from="offers" onClick={() => navigate(`/car-owner`)} />
    );
  }

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <InputSearch
        // onChange={handleSearch} value={searchText} action={searchAction}
        />
        <div className="flex flex-row items-center justify-between my-5">
          <div className="bg-[#00CEBC] p-3 rounded-[10px] text-[#ffffff] text-[14px] font-500 flex flex-row items-center">
            <div>{t('listOrdersCarOwner.orderNumber')}</div>
            <div className="px-2">:</div>
            <div>#{id}</div>
          </div>
          <div className="flex flex-row items-center justify-around p-2">
            <div className="text-[#3E0292] text-[14px] font-500 mx-5">
              {t('listOffers.offers')}
            </div>
            <div className="bg-[#D7E2FF] rounded-[5px] text-[#3E0292] text-[16px] font-500 p-3">
              {offers.length}
            </div>
          </div>
        </div>
        <div className="relative">
          <InfiniteScroll
            dataLength={offers.length}
            next={getAllOffersForRequest}
            hasMore={hasMore}
            loader={
              <div className="flex justify-center bg-[#D7E2FF69] h-[100px] items-center">
                <Spinner
                  color="success"
                  aria-label="Extra large spinner example"
                  size="xl"
                />
              </div>
            }
            endMessage={
              <p className="mt-20 text-center text-[#3E0292]">
                {t('تم تحميل كل العروض')}
              </p>
            }
          >
            <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-16  my-5">
              {offers.map((offer, index) => (
                <CardOfferOwner
                  key={index}
                  refreshOffer={getAllOffersForRequest}
                  offer={offer}
                  onClick={() =>
                    navigate(`/car-owner/${id}/offers/${offer?.id}`)
                  }
                />
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Offers;
